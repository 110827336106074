import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportRouterService {
  private rota: string = ''; 

  getRota(): string {
    return this.rota;
  }

  setRota(rota: string): void { 
      this.rota = rota; 
  }
}
