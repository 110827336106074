<div class="field-form-control" [ngStyle]="styles">
    <label class="form-label" for="{{id()}}" [style.color]="labelColor" [class.required]="isRequired">{{label()}}</label> <br>
    <input class="d-none" id="{{id()}}" [formControl]="formControlValue()" type="text">
    <div class="row">
        @for (item of toggleButtonOptions(); track $index) {
            <div class="col">
                <input
                    type="radio"
                    class="btn-check"
                    name="options-base-{{id()}}"
                    id="{{$index}}-{{id()}}"
                    autocomplete="off"
                    [checked]="item.value === formControlValue().value"
                    >
                <label class="btn w-100 mb-2"
                    [ngClass]="{'me-2': toggleButtonOptions().length - 1 !== $index}" for="{{$index}}-{{id()}}"
                    (click)="selectItem(item)">
                    <div class="d-grid">
                        @if (item.icon && item.iconComponent === 'mat-icon') {
                            <mat-icon class="mat-icon-tgglbtn" svgIcon="{{item.icon}}"></mat-icon>
                        }@else if (item.icon) {
                            <i class="{{item.icon}} fs-small"></i>
                        }
                        {{item.label}}
                    </div>
                </label>
                <div class="fs-xsmall additional-info mb-2 text-center">
                    {{item.additionalInfo}}
                </div>
            </div>
        }
    </div>
    <app-validation-errors *ngIf="formControlValue().touched"
        [customErrorMessages]="customErrorMessages"
        [errors]="formControlValue().errors"
        >
    </app-validation-errors>
</div>
