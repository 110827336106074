import { CommonModule } from '@angular/common';
import { Component, input, OnInit, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-user-actions',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './user-actions.component.html',
  styleUrl: './user-actions.component.scss'
})
export class UserActionsComponent implements OnInit{
  actionsEvent = output(); 
  userBlocked = input<boolean>();
  color = input<string>();
  styles:any = '';
  isInviteFriendActive: boolean = false; // Controle para a seção "Convidar amigo"
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.styles = {
      '--bs-dropdown-divider-margin-y': '6px',
      '--bs-dropdown-divider-bg': 'rgba(0, 0, 0, 0.175)',
      '--color-actions' : this.color()?.toString()
    };

    // Carregar o estado de activeSection do localStorage
    const activeSection = JSON.parse(localStorage.getItem('activeSections') || '{}');
    this.isInviteFriendActive = activeSection['convidar amigo'] === true; // Verifica o status de "convidar amigo"

  }


  actionEventClick(): void {
    this.actionsEvent.emit();
  }

  logout(): void {
    this.authService.logout();

    this.actionsEvent.emit();
  }
}
