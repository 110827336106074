<div class="modal fade" id="membershipCardModal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" [attr.data-bs-backdrop]="backdropType">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Carteirinha QR Code</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCancel()"></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <qrcode #qrCode [qrdata]="memberShipCard()?.cardNumber" [width]="300" elementType="svg"></qrcode>
          <h2 class="mt-3">Escaneie o QR Code para acessar sua carteirinha.</h2>
        </div>
      </div>
    </div>
  </div>
</div>
