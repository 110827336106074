import { Component, WritableSignal, computed, signal } from '@angular/core';
import { InvoiceModel, StatusInvoice } from '../../models/invoiceModel';
import { CommonModule } from '@angular/common';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CustomButtonDto } from '../app-button/app-button-dto';
import { Location } from '@angular/common';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';
import { CreditCardInfoModel, PaymentType } from '../../models/paymentData';
import { ModalService } from 'src/app/core/services/modal.service';
import { MatIconModule } from '@angular/material/icon';
import { SelectModel } from '../app-select-input/app-select-input.component';
import { CommonService } from 'src/app/core/services/common.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PaymentInvoiceModalComponent, PaymentInvoiceModalData } from '../payment-invoice-modal/payment-invoice-modal.component';
import { InvoiceService } from 'src/app/core/services/invoice.service';

@Component({
  selector: 'app-view-invoice',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, CustomCurrencyPipe, MatIconModule, PaymentInvoiceModalComponent],
  templateUrl: './view-invoice.component.html',
  styleUrl: './view-invoice.component.scss'
})
export class ViewInvoiceComponent {
  invoice: any;
  invoiceId: any;
  StatusInvoice = StatusInvoice;
  PaymentType = PaymentType;
  modalId = 'payment-modal';

  faturasVinculadasList: any[] = [];

  paymentType = PaymentType.CREDITO;
  paymentInvoiceModalData: PaymentInvoiceModalData;
  fcLogoImg = '/assets/imagens/fc-logo.png';

  btnBackOptions = {
    buttonText: 'Voltar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-secondary',
  } as CustomButtonDto;

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);

  creditCardOptions = computed<SelectModel[]>(() => {
    if (!this.creditCardInfo()) {
      return [];
    }

    return this.creditCardInfo().map(cardInfo => {
      return {
        code: cardInfo.cardNumber, // Use card number as code
        name: `${cardInfo.cardOperator} - ....${cardInfo.cardNumber.slice(-4)}`,
      } as SelectModel;
    });
  });

  constructor(
    private location: Location,
    private modalService: ModalService,
    private memberService: MemberService,
    private invoiceService: InvoiceService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.invoiceId = history.state.invoice;

    this.memberService.getMembeInvoiceDashboardDataDetail(this.invoiceId).subscribe(res => {
      this.invoice = res;
      if(res.invoiceType.id === '51c20cb8-871b-462d-8d23-31865105e298' || res.invoiceType.id === 'c2a91257-34ba-40ca-a168-52f3d8ec5b04'){
        this.invoiceService.getAntecipationRenegotiationInvoices(res.id).subscribe(data => {
          this.faturasVinculadasList = data;
        })
      }
    })

    // TODO: Remover mock
    const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
    this.creditCardInfo.set(creditCardArr);


  }

  back() {
    this.location.back();
  }

  openModal(paymentType: PaymentType) {
    this.setModalData(paymentType);
    this.modalService.open(this.modalId);
  }

  getStatus(status: any) {
    switch (status) {
      case 'waiting_payment':
        return 'Aguardando pagamento';
      case 'paid':
        return 'Pago';
      case 'refunded':
        return 'Reembolsado';
      case 'canceled':
        return 'Cancelado';
      case 'refused':
        return 'Recusado';
      case 'pending':
        return 'Pendente';
      default:
        return status;
    }
  }

  setModalData(paymentType: PaymentType) {
    this.paymentType = paymentType;

    switch (paymentType) {
      case PaymentType.BOLETO:
        // TODO: Remover mock (info vem do input invoiceList)
        this.paymentInvoiceModalData = {
          invoiceNumber: '932823',
          invoiceCode: 'u12804y2374rh2084723h432847g238',
          invoiceCodeImg: '/assets/imagens/bar-code.png'
        };
      break;
      case PaymentType.PIX:
        this.paymentInvoiceModalData = {
          invoiceNumber: '932823',
          invoiceCode: 'u12804y2374rh2084723h432847g238',
          invoiceCodeImg: '/assets/imagens/qr-code.png'
        };
      break;
      case PaymentType.CREDITO:
        this.paymentInvoiceModalData = {
          invoiceNumber: '932823',
          creditCardOptions: this.creditCardOptions()
        };
      break;

      default:
        break;
    }
  }

  onModalConfirm(): void {
    console.log('TODO: Ação de confirmação do modal');
  }
}
