<div id="carouselExampleIndicators" class="carousel slide"
    [attr.data-bs-ride]='styles.carouselType == "Dinâmico" ? "carousel" : null' [ngStyle]="styles">

    <ol class="carousel-indicators" *ngIf="showIndicators">
        <li *ngFor="let chunk of chunkedItems; let i = index" data-bs-target="#carouselExampleIndicators"
            [attr.data-bs-slide-to]="i" [class.active]="i === 0">
        </li>
    </ol>
    <div class="carousel-inner">
        <ng-container *ngFor="let chunk of chunkedItems; let i = index">
            <div class="carousel-item" [class.active]="i === 0" [attr.data-bs-interval]='styles.carouselInterval ? (styles.carouselInterval*1000) : "1000"'>
                <div class="cards-wrapper d-flex flex-nowrap justify-content-space-btween">
                    <ng-container *ngFor="let item of chunk; let j = index">
                        <app-carousel-plan-card
                            [carouselCardData]="item"
                            [isMobile]="isMobile"
                            [chunkCarouselSize]="chunkSize"
                            [templateVersion]="versaoAtual"
                            [indexJ]="j"
                            [styles]="styles"
                            (carouselAction)="btnCarouselAction($event)"
                        >
                        </app-carousel-plan-card>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <a class="carousel-control-prev" *ngIf="showIndicators" href="#carouselExampleIndicators" role="button"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" *ngIf="showIndicators" href="#carouselExampleIndicators" role="button"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </a>
</div>
