<section class="section-next-match" *ngIf="matchesArr.length > 0 && styles" [ngStyle]="styles">
    <div class="container wrapper-next-match p-4">
        <div id="confronto" class="carousel slide" data-bs-ride="true">

            <ol class="carousel-indicators" *ngIf="showIndicators">
                <li *ngFor="let item of matchesArr; let i = index" data-bs-target="#confronto" [attr.data-bs-slide-to]="i"
                    [class.active]="i === 0"></li>
            </ol>

            <div class="carousel-inner p-1 p-md-3">
                <ng-container *ngFor="let item of matchesArr; let i = index">
                    <div class="carousel-item" [class.active]="i === 0">
                        <div class="d-grid">
                            <div class="title my-2 text-center">
                                <span>
                                    {{styles['sectionTitle']}}
                                </span>
                            </div>
                            <div class="sub-title my-2 text-center" *ngIf="styles['showSubtitle']">
                                <span>
                                    {{styles['subTitle']}}
                                </span>
                            </div>
                        </div>
                        <div class="match d-flex my-5 justify-content-center">
                            @if (item.team1) {
                                <div class="team px-2 px-sm-3 px-md-5">
                                    <img src="{{item.team1.src}}" alt="team 1">
                                    <div class="club-name">
                                        <span>
                                            {{item.team1.name}}
                                        </span>
                                    </div>
                                </div>
                            }
                            <div class="versus mx-1 align-content-center">
                                <span>X</span>
                            </div>
                            @if (item.team2) {
                                <div class="team px-2 px-sm-3 px-md-5">
                                    <img src="{{item.team2.src}}" alt="team 2">
                                    <div class="club-name">
                                        <span>
                                            {{item.team2.name}}
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="d-flex align-items-center flex-column">
                            <div class="local d-flex align-items-center flex-column flex-md-row ">
                                <p>{{item.championship}}</p><p class="d-none d-md-flex mx-2">/</p><p>{{item.local}}</p>
                            </div>
                            <div class="date d-flex my-2 align-items-center">
                                <span>{{item.data}} às {{item.hora}}</span>
                            </div>
                            <app-button [style]="'--bs-btn-padding-x: 1.4em;'" [buttonOptions]="buttonOptions"
                                class="app-button mt-4" (buttonClick)="goToRegister()"></app-button>
                        </div>
                    </div>
                </ng-container>
            </div>

            <a class="carousel-control-prev" href="#confronto" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </a>
            <a class="carousel-control-next" href="#confronto" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </a>
        </div>
    </div>
</section>