import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { PostGuessModel, ScoreGuessModel } from 'src/app/shared/models/scoreGuessModel';

@Injectable({
  providedIn: 'root'
})
export class ScoreGuessService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

    getAllScoreGuess(): Observable<ScoreGuessModel[]> {
      return this.get<ScoreGuessModel[]>(`${this.env.socioApiUrl}/scoreguess`);
    }

    getScoreGuessById(scoreGuessId: string): Observable<ScoreGuessModel> {
      return this.get<ScoreGuessModel>(`${this.env.socioApiUrl}/scoreguess/${scoreGuessId}`);
    }

    postScoreGuess(scoreGuess: PostGuessModel): Observable<ScoreGuessModel> {
      return this.post<ScoreGuessModel>(`${this.env.publicApiSocioUrl}v1/socio/scoreguess`, scoreGuess);
    }

    getScoreGuessByMemberId(memberId: string): Observable<ScoreGuessModel> {
      return this.get<ScoreGuessModel>(`${this.env.publicApiSocioUrl}v1/socio/scoreguess/member/${memberId}/guesses`);
    }

}
