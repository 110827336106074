import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, output, SimpleChanges, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';
import { CarouselItem } from './carousel-plans.model';
import { CarouselPlanCardComponent } from "./carousel-plan-card/carousel-plan-card.component";

@Component({
  selector: 'app-carousel-plans',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink, CarouselPlanCardComponent],
  templateUrl: './carousel-plans.component.html',
  styleUrl: './carousel-plans.component.scss'
})
export class CarouselPlansComponent {

  @ViewChild('carousel', {read: ElementRef}) carousel: ElementRef;
  @Input() items: CarouselItem[];
  @Input() styles: any;
  @Input() highlightButtonOptions: any;
  @Input() defaultButtonOptions: any;

  carouselResponse = output<CarouselItem>();

  chunkedItems: CarouselItem[][] = [];
  chunkSize = 4;
  isMobile = false;
  showIndicators = false;
  classes: any;
  classesAlign: any;
  versaoAtual: any;

  constructor(private layoutConfigService: LayoutConfigService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.items?.length) {
      this.chunkItems();
    }
  }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.classes = {
      'layout-v2': layoutVersion === 2,
    };
    this.classesAlign = {
      'align-items-center': layoutVersion === 1,
      'align-items-start': layoutVersion === 2
    };
    this.setCardSize(window.innerWidth);
    this.versaoAtual = layoutVersion;

    this.chunkItems();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setCardSize((event.target as Window).innerWidth);
    this.chunkItems();
  }

  setButtonConfig() {
    this.items.forEach(item => {
      if (item.highlight) {
        item.buttonOptions = this.highlightButtonOptions;
      } else {
        item.buttonOptions = this.defaultButtonOptions;
      }
    });
  }

  setCardSize(innerWidth: number) {
    this.isMobile = false;
    switch (true) {
      case innerWidth < 768:
        this.chunkSize = 1;
        this.isMobile = true;
        break;
      case innerWidth >= 768 && innerWidth < 990:
        this.chunkSize = 2;

        break;
      case innerWidth >= 990 && innerWidth < 1200:
        this.chunkSize = 3
        break;
      case innerWidth >= 1200:
        this.chunkSize = 4
        break;

      default:
        break;
    }
  }

  private chunkItems(): void {

    this.chunkedItems = [];
    console.log("size", this.chunkSize)

    for (let i = 0; i < this.items.length ; i += this.chunkSize) {
      const chunkedCarouselItems = this.items.slice(i, i + this.chunkSize);
      this.chunkedItems.push(chunkedCarouselItems);
    }
    this.showIndicators = this.chunkedItems.length > 1;
  }

  btnCarouselAction(plano: CarouselItem) {
    this.carouselResponse.emit(plano);
  }
}
