<div class="container p-4 p-md-5 login-screen">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            [buttonType]="'btn-secondary'"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="login-main-title my-4 text-center">
       <label for=""> {{pageTitle}}</label>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <form [formGroup]="loginFormGroup">
                <app-input-custom class="col"
                    [id]="'CPFInputId'"
                    [label]="'CPF'"
                    [mask]="InputMaskTypesEnum.CPF"
                    [placeholder]="'Insira seu CPF'"
                    formControlName="CPFControl"
                ></app-input-custom>
                <app-input class="col-md-6"
                    [id]="'passwordInputId'"
                    [label]="'Senha'"
                    [type]="InputTypeEnum.PASSWORD"
                    [placeholder]="'Digite sua senha'" 
                    formControlName="passwordControl"
                    (keyup.enter)="login()">
                </app-input>
                <a class="password-forgot" routerLink="/login/recuperar-senha">Esqueceu sua senha ?</a>
            </form>
            <div class="text-center my-5" id="btn-login">
                <app-button id="loginBtn" class="mx-2 my-1"
                    [isIcon]="true" [right]="true"
                    [buttonOptions]="loginBtn"
                    [iconName]="'bi bi-arrow-right'"
                    [disabled]="!loginFormGroup.valid"
                    [buttonType]="'submit'"
                    (buttonClick)="login()"></app-button>
            </div>
            <hr>
            <div class="text-center my-4" id="btn-wanna-be-partner">
                <div class="my-3 fs-medium">Ainda não é sócio ?</div>
                <app-button id="bePartnerBtn" class="mx-2 my-1" [buttonOptions]="wannaBePartnerBtn" routerLink="/cadastro-socio"></app-button>
            </div>
        </div>
    </div>
</div>
