<div class="container p-4 p-md-5 col-md-8">
    <div class="add-credit-card-main-title my-4" [style.color]="labelColor">
        {{title}}
    </div>
    <form [formGroup]="addCreditCardformGroup">
        <div class="row">
            <app-input-custom class="col" 
                [id]="'cardNumberInputId'"
                [placeholder]="'Insira o número do cartão'"
                [label]="'Número do cartão'"
                [mask]="InputMaskTypesEnum.CREDIT_CARD"
                formControlName="cardNumberControl"
            ></app-input-custom>
        </div>
        <div class="row">
            <app-input class="col-md-6"
                [id]="'expirationDateInputId'"
                [label]="'Data de validade'"
                [type]="InputTypeEnum.MONTH"
                [placeholder]="DateMode.MMAA"
                formControlName="expirationDateControl"
            ></app-input>
            <app-input class="col-md-6"
                [id]="'cvvInputId'"
                [label]="'CVV'" 
                [placeholder]="'CVV'"
                [type]="InputTypeEnum.NUMBER"
                [maxLength]="3"
                formControlName="cvvControl"
            ></app-input>
        </div>
        <div class="row">
            <app-input class="col" 
                [id]="'cardNameInputId'"
                [label]="'Nome do cartão'"
                [placeholder]="'Insira o nome do titular do cartão'"
                formControlName="cardNameControl"
            ></app-input>
        </div>
        <div class="row">
            <app-input-custom class="col"
                [id]="'CPFInputId'"
                [label]="'CPF'"
                [mask]="InputMaskTypesEnum.CPF"
                [placeholder]="'Insira o CPF do titular'"
                formControlName="CPFControl"
            ></app-input-custom>
        </div>
        <div class="d-flex flex-wrap justify-content-sm-between justify-content-center mt-4">
            <app-button id="cancel" class="my-2 mx-2"
                [buttonOptions]="btnCancelOptions"
                (buttonClick)="cancel()">
            </app-button>
            <app-button id="save" class="my-2 mx-2"
                [disabled]="addCreditCardformGroup.invalid"
                [buttonOptions]="btnSaveOptions"
                (buttonClick)="save()">
            </app-button>
        </div>
    </form>
</div>
