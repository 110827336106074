<button type="button"
    [attr.type]="type"
    [ngClass]="[buttonSize, buttonType]"
    [ngStyle]="styles"
    [disabled]="disabled"
    (click)="onClick()"
    [style]="style"
>
<mat-icon *ngIf="isIcon && left && iconType == 'mat-input'" class="mat-icon-tgglbtn" svgIcon="{{iconName}}"></mat-icon>
<i *ngIf="isIcon && left" [attr.class]="iconName"></i>
{{buttonText }}
<i *ngIf="isIcon && right" [attr.class]="iconName"></i>

</button>
