<div class="pt-4 pt-md-5 px-md-3 px-lg-0">
    <div class="d-flex justify-content-end" *ngIf="!selectPlan">
        <app-button id="back-desktop" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonBackOptions" (buttonClick)="back()">
        </app-button>
    </div>
    @if (showPlans) {
    <section class="section-upgrade-planos">
        <div class="container wrapper-upgrade-planos p-4">
            <div class="wrapper-upgrade-planos pb-3" *ngIf="items.length > 0">
                <app-logo-text class="mb-4"></app-logo-text>
                <p class="subtitle-upgrade-planos">Selecione o plano para fazer {{getOperationTypeLabel()}}</p>
            </div>
            <ng-container *ngIf="items.length > 0; else noPlans">
                <app-carousel-plans [items]="items" [styles]="styles" [defaultButtonOptions]="buttonOptionsCard"
                    [highlightButtonOptions]="buttonOptionsCardSpecial"
                    (carouselResponse)="carouselResponse($event)"></app-carousel-plans>
            </ng-container>
            <ng-template #noPlans>
                <div class="col-12 text-center py-5">
                    <b>{{ getNoPlansMessage() }}</b>
                </div>
            </ng-template>
        </div>
    </section>
    }
</div>

<app-modal [modalTitle]="'Pagamento em Aberto'" [modalId]="orderModalId" [modalBodyMessage]="orderModalBodyMessage"
    [showCloseBtn]="true" (confirmActionChange)="onOrderModalConfirm($event)">
</app-modal>

<app-payment-modal [modalId]="modalId" [paymentType]="paymentType" [paymentModalData]="paymentInvoiceModalData"
    (confirmActionChange)="onModalConfirm()">
</app-payment-modal>