import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { MemberShipCardsDependentsModel, MemberSituationModel, PostMemberModel, PostMemberPlansModel, PostPublicMemberPlansModel } from 'src/app/shared/models/memberModel';
import { Page } from 'src/app/shared/models/Page';
import { GeneralConfigModel } from 'src/app/shared/models/generalConfigModel';
import { MemberInviteTO } from 'src/app/shared/models/memberInviteModel';
import { FreePaymentDataPayload } from './payment-data.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends BaseService {

  memberId: string;
  memberLoginData: any;
  memberPlan: any;

  constructor(http: HttpClient,
    private userService: UserService,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  private env = environment;

  public update(data: any, id: string): Observable<any> {
    return this.http.put<any>(
      `${this.env.socioApiUrl}/members/` + id,
      {
        ...data,
      }
    );
  }

  public updateAddress(id: string, data: any): Observable<any> {
    return this.http.put<any>(
      `${this.env.socioApiUrl}/member-address/` + id,
      { ...data }
    );
  }

  putMember(member: PostMemberModel, memberId: string): Observable<PostMemberModel> {
    return this.http.put<PostMemberModel>(`${this.env.publicApiSocioUrl}v1/socio/member-address`, member);
  }

  postMember(member: PostMemberModel): Observable<PostMemberModel> {
    return this.http.post<PostMemberModel>(`${this.env.publicApiSocioUrl}v1/socio/members`, member);
  }

  postMemberAddress(member: PostMemberModel): Observable<PostMemberModel> {
    return this.http.post<PostMemberModel>(`${this.env.publicApiSocioUrl}v1/socio/member-address`, member);
  }

  postMemberPlans(member: PostPublicMemberPlansModel): Observable<PostMemberPlansModel> {
    return this.http.post<PostMemberPlansModel>(`${this.env.publicApiSocioUrl}v1/socio/member-plans`, member);
  }

  postFreeMemberPlans(member: FreePaymentDataPayload): Observable<FreePaymentDataPayload> {
    return this.http.post<FreePaymentDataPayload>(`${this.env.publicApiSocioUrl}v1/socio/member-plans`, member);
  }

  postMemberPlansLogged(member: PostMemberPlansModel): Observable<PostMemberPlansModel> {
    return this.http.post<PostMemberPlansModel>(`${this.env.socioApiUrl}/member-plans`, member);
  }

  sendInviteEmail(memberInviteTO: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/member-invites/send-invite`, memberInviteTO);
  }

  setMemberId(id: string): void {
    this.memberId = id;
  }

  getMemberId(): string {
    return this.memberId;
  }

  setMemberLoginData(loginData: any): void {
    this.memberLoginData = loginData;
  }

  getMemberLoginData(): any {
    return this.memberLoginData;
  }

  setMemberPlan(plan: any): void {
    this.memberPlan = plan;
  }

  getMemberPlan(): any {
    return this.memberPlan;
  }

  getMemberPublicInfo(cpf: string): Observable<PostMemberModel> {
    return this.get<PostMemberModel>(`${this.env.publicApiSocioUrl}v1/socio/members/cpf/${cpf}`).pipe(tap(user => {
      this.userService.setCurrentUser(user);

      this.userService.setCurrentUserSubject(user);
    }))
  }

  getMemberDashboardData(id: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/members/get-member-dashboard-data/${id}`);
  }



  getCurrentPlan(id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('showError', 'false');
    return this.get<any>(`${this.env.socioApiUrl}/member-plans/details/${id}`, { params });
  }

  getMembePlanDashboardData(id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('showError', 'false');
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-plans/get-member-plan-dashboard-data/${id}`,{params});
  }


  getDataUserByCpf(cpf: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiAuthUrl}v1/auth/users/user-by-cpf/${cpf}`);
  }

  resendEmail(member: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiAuthUrl}v1/auth/users/resendEmail`, member);
  }

  getMembeInvoiceDashboardData(id: string): Observable<any[]> {
    return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/invoices/get-member-invoices-dashboard-data/${id}`);
  }

  getMemberDependentesByMember(id: string): Observable<Page<any>> {
    return this.get<Page<any>>(`${this.env.publicApiSocioUrl}v1/socio/memberDependents/search/${id}`);
  }

  getLastMembeInvoiceDashboardData(id: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/invoices/get-last-member-invoices-dashboard-data/${id}`);
  }

  getMembeInvoiceDashboardDataDetail(id: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/invoices/get-member-invoices-dashboard-data/by-invoice-id/${id}`);
  }

  getMemberShipCardDashboardData(id: string): Observable<MemberShipCardsDependentsModel> {
    let params = new HttpParams();
    params = params.append('showError', 'false');
    return this.get<MemberShipCardsDependentsModel>(`${this.env.publicApiSocioUrl}v1/socio/membership-cards/get-memberShipCard-dashboard-data/${id}`,{params});
  }

  getMemberShipCardsDependentsDashboardData(id: string): Observable<MemberShipCardsDependentsModel[]> {
    return this.get<MemberShipCardsDependentsModel[]>(`${this.env.publicApiSocioUrl}v1/socio/membership-cards/get-memberShipCard-dependents-dashboard-data/${id}`);
  }

  getAllMemberNumbers(): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/members/totalMembers`);
  }


  getMemberIndicationByMember(memberId: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-invites-link/get-member-indication-by-member/${memberId}`);
  }

  getAllMemberInvitationsByMember(memberId: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-invites/all-invitations-sent/${memberId}`);
  }

  getDocumentsMember(memberId: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer seu-token-aqui'
    });
    return this.get<any>(`${this.env.socioApiUrl}/member-documents/member/${memberId}`, { headers });
  }

  getGeneralConfig(): Observable<Page<GeneralConfigModel>> {
    return this.get<Page<GeneralConfigModel>>(`${this.env.publicApiSocioUrl}v1/socio/general-configuration-portal`);
  }

  setAcceptTerms(id: string): Observable<any> {
    return this.http.post<any>(`${this.env.socioApiUrl}/members/accept-terms?memberId=${id}`, {});
  }

  createMemberInviteLink(inviteLink: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/member-invites-link/create-member-invite-link`, inviteLink);
  }

  createMemberInviteEmail(inviteMember: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/member-invites/creat-member-invite`, inviteMember);
  }

  updateAutomaticRenewal(memberId: string, automaticRenewal: boolean): Observable<any> {
    return this.http.put<any>(`${this.env.socioApiUrl}/member-plans/${memberId}/automatic-renewal?automaticRenewal=${automaticRenewal}`,{});
  }

  getAmountMembersBySituation(): Observable<number> {
    return this.get<number>(`${this.env.publicApiSocioUrl}v1/socio/members/get-amount-members-by-situation`);
  }




  getMemberSituations() {
    return this.http.get<Page<MemberSituationModel>>(
      `${this.env.socioApiUrl}/member-situation`
    );
  }

  isMemberSituationInEnum(id: string): boolean {
    return Object.values(MemberSituationEnum).includes(id as MemberSituationEnum);
  }
}

enum MemberSituationEnum {
  Desistente = 'ffcd8b0d-5ed1-4441-a562-aa26fa61ae73',
  Lead = 'ddcd8b0d-5ed1-4441-a562-aa26fa61ae55',
  NaoRenovado = 'a8c3839b-5fb1-4e99-8869-9e30bfee4f7e',
  Cancelado = 'fcb993b1-176b-412e-b206-755f48b7f0c9'
}