<div class="container px-4 py-5">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="btnBackOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="d-flex flex-column align-items-center pb-4">
        <p class="h1">FAQ</p>
        <p class="h6">Perguntas frequentes</p>
    </div>
    <div class="row justify-content-center">
        <div class="col">
            <div class="accordion" id="faqAccordion">

                @for (item of questions; track $index) {
                    <div class="accordion-item mb-3">
                        <h2 class="accordion-header" id="heading{{$index }}">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+$index"
                                aria-expanded="false" [attr.aria-controls]="'collapse'+$index">
                                <i class="bi bi-caret-down-fill me-2"></i>
                                {{ item.title }}
                            </button>
                        </h2>
                        <div id="collapse{{ $index }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+$index"
                            data-bs-parent="#faqAccordion">
                            <div class="accordion-body" [innerHTML]="item.content"> </div>
                        </div>
                    </div>
                }




                <!-- Repeat similar blocks for other FAQ items -->
                <!-- Example: FAQ Item 3, Item 4, ... -->

            </div>
        </div>
    </div>
</div>