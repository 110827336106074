import { CommonModule } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { Router, RouterLink } from '@angular/router';
import { ConteudoExclusivoModel } from '../../models/conteudoExclusivoModel';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-carousel-simple',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink, CarouselModule],
  templateUrl: './app-carousel-simple.component.html',
  styleUrl: './app-carousel-simple.component.scss'
})
export class AppCarouselSimpleComponent implements OnInit {
  title = input.required<string>();
  colorTitle = input.required<string>();
  carouselData = input.required<ConteudoExclusivoModel[]>();

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    margin: 27,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      600: {
        items: 2
      },
      700: {
        items: 3
      },
      940: {
        items: 3
      },
      1240: {
        items: 3.5
      },
      1540: {
        items: 3.5
      },
      1840: {
        items: 6
      },
      2140: {
        items: 6
      },
      2440: {
        items: 6
      }
    },
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }


  handleClick(object: any, event?: Event){
    event?.preventDefault();
    if(object.url && object.type === 'VIDEO') {
      this.goToUrl(object.url)
    }
    if(object.type === 'ARTICLE'){
      this.router.navigateByUrl(`conteudo-exclusivo/detalhe/${object.id}`)
    }
  }

  goToUrl(url: string) {
    if(!url)return
    // if (url.includes(environment.dns)) {
    //   this.router.navigateByUrl(url);
    // } else {
      window.open(url, '_blank');
    // }
  }

}
