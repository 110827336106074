import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivateFn } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { throwError } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUser = userService.currentUserValue ?? {} ;
  const token = authService.getToken() ?? '' ;

  if (token && authService.isTokenExpired(token)) {
    authService.logout(state.url);
    return throwError(() => new Error('Token expired'));
  }

  if (
    Object.keys(currentUser)?.length &&
    token
  ){
    return true;
  }

  authService.logout(state.url);
  return false;
};
