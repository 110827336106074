import { Component, OnDestroy, input, model } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AppInputComponent, InputType } from '../app-input/app-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
declare var bootstrap: any;

@Component({
  selector: 'app-guess-modal',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule],
  templateUrl: './guess-modal.component.html',
  styleUrl: './guess-modal.component.scss'
})
export class GuessModalComponent implements OnDestroy {
  matchModalData = input<any>();
  confirmAction = model();

  matchGuessGroup = new FormGroup({
    guessTeam1Control: new FormControl<number>(0, Validators.required),
    guessTeam2Control: new FormControl<number>(0, Validators.required),
  });

  confirmBtn = {
    buttonText: 'Confirmar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };
  InputTypeEnum = InputType;
  private modalSubscription: Subscription | undefined;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === 'modal-guess') {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            const modal = new bootstrap.Modal(modalElement);
            modal.show();
          } else if (state.action === 'close') {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              modal.hide();
            }
          }
        }
      }
    });
  }

  onConfirm() {
    let guessResponse = {...this.matchGuessGroup.value, id: this.matchModalData().id};
    this.confirmAction.set(guessResponse);
    this.modalService.close('modal-guess');
    this.matchGuessGroup.reset();
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
