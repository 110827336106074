import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  standalone: true
})
export class CustomCurrencyPipe  implements PipeTransform {
  transform(value: number | null, currencyCode: string = 'BRL'): string {
    if (!value) {
      return ''; // Handle cases where value is null or undefined
    }

    // Remove currency symbol from the value before formatting
    const valueWithoutSymbol = value.toString().replace(/[^0-9.-]/g, '');
    const valueWithoutSymbolAsNumber = parseFloat(valueWithoutSymbol);

    // Format the value without the symbol
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal', // Use 'decimal' style to avoid symbol
      minimumFractionDigits: 2, // Adjust if needed
    }).format(valueWithoutSymbolAsNumber);

    // Modify the formatted string to add space, symbol, and handle sign
    const symbol = value >= 0 ? 'R$ ' : 'R$ '; // Add space and handle sign
    return `${symbol}${formattedValue}`;
  }
}
