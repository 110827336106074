import {DatePipe} from '@angular/common';
import {HostListener, Injectable} from '@angular/core';
import {CreditCardInfoModel} from 'src/app/shared/models/paymentData';
import {LocalStorageService} from './local-storage.service';
import {Observable} from 'rxjs';
import {BaseService} from './base/base.service';
import {HttpClient, HttpContext, HttpContextToken} from '@angular/common/http';
import {ErrorHandlerService} from './error-handler.service';
import {environment} from 'src/environments/environment';
import {FileService} from './file.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {


  constructor(private datePipe: DatePipe,
              private localStorageService: LocalStorageService,
              private fileService: FileService,
              http: HttpClient,
              errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  private env = environment;
  viacepUrl: string = `https://viacep.com.br/ws/`;


  // TODO: Checar como a data vai vir do servidor e alterar se necessário
  formateDateInput(date: string, mode: DateMode): string {
    let dateOutput = '';
    switch (mode) {
      case DateMode.MMAA:
        dateOutput = this.datePipe.transform(date, 'yyyy-MM', 'en-US') as string;
        break;
      case DateMode.DDMMAA:
        dateOutput = this.datePipe.transform(date, 'yyyy-MM-dd', 'en-US') as string;
        break;
      default:
        break;
    }
    return dateOutput;
  }

  getCreditCardOperator(cardNumber: string): string {
    if (!cardNumber) return "Unknown";

    // Ensure card_number is a string and extract the first 6 digits
    const iin = parseInt(cardNumber.slice(0, 6));

    // Define the IIN ranges for different card operators
    const cardOperators: { [key: string]: [number, number][] } = {
      'Visa': [[400000, 499999]],
      'Mastercard': [[510000, 559999], [222100, 272099]],
      'American Express': [[340000, 349999], [370000, 379999]],
      'Diners Club': [[300000, 305999], [360000, 369999], [380000, 399999]],
      'Discover': [
        [601100, 601109], [601120, 601149], [601174, 601174],
        [601177, 601179], [601186, 601199], [644000, 659999]
      ],
      'Elo': [
        [506699, 506778], [509000, 509999], [650031, 650033], [650035, 650051],
        [650405, 650439], [650485, 650538], [650541, 650598], [650700, 650718],
        [650720, 650727], [650901, 650920], [651652, 651679], [655000, 655019],
        [655021, 655058]
      ],
      'Hipercard': [
        [606282, 606282], [637095, 637095], [637568, 637568],
        [637599, 637599], [637609, 637609], [637612, 637612]
      ]
    };

    // Check which operator the IIN belongs to
    for (const operator in cardOperators) {
      for (const range of cardOperators[operator]) {
        if (iin >= range[0] && iin <= range[1]) {
          return operator;
        }
      }
    }

    return "Unknown Card Operator";
  }

  getCardIcon(cardOperator: string): string {
    switch (cardOperator) {
      case 'Visa':
        return 'visa';
      case 'Mastercard':
        return 'master';
      case 'Elo':
        return 'elo';
      case 'American Express':
        return 'american-express';
      case 'Diners Club':
        return 'diners-club';
      case 'Discover':
        return 'discover';
      case 'Hipercard':
        return 'hipercard';
      // Add other card operators and their icon code
      default:
        return ''; // Set to null for unknown operators
    }
  }

  getCreditCardInfoFromLocalStorage(): CreditCardInfoModel[] {
    const creditCardArr = this.localStorageService.getItem('credit-card-info');
    if (creditCardArr) {
      return creditCardArr; // Return array
    } else {
      return []; // Return null if no data found
    }
  }

  getAddressByCep(cep: string): Observable<any> {
    return this.http.get(this.viacepUrl + cep + `/json/`, {
      context: new HttpContext().set(NO_AUTH, true)
    });
  }

  getState(): Observable<any> {
    return this.http.get<any>(this.env.socioApiUrl + '/adresses/states');
  }

  getCity(stateId: any): Observable<any> {
    return this.http.get<any>(`${this.env.publicApiSocioUrl}v1/socio/city/state/${stateId}`);
  }

  getStatePublic(): Observable<any> {
    return this.http.get<any>(this.env.publicApiSocioUrl + 'v1/socio/adresses/states');
  }

  getInvertedDate(date: string): string {
    return date.split('-').reverse().join('/');
  }

  getImageStr(imgId: string): string {
    let imageUrl;
    if (imgId) imageUrl = this.fileService.getDocDownloadUrl(imgId as string);
    return imageUrl as string;
  }

  getFormattedPhone(phone: string): string {
    if (phone.length === 11) {
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else {
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  }

  getCnpjFormated(cnpj: string): string {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  getRemFontSize(fontSize: string, isMobile: boolean): string {
    return isMobile ?  (parseInt(fontSize) / 24) + 'rem' : (parseInt(fontSize) / 16) + 'rem';
  }

}

export enum DateMode {
  MMAA = 'MM/AA',
  DDMMAA = 'DD/MM/AA'
}

export const NO_AUTH = new HttpContextToken(() => false);
