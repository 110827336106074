import { Component, Input, Provider, forwardRef, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';

const COUNTRY_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppSelectInputComponent),
  multi: true,
};

@Component({
  selector: 'app-select-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ValidationErrorsComponent],
  providers: [COUNTRY_CONTROL_VALUE_ACCESSOR],
  templateUrl: './app-select-input.component.html',
  styleUrls: ['./app-select-input.component.scss']
})
export class AppSelectInputComponent<T> extends ControlValueAccessorDirective<T> {
  @Input() id = 'inputId';
  @Input() label = 'Label';
  @Input() options: SelectModel[] = [];
  customErrorMessages: Record<string, string> = {};
  // TODO: Rever estrutura de color
  labelColor = input('white');
}

export interface SelectModel {
  code: string
  name: string
}
