import { CommonModule } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { FileUploadComponent } from 'src/app/shared/components/app-file-upload/app-file-upload.component';
import { Location } from '@angular/common';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { ExclusiveContentService } from 'src/app/core/services/exclusive-content.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-details-content',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './details-content.component.html',
  styleUrl: './details-content.component.scss',
})
export class DetailsContentComponent {
  labelColor = 'white';
  currentUser: UserModel;
  accessToken: string;
  listDocuments: any;
  showContent = false;

  content: any;

  constructor(
    private location: Location,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private commonService: CommonService,
    private elRef: ElementRef,
    private contentService: ExclusiveContentService
  ) {
    this.currentUser = this.userService.currentUserValue ?? ({} as UserModel);
    this.accessToken = this.localStorageService.getItem('token');
  }

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param: any) => {
      if (param && Object.keys(param).length) {
        console.log(param.id);
        this.contentService
          .getExclusiveContentById(param.id)
          .subscribe((res: any) => {
            this.content = res;
            this.showContent = true;
          });
      }
    });
  }
  ngAfterViewChecked(): void {
    // Seleciona a div pelo ID e, em seguida, as tags <img> dentro dela
    const contentContainer =
      this.elRef.nativeElement.querySelector('#content-container');
    const images = contentContainer.querySelectorAll('img');

    // Aplica o estilo "width: 100%" a cada tag <img>
    images.forEach((img: HTMLImageElement) => {
      img.style.width = '100%';
    });
  }

  showAlert(type: AlertType, message: string): void {
    this.alertService.showAlert(type, message);
  }

  back() {
    this.location.back();
  }
}
