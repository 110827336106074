<div class="card text-center">
    <div class="p-2 p-md-3 card-body d-flex flex-column align-items-center justify-content-between">
      <h5 class="card-title">{{ title }}</h5>
      <p *ngIf="!icon" class="card-text mt-auto mb-auto">{{ value }}</p>
      <div *ngIf="icon || extraText" class="d-flex flex-column mt-auto mb-auto tendencia">
        <i *ngIf="icon" class="bi" [ngClass]="icon"></i>
        <small *ngIf="extraText" class="h6 m-0">{{ extraText }}</small>
      </div>
    </div>
  </div>
