import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo-text.component.html',
  styleUrls: ['./logo-text.component.scss']
})
export class LogoTextComponent implements OnInit{

  @Input()
  inputStyle: any;
     
  // public stylesJson: any = {
  //   prefixText: 'Somos',
  //   highlightedText: '100.000',
  //   suffixText: 'torcedores',
  //   logoUrl: '/assets/imagens/logo.png',
  //   prefixColor: '#5F83BF',
  //   highlightedColor: '#E0C252',
  //   suffixColor: '#5F83BF',
  //   prefixWeight: '400',
  //   highlightedWeight: 'bold',
  //   suffixWeight: '400',
  //   prefixSize: '14px',
  //   highlightedSize: '14px',
  //   suffixSize: '15px',
  //   primaryColor: '#E0C252',
  //   secondaryColor: '#191844'
  // };
  
  styles: { '--primaryColor': string; '--secondaryColor': string; '--prefixColor': string; '--highlightedColor': string; '--suffixColor': string; '--prefixWeight': string; '--highlightedWeight': string; '--suffixWeight': string; '--prefixSize': string; '--highlightedSize': string; '--suffixSize': string; };

  constructor () {

  }

  ngOnInit(): void {    
    this.setStyleProperties();
  }

  private setStyleProperties() {
    this.styles = {
      '--primaryColor':  this.inputStyle?.primaryColor,
      '--secondaryColor':  this.inputStyle?.secondaryColor,
      '--prefixColor': this.inputStyle?.prefixTextColor,
      '--highlightedColor':  this.inputStyle?.highlightedColor,
      '--suffixColor':  this.inputStyle?.suffixColor,
      '--prefixWeight':  this.inputStyle?.prefixWeight,
      '--highlightedWeight':  this.inputStyle?.highlightedWeight,
      '--suffixWeight':  this.inputStyle?.suffixWeight,
      '--prefixSize':  this.inputStyle?.prefixSize,
      '--highlightedSize':  this.inputStyle?.highlightedSize,
      '--suffixSize':  this.inputStyle?.suffixSize
    };
  }


}
