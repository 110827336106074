import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cep',
    standalone: true
})
export class CepPipe implements PipeTransform {
    transform(value: string): string {
        // Verifica se o valor é um CEP válido
        if (!value || typeof value !== 'string') {
            return value;
        }

        // Remove todos os caracteres que não sejam dígitos do CEP
        const cep = value.replace(/\D/g, '');

        // Formata o CEP no padrão 00000-000
        let formattedCep = '';
        if (cep.length === 8) {
            formattedCep = `${cep.slice(0, 5)}-${cep.slice(5)}`;
        } else {
            // Se o CEP não tiver 8 dígitos, retorna o valor sem formatação
            formattedCep = cep;
        }

        return formattedCep;
    }
}