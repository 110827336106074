import { CommonModule } from '@angular/common';
import { Component, computed, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MemberService } from 'src/app/core/services/member.service';
import { MemberInviteTO } from 'src/app/shared/models/memberInviteModel';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'app-convidar-amigo',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule, MatIconModule],
  templateUrl: './convidar-amigo.component.html',
  styleUrl: './convidar-amigo.component.scss'
})
export class ConvidarAmigoComponent implements OnInit {

  currentPage: number = 1;
  pageSize: number = 15;
  totalPages: number = 0;

  convitesEnviados: any[] = [];
  convitesEnviadosPerPage: any[] = [];

  typeActive = 'email';
  filterGroup = new FormGroup({
    filterControl: new FormControl<string>('', Validators.required)
  });
  indicacoesRealizadas: any[] = [];

  link: any;
  member: any;

  backBtn = {
    buttonText: 'Voltar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-secondary',
  };

  sendBtn = {
    buttonText: 'Enviar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  buttonEmailOptions = {
    buttonText: 'Email',
    buttonBorderWidth: '1px',
    buttonSize: `btn btn-md px-5 btn-primary`,
    borderRadius: '25px'
  };
  style = {
    '--primary-color': localStorage.getItem('primaryColor')
  }

  constructor(private location: Location, private memberService: MemberService, private userService: UserService, private alertService: AlertService,private router: Router) { this.member = this.userService.currentUserValue as UserModel; }

  back() {
    this.location.back();
  }

  ngOnInit(): void {
    this.validateAccess();
    this.link = `${window.location.origin}/cadastro-socio?memberId=${this.member.id}&social=`;
    this.getAllMemberInvitationsByMember();
  }

  getAllMemberInvitationsByMember() {
    this.convitesEnviados = [];
    this.memberService.getAllMemberInvitationsByMember(this.member.id).subscribe(res => {
      this.convitesEnviados = res.content;
      this.totalPages = Math.ceil(this.convitesEnviados?.length / this.pageSize);
      this.updatePagedItems();
    })
  }

  sendInvite() {
    if (this.filterGroup.valid) {
      const currentUser = JSON.parse(localStorage.getItem('current-user') || '{}');
      const memberId = currentUser.id;

      const email = this.filterGroup.get('filterControl')?.value;
      if (email) {
        const memberInviteTO: any = {
          emailIndicated: email.toLowerCase(), // Converte o email para minúsculas
          url: `${window.location.origin}/cadastro-socio`,
          memberId: memberId
        };

        this.memberService.sendInviteEmail(memberInviteTO).subscribe(response => {
          this.showAlert(AlertType.SUCCESS, 'Convite enviado com sucesso!');
          this.getAllMemberInvitationsByMember();
        }, error => {
          console.error('Erro ao enviar convite', error.error.message);
          this.showAlert(AlertType.WARNING, error.error.message);
        });
      } else {
        this.showAlert(AlertType.DANGER, 'Por favor, insira um email válido.');
      }
    }
  }

  showAlert(type: AlertType, message: string): void {
    this.alertService.showAlert(type, message);
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  updatePagedItems() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.convitesEnviadosPerPage = this.convitesEnviados.slice(startIndex, endIndex);
  }

  nextPage() {
    
    if (this.currentPage < this.totalPages) {
      this.currentPage = this.currentPage + 1;
      this.updatePagedItems();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      // this.currentPage()--;
      this.currentPage = this.currentPage - 1;
      this.updatePagedItems();
    }
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePagedItems();
    }
  }

  // Método de validação de acesso
  private validateAccess(): void {
    const activeSections = localStorage.getItem('activeSections');

    if (!activeSections) {
      this.handleAccessDenied();
      return;
    }

    const parsedSections = JSON.parse(activeSections);

    if (!parsedSections['convidar amigo']) {
      this.handleAccessDenied();
    }
  }

  // Tratamento de acesso negado
  private handleAccessDenied(): void {
    this.alertService.showAlert(AlertType.WARNING, 'Você não possui acesso a essa página');
    this.router.navigate(['/dashboard']);
  }

}
