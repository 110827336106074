<div class="container p-4 p-md-5">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backOptions" (buttonClick)="back()">
        </app-button>
    </div>
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="partner-data-main-title mb-4 text-center" [style.color]="labelColor">
        {{pageTitle}}
    </div>

    <form [formGroup]="memberRegistrationGroup">
        <div class="row">
            <app-input class="col-md-6" [id]="'fullNameInputId'" [type]="InputTypeEnum.NAME" [isUpperCase]="true"
                [label]="'Nome Completo'" [placeholder]="'Insira seu Nome'" formControlName="fullnameControl">
            </app-input>
            <app-input-custom class="col-md-3" [id]="'CPFInputId'" [label]="'CPF'" [mask]="InputMaskTypesEnum.CPF"
                [placeholder]="'Insira seu CPF'" formControlName="cpfControl"
                (input)="validateCpfNumber(controls.cpfControl)">
            </app-input-custom>
            <app-input-custom class="col-md-3" [id]="'dateBirthInputId'" [label]="'Data de Nascimento'" [mask]="InputMaskTypesEnum.DATA"
            [placeholder]="'Informe seu nascimento'" formControlName="birthDate"
            (input)="validateDateBirth()" [dropSpecialCharacters]="false">
            </app-input-custom> 
        </div>
        <div class="row">
            <app-select-input class="col-md-3" [id]="'genderInputId'" [label]="'Sexo'" [options]="genderOptions"
                formControlName="genderControl"></app-select-input>
            <app-input class="col-md-6" [id]="'emailInputId'" [label]="'Email'" [type]="InputTypeEnum.EMAIL"
                [placeholder]="'Insira seu email'" formControlName="emailControl">
            </app-input>
            <app-input-custom class="col-md-3" [id]="'phoneInputId'" [label]="'Celular'"
                [placeholder]="'Insira seu celular'" [mask]="InputMaskTypesEnum.PHONE" formControlName="phoneControl">
                ></app-input-custom>
        </div>
        <div class="row">
            <app-input class="col-md-6" [id]="'passwordInputId'" [label]="'Senha'" [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Crie sua senha'" formControlName="passwordControl"></app-input>
            <app-input class="col-md-6" [id]="'confirmPasswordInputId'" [label]="'Confirmar Senha'"
                [type]="InputTypeEnum.PASSWORD" [placeholder]="'Confirme sua senha'"
                formControlName="confirmPasswordControl"></app-input>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-between my-3">

            <div class="d-flex align-items-center">
                <app-checkbox [id]="'termsConfirmationInputId'" [label]="checkboxLabel"
                    formControlName="termsConfirmationInputId"></app-checkbox>Li e concordo com os 
                    <a href="#" (click)="showTermsAndPolicies($event, 'terms')" class="mx-1">termos de uso</a> e a <a  href="#" class="mx-1" (click)="showTermsAndPolicies($event, 'policies')"> política de
                    privacidade</a>
            </div>


            <app-button id="btn-cadastrar" 
            [disabled]="memberRegistrationGroup.invalid" [buttonOptions]="buttonOptions"
                (buttonClick)="onSubmit()">
            </app-button>
        </div>
    </form>
</div>

<app-modal 
[modalTitle]="termsAndPoliciesModalTitle" 
[modalId]="termsAndPoliciesModalId" 
[modalBodyMessage]="termsAndPoliciesModalBodyMessage"
[size]="'modal-xl'"
[showCloseBtn]="false"
[scrollable]="scrollable"></app-modal>