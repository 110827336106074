import { CommonModule } from '@angular/common';
import { Component, OnInit, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ExclusiveContentService } from 'src/app/core/services/exclusive-content.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { StyleContentExclusive } from 'src/app/shared/models/styleContent';
import { SafePipe } from 'src/app/shared/pipe/safe.pipe';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

@Component({
  selector: 'app-section-conteudo-exclusivo',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RouterLink,
    SafePipe
  ],
  templateUrl: './section-conteudo-exclusivo.component.html',
  styleUrl: './section-conteudo-exclusivo.component.scss'
})
export class SectionConteudoExclusivoComponent implements  OnInit {

  movies: any;

  chunkedItems:ChunckModel [][] = [];
  chunkSize = 4;
  isMobile = window.innerWidth < 768;
  showIndicators = false;

  scrollAmount: number;
  public styles: { [key: string]: any };
  public deluxePartnerBtn: any;
  versao: number = 1;
  exclusiveStyles: StyleContentExclusive;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
    this.setMainStyle(this.exclusiveStyles);
    this.setCardSize(window.innerWidth);
    this.chunkItems();
  }

  constructor(
    private layoutConfigService: LayoutConfigService,
    private exclusiveContentService: ExclusiveContentService,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.versao = layoutVersion;
    this.setCardSize(window.innerWidth);
    this.getExclusiveContentSec();
    this.getExclusiveContent();
  }

  chunkItems(): void {
    if(!this.movies) return;
    this.chunkedItems= [];
    for (let i = 0; i < this.movies.length; i += this.chunkSize) {
      this.chunkedItems.push(this.movies.slice(i, i + this.chunkSize));
    }
    this.showIndicators = this.chunkedItems.length > 1;
  }
  
  setCardSize(innerWidth: number) {
    this.isMobile = false;
    switch (true) {
      case innerWidth < 768:
        this.chunkSize = 1;
        this.isMobile = true;
        break;
      case innerWidth >= 768 && innerWidth < 990:
        this.chunkSize = 2;

        break;
      case innerWidth >= 990 && innerWidth < 1200:
        this.chunkSize = 3
        break;
      case innerWidth >= 1200:
        this.chunkSize = 4
        break;

      default:
        break;
    }
  }

  getExclusiveContent(): void {
    this.exclusiveContentService.getExclusiveContent().subscribe({
      next: (response: any) => {
        this.movies = response.content.filter((content: any) => content.exclusive == false);
        this.chunkItems();
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    });
  }

  getExclusiveContentSec(): void {
    this.exclusiveContentService.getExclusiveContentSection().subscribe({
      next: (response: StyleContentExclusive) => {
        this.exclusiveStyles = response;

        this.setMainStyle(this.exclusiveStyles);

        this.setBtnStyle(this.exclusiveStyles);
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    });
  }

  private setMainStyle(exclusiveStyles: StyleContentExclusive): void {
    if (!exclusiveStyles) {
      return;
    }

    this.styles = {
      sectionTitle: exclusiveStyles.sectionTitle,
      subtitleText: exclusiveStyles.subtitleText,
      showSubtitle: exclusiveStyles.showSubtitle,
      '--section-title-font-size': this.commonService.getRemFontSize(exclusiveStyles.sectionTitleFontSize, this.isMobile),
      '--section-title-color': exclusiveStyles.sectionTitleColor,
      '--show-section': exclusiveStyles.showSection ? 'block' : 'none',
      '--subtitle-font-size': this.commonService.getRemFontSize(exclusiveStyles.subtitleFontSize, this.isMobile),
      '--subtitle-color': exclusiveStyles.subtitleColor,
      '--show-subtitle': exclusiveStyles.showSubtitle ? 'block' : 'none',
      '--background-color': exclusiveStyles.backgroundColor,
      '--background-image': exclusiveStyles.backgroundIsImage ? `url(${this.commonService.getImageStr(exclusiveStyles.document?.id)})` : 'none',
      '--side-buttons-color': exclusiveStyles?.sideButtonsColor,
      '--side-buttons-border-color': exclusiveStyles?.sideButtonsBorder ? exclusiveStyles?.sideButtonsBorderColor : 'none',
      '--side-buttons-icon-color': exclusiveStyles?.sideButtonsIconColor == 'true' ? 'invert(1)' : 'invert(0)',
      '--carousel-indicators-color': exclusiveStyles?.sideButtonsColor,
    };
  }

  private setBtnStyle(exclusiveStyles: StyleContentExclusive): void {
    if (!exclusiveStyles) {
      return;
    }

    this.deluxePartnerBtn = {
      buttonText: 'TORNE-SE SÓCIO',
      primaryColor: exclusiveStyles.buttonColor,
      secondaryColor: '',
      buttonTextColor: exclusiveStyles.buttonTextColor,
      borderRadius: '25px',
      buttonBorderWidth: exclusiveStyles.hasButtonBorder ? '1px' : 'none',
      buttonBorderColor: exclusiveStyles.hasButtonBorder ? exclusiveStyles.buttonBorderColor : 'none',
      buttonSize: 'btn btn-md',
      btnBorderHover: exclusiveStyles.buttonBorderWhenHovering,
      btnBorderHoverColor: exclusiveStyles.buttonBorderColorWhenHovering,
      btnTextHoverColor: exclusiveStyles.buttonTextColorWhenHovering,
      btnHoverColor: exclusiveStyles.buttonColorWhenHovering,

    };
  }

  getVideoId(videoUrl: string) {
    return this.extractVideoId(videoUrl);
  }

  private extractVideoId(url: string): string {
    let videoId = '';

    if (url?.includes('youtube.com')) {
      const splitParts = url?.split('?v=');
      if (splitParts.length > 1) {
        videoId = splitParts[1];
      } else {
        videoId = url?.split('youtu.be/')[1];
      }
    } else if (url?.includes('youtu.be/')) {
      videoId = url?.split('youtu.be/')[1];
    }

    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }
}

interface ChunckModel {
  document:{id:string},
  url:string,
}