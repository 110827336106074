import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { UserModel } from 'src/app/shared/models/userModel';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private env = environment;

  private currentUserSubject = new BehaviorSubject<UserModel | null>(this.currentUserValue);
  public currentUser: Observable<UserModel | null> = this.currentUserSubject.asObservable();

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,
    private localStorageService: LocalStorageService) {
    super(http, errorHandlerService);
  }

  public removeCurrentUser() {
    return this.localStorageService.removeItem('current-user');
  }

  public setCurrentUser(user: UserModel | null) {
    return this.localStorageService.setItem('current-user', user);
  }

  public get currentUserValue(): UserModel | null {
    return this.localStorageService.getItem('current-user');
  }

  setCurrentUserSubject(user: UserModel): void {
    this.currentUserSubject.next(user);
  }

  // Método para consultar se o CPF está na lista negra para login
  public canLogin(cpf: string): Observable<boolean> {
    const url = `${this.env.publicApiSocioUrl}v1/socio/members/can-login/${cpf}`;
    return this.http.get<boolean>(url).pipe(
      tap((response: boolean) => {
      })
    );
  }

  
  public getMemberFinalScoreByMemberId(memberId: string): Observable<any> {
    const url = `${this.env.socioApiUrl}/member-final-score/member/${memberId}`;
    return this.http.get<any>(url).pipe(
      tap((response: any) => {
        // Handle the response if needed
      })
    );
  }
}
