import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveSectionsService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

    getActiveSections() {
      return this.get(`${this.env.publicApiSocioUrl}v1/socio/activesections`);
    }

}