import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { UserActionsComponent } from 'src/app/shared/components/user-actions/user-actions.component';
import { TruncateTextDirective } from 'src/app/shared/directives/truncate-text.directive';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { ExperienceService } from 'src/app/core/services/experience.service';

@Component({
  selector: 'user-dropdown',
  standalone: true,
  imports: [CommonModule, UserActionsComponent, TruncateTextDirective],
  templateUrl: './user-dropdown.component.html',
  styleUrl: './user-dropdown.component.scss'
})
export class UserDropdownComponent implements OnInit {

  userInfo = input.required<UserModel>();
  styles = input.required<any>();
  userBlocked = input<boolean>();

  userRank: string = '';
  userScore: string = '';
  userPhoto: string = '';


  constructor(private userService: UserService,
    private experienceService: ExperienceService
  ) { }

  ngOnInit(): void {
    this.loadUserDetails();
  }

  private loadUserDetails(): void {
    const user = this.userInfo();
    if (user && user.id) {

      this.experienceService.getMemberScore(user.id).subscribe(score => {
        this.userScore = score;
      })

      this.userService.getMemberFinalScoreByMemberId(user.id).subscribe({
        next: (response) => {
          this.userRank = response?.currentPosition;
          // this.userScore = response?.totalPoints;
          this.userPhoto = response?.photo || 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745';

        },
        error: (err) => {
          console.error('Error fetching user details', err);
        }
      });
    }
  }
}