<div class="field-form-control" *ngIf="control">
  <label [for]="id" *ngIf="label !== ''" class="form-label" [style.color]="labelColor" [class.required]="isRequired">{{
    label
  }}</label>
  @if (type===InputType.NUMBER) {
    <input class="form-control"
      [required]="isRequired"
      [id]="id"
      [type]="type"
      (input)="setMaxLengthNumberInput($event);"
      [placeholder]="placeholder"
      [formControl]="control"
      autocomplete="off"
    />
  } @else {
    <input class="form-control"
      [required]="isRequired"
      [id]="id"
      [type]="type"
      [maxlength]="maxLength()"
      [placeholder]="placeholder"
      [formControl]="control"
      autocomplete="off"
      (input)="transformInputToUppercase($event)"
    />
  }
  
  <app-validation-errors *ngIf="control.touched"
    [customErrorMessages]="customErrorMessages"
    [errors]="control.errors"
  >
  </app-validation-errors>
</div>
