<section class="beneficios-section" [ngStyle]="styles" *ngIf="styles">
  <div class="container p-4">
    <h2 class="section-title">{{ title }}</h2>

    <div class="row beneficios-table flex-nowrap">
      <!-- coluna de beneficios -->
      <div class="col-6">
        <div class="row">
          <div class="col-12 header-beneficios text-nowrap sticky-top"></div>
          @for(benefitItem of benefitsList; track benefitItem.benefitName; let idx = $index){
            <div class="col-12 beneficio line" [id]="'benefit-' + idx">{{benefitItem.benefitName}}</div>
          }
        </div>
      </div>
      <!-- coluna planos -->
      @for(benefitPlan of benefitsTableData; track benefitPlan.title; let idx = $index){
      <div class="col">
        <div class="row">
          <div class="col-12 header text-nowrap sticky-top">{{benefitPlan.title}}</div>
          @for(benefitItem of benefitsList; track benefitItem.benefitName; let idx = $index){
          <td class="col-12 line" [style]="setLinestyle(idx)"><i class='bi ' [ngClass]="benefitsCheck(benefitPlan, benefitItem.id)"></i></td>
          }
        </div>
      </div>
    }

    </div>
  </div>
</section>