@if (inputStyle) {
<div class="logo-text" [ngStyle]="inputStyle">
  <img [src]="inputStyle?.url" alt="Logo" class="logo">
  <div class="header-text">
    <p class="line1">
      <span class="prefix" [style]="inputStyle?.prefixTextColor">{{ inputStyle.prefixText }}</span>
      <span class="highlight" [style]="inputStyle?.style">{{ inputStyle.highlightedText }}</span>
    </p>
    <p class="sufix" [style]="inputStyle?.style">{{ inputStyle.suffixText }}</p>
  </div>
</div>
}