import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TermsService } from 'src/app/core/services/terms.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';

@Component({
  selector: 'app-termos-uso',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './termos-uso.component.html',
  styleUrl: './termos-uso.component.scss'
})
export class TermosUsoComponent implements OnInit {

  content: string
  labelColor = 'white';

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  constructor(
    private termsService: TermsService,
  ) { }

  ngOnInit(): void {
    this.getTerms();
  }

  getTerms() {
    this.termsService.getTerms().subscribe((data) => {
      this.content = data[0].content.replace(
        /<p><strong style="background-color: transparent;">Regulamento<\/strong><\/p>/,
        ''
      );
    });
    console.log(this.content, 'content');
  }


  back() {
    window.history.back();
  }


}
