import { ExperienceService } from './../../../core/services/experience.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, input } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CardsResgatar } from '../../models/cardsResgatar';
import { Router, RouterLink } from '@angular/router';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-cards-resgatar',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink],
  templateUrl: './app-cards-resgatar.component.html',
  styleUrl: './app-cards-resgatar.component.scss'
})
export class AppCardsResgatarComponent implements OnInit {
  labelColor: '#FFFFFF';
  data = input.required<any[]>();
  title = input<string>();

  @Input() score: any;
  typeContent = input<string>();
  showItemResgatado = false;

  checkInBtn = {
    buttonText: "Resgatar",
    borderRadius: "25px",
    buttonSize: "btn btn-md py-2 btn-primary",
    fontSize: 14
  };

  buyBtn = {
    buttonText: "Comprar",
    borderRadius: "25px",
    buttonSize: "btn btn-md py-2 btn-secondary",
    fontSize: 14
  };

  constructor(private alertService: AlertService, private router: Router, private experienceService: ExperienceService){}

  resgatarItem() {
    this.showItemResgatado = true;
  }

  goTo(link:string) {
    console.log(link)
    if(link !== '' && link !== null && link !== undefined) {
      window.open(link, '_blank');
    }
  }

  ngOnInit(): void {}

  logdata() {
    console.log(this.data());
  }


  rescueExperience(item : any){
    if(this.score < item.point){
      this.alertService.showAlert(AlertType.WARNING, 'Saldo insuficiente para essa experiência');
    } else {
      let rescuePayload: any = {
        memberId: item.memberId,
        experienceId: item.id,
        points: item.point
      }

      this.experienceService.rescueExperience(rescuePayload).subscribe({
        next: (res:any) => {
          this.router.navigate(['/resgates'], { state: { rescue: item } });
        }
      })

    }
     //routerLink="/resgates"
     console.log(item, this.score, 'aqui')
  }

}
