import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appTruncateText]',
  standalone: true
})
export class TruncateTextDirective implements AfterViewInit {
  @Input('appTruncateText') maxCharacters: number = 100; // Default to 100 characters

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.truncateText();
  }

  private truncateText() {
    const element = this.el.nativeElement;
    const text = element.innerText || element.textContent;

    if (text.length > this.maxCharacters) {
      const truncated = text.substring(0, this.maxCharacters) + '...';
      element.innerText = truncated;
    }
  }
}
