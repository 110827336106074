import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FootballMatchModel } from 'src/app/shared/models/footballMatchModel';
import { Page } from 'src/app/shared/models/Page';

@Injectable({
  providedIn: 'root'
})
export class GameService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

    getAllGamesPublic(): Observable<Page<FootballMatchModel>> {
      return this.get<Page<FootballMatchModel>>(`${this.env.publicApiSocioUrl}v1/socio/game`);
    }

    getAllGames(): Observable<Page<FootballMatchModel>> {
      return this.get<Page<FootballMatchModel>>(`${this.env.socioApiUrl}/game`);
    }

    getNextMatches() {
      return this.get(`${this.env.publicApiSocioUrl}v1/socio/next-matches-sections`);
    }
}
