<div class="conteudo-exclusivo-video">
    <div class="video" [style.background]="'url(' + dataVideo.image.url + ')'" *ngIf="!showVideo"></div>
    <div class="dados" *ngIf="!showVideo" >
        <h1 class="main-title">{{ dataVideo.title }}</h1>
        <h2 class="subtitle">{{ dataVideo.subtitle }}</h2>
        <div class="buttons">
            <app-button
                [isIcon]="true"
                [left]="true"
                [iconName]="'bi bi-play-circle-fill'"
                class="mx-2 my-1"
                id="playBtn"
                [buttonOptions]="playBtn"
                (buttonClick)="setShowVideo()"
            >
            </app-button>
            <app-button 
                [isIcon]="true"
                [left]="true"
                [iconName]="'bi bi-arrow-left'"
                class="mx-2 my-1 mt-5"
                id="backBtn"
                [buttonOptions]="backBtn"
                data-bs-dismiss="modal"
                routerLink="/conteudo-exclusivo"
            >
            </app-button>
        </div>
    </div>
    <div class="player" *ngIf="showVideo">
        <div class="container">
            <div class="back">
                <app-button 
                    [isIcon]="true"
                    [left]="true"
                    [iconName]="'bi bi-arrow-left'"
                    class="mx-2 my-1"
                    id="backBtn"
                    [buttonOptions]="backBtn"
                    data-bs-dismiss="modal"
                    (buttonClick)="setShowVideo()"
                >
                </app-button>
            </div>
            <div class="video-container ">
                <iframe 
                    src="https://www.youtube.com/embed/P9SU8jYOkoA?autoplay=1" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen
                ></iframe>
            </div>
        </div>
        
    </div>
</div>