import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import { Location } from '@angular/common';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [AppButtonComponent, AppInputComponent, ReactiveFormsModule, AppModalComponent],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent {

  InputType = InputType;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Insira abaixo o Email da conta para envio de redefinição de senha.';
  modalId = 'recoverPassModalId';

  recoverPassFormGroup = new FormGroup({
    emailControl: new FormControl<string>('', [Validators.required, Validators.email]),
  });

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  recoverPassBtn = {
    buttonText: 'Enviar e-mail',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };
  
  // TODO: Se o email não existir, exibir mensagem: E-mail inexistente em nossa base.
  modalBodyMessage = 'Um e-mail com as instruções de troca de senha foram enviadas para o seu e-mail';

  constructor(
    private modalService: ModalService, 
    private location: Location, 
    private authService: AuthService
  ) { }

  recoverPassword() {
    if(this.recoverPassFormGroup.invalid) {
      return;
    }
    let email = this.recoverPassFormGroup.get('emailControl')?.value as string;
    
    this.authService.recoverPassword(email).subscribe(
      () => this.openModal(this.modalId)
    );
    // TODO: Fazer request para enviar email -> se existir -> abrir o modal
    // se não existir -> mostrar alert e voltar para login
    // this.openModal(this.modalId);
  }

  openModal(modalId: string) {
    this.modalService.open(modalId);
  }

  onModalConfirm(): void {
    this.location.back();
  }

  back() {
    this.location.back();
  }
}

