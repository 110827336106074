import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { VideoService } from 'src/app/core/services/video.service';
import { SafePipe } from "src/app/shared/pipe/safe.pipe";

@Component({
    selector: 'app-section-video',
    standalone: true,
    templateUrl: './section-video.component.html',
    styleUrl: './section-video.component.scss',
    imports: [CommonModule, SafePipe]
})
export class SectionVideoComponent implements OnInit {
  videoUrl: string;
  styles: { [key: string]: string };

  constructor(
    private videoService: VideoService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getVideoConfig();
  }

  getVideoConfig() {
    this.videoService.getVideoConfig().subscribe((response: any) => {
    let videoResponse = response;
    this.styles = {
      '--show-section': videoResponse.showSection ? 'block' : 'none',
      '--background-color': videoResponse.backgroundColor,
      '--background-image': videoResponse.backgroundIsImage ? `url(${this.getImageUrl(videoResponse)})`: 'none',
    }
    this.videoUrl = videoResponse.videoUrl;
    });
  }


  getImageUrl(video: any) {
    if (video?.defaultStyle) {
      return '/assets/imagens/bg-video.png';
    }
    return this.commonService.getImageStr(video.document.id);
  }


  getVideoId() {
    return this.extractVideoId(this.videoUrl);
  }

  private extractVideoId(url: string): string {
    let videoId = '';

    if (url?.includes('youtube.com')) {
      const splitParts = url?.split('?v=');
      if (splitParts.length > 1) {
        videoId = splitParts[1];
      } else {
        videoId = url?.split('youtu.be/')[1];
      }
    } else if (url?.includes('youtu.be/')) {
      videoId = url?.split('youtu.be/')[1];
    }

    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }    
    return videoId;
  }
}

