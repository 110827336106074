<div class="field-form-control" *ngIf="control">
  <label [for]="id" *ngIf="label" class="form-label" [style.color]="labelColor()" [class.required]="isRequired">
    {{ label }}
  </label>
  <select class="form-select" [formControl]="control" [id]="id">
    <option *ngFor="let option of options" [value]="option.code">
      {{ option.name }}
    </option>
  </select>

  <app-validation-errors *ngIf="control.touched"
    [customErrorMessages]="customErrorMessages"
    [errors]="control.errors"
  >
  </app-validation-errors>
</div>
