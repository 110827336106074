import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent ],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss'
})
export class VideoComponent implements OnInit{

  public dataVideo: any;
  public showVideo = false;

  playBtn = {
    buttonText: 'Reproduzir',
    primaryColor: 'transparent',
    secondaryColor: 'transparent',
    buttonTextColor: '#FFFFFF',
    borderRadius: '0px',
    buttonBorderWidth: '0px',
    buttonBorderColor: 'transparent',
    buttonSize: 'btn btn-sm px-4 py-2'
  };

  backBtn = {
    buttonText: 'Voltar',
    primaryColor: 'transparent',
    secondaryColor: '#E0C252',
    buttonTextColor: '#E0C252',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonBorderColor: '#E0C252',
    buttonSize: 'btn btn-sm px-4 py-2'
  };

  setShowVideo() {
    this.showVideo = !this.showVideo;
  }

  ngOnInit(): void {
    this.dataVideo = {
      title: 'Argentine National Team, Road to Qatar',
      subtitle: 'Argentine NatioArgentina National Football Team is getting ready for the World Cup and opens the door to behind-the-scenes. The qualifiers, the Copa América, the Finalissima and match preparation for Qatar as never seen before.nal Team, Road to Qatar',
      codigoVideo: 'P9SU8jYOkoA',
      image: {
        url: '/assets/imagens/bg-conteudo-exclusivo-video.png',
        title: '',
        alt: '',
      },
    }  
  }
 
}
