import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Sets a value in local storage.
   * @param key The key under which to store the value.
   * @param value The value to store.
   */
  setItem(key: string, value: any): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value); // Stringify complex objects before storing
    }
    localStorage.setItem(key, value);
  }

  /**
   * Gets a value from local storage.
   * @param key The key of the value to retrieve.
   * @returns The retrieved value, or null if not found.
   */
  getItem(key: string): any {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value as string); // Parse JSON if the value is a stringified object
    } catch (error) {
      return value; // Return the raw value if parsing fails (might be a string)
    }
  }

  /**
   * Removes a value from local storage.
   * @param key The key of the value to remove.
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears all data from local storage.
   */
  clear(): void {
    localStorage.clear();
  }
}
