import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { TermsUseModel } from 'src/app/shared/models/termsUseModel';

@Injectable({
  providedIn: 'root'
})
export class TermsService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  getTerms(): Observable<TermsUseModel[]> {
    return this.get<TermsUseModel[]>(`${this.env.publicApiSocioUrl}v1/socio/terms-of-use`);
  }
}
