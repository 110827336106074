<div class="upload-container">
    <div class="form-group">
        <label for="fileInput" [style.color]="labelColor">Escolha um arquivo:</label>
        <input type="file" id="fileInput" (change)="onFileChange($event)" />
    </div>
    <app-button
        class="mx-2 my-1"
        id="sendBtn"
        [buttonOptions]="sendBtn"
        type="submit"
        [disabled]="!selectedFile"
        (buttonClick)="onClick()">
    </app-button>
  </div>