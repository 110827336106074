import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankingService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getRanking() {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/member-ranking-sections`);
  }

  getMemberRanking() : Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-final-scores/get-members-ranking`);
  }


}
