import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PrivacyPolicyService } from 'src/app/core/services/privacy-policy.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent implements OnInit {

  privacyPolicy: string='';

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  constructor(
    private privacyPolicyService: PrivacyPolicyService
  ) { }

  ngOnInit(): void {
    this.getPrivacyPolicy();
  }

  getPrivacyPolicy() {
    this.privacyPolicyService.getPrivacyPolicy().subscribe((response: any) => {
      this.privacyPolicy = response.content[0].description
    });
  }

  back() {
    window.history.back();
  }

}
