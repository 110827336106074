import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { AlertComponent } from './core/components/alert/alert.component';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePtBr from '@angular/common/locales/pt';
import { AuthInterceptor } from './core/auth.interceptor';
import { ErrorInterceptor } from './core/error.interceptor';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieConsentComponent } from './core/components/cookie-consent/cookie-consent.component';

registerLocaleData(localePtBr);

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FooterComponent,
        CookieConsentComponent,
        HeaderComponent,
        AlertComponent,
        SpinnerComponent,
        BrowserAnimationsModule,
        MatPaginatorModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        DatePipe,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class AppModule { }
