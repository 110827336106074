import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { CarouselItem } from '../carousel-plans.model';
import { AppButtonComponent } from '../../app-button/app-button.component';

@Component({
  selector: 'app-carousel-plan-card',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './carousel-plan-card.component.html',
  styleUrl: './carousel-plan-card.component.scss'
})
export class CarouselPlanCardComponent {
  carouselCardData = input<any>();
  chunkCarouselSize = input<number>();
  isMobile = input<boolean>();
  templateVersion = input<number>();
  indexJ = input<number>();
  styles = input<any>();
  carouselAction = output<CarouselItem>();

  buttonAction(plano: CarouselItem): void {
    this.carouselAction.emit(plano);
  }
}
