import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  constructor() {}

  setFavicon(iconUrl: string) {
    const favicon = document.getElementById('appFavicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = iconUrl;
    }
  }
}
