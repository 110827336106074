import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { Location } from '@angular/common';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { CreditCardInfoModel } from 'src/app/shared/models/paymentData';
import { ActivatedRoute } from '@angular/router';
import { CommonService, DateMode } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-adicionar-cartao',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AppInputCustomComponent, AppInputComponent, AppButtonComponent],
  templateUrl: './adicionar-cartao.component.html',
  styleUrl: './adicionar-cartao.component.scss'
})
export class AdicionarCartaoComponent implements OnInit {

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;
  DateMode = DateMode;

  // TODO: Rever estrutura de color
  labelColor = 'white';
  
  title = 'Adicionar cartão';

  addCreditCardformGroup = new FormGroup({
    cardNumberControl: new FormControl<string>('', Validators.required),
    expirationDateControl: new FormControl<string>('', Validators.required),
    cvvControl: new FormControl<string>('', Validators.required),
    cardNameControl: new FormControl<string>('', Validators.required),
    CPFControl: new FormControl<string>('', Validators.required)
  });

  btnSaveOptions = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  btnCancelOptions = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-secondary',
  };

  creditCard: CreditCardInfoModel;
  mode = PageMode.ADD;

  constructor(
    private location: Location,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService) {

    this.activatedRoute.params.subscribe((param: any) => {
      if (param && Object.keys(param).length) {
        this.mode = PageMode.EDIT;
        this.title = 'Editar cartão';
        const creditCardNumber = param.cardNumber;

        this.creditCard = this.getCreditCardByNumber(creditCardNumber);
      }
    });
  }

  ngOnInit(): void {
    if (this.creditCard) {
      this.initForm(this.addCreditCardformGroup, this.creditCard);
    }
  }

  getCreditCardByNumber(number: string): CreditCardInfoModel {
    let creditCard = {} as CreditCardInfoModel;
    const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
    if (creditCardArr?.length && number) {
      creditCard = creditCardArr.find((item) => item.cardNumber === number) as CreditCardInfoModel;
    }
    return creditCard;
  }

  private initForm(formGroup: FormGroup, creditCard: CreditCardInfoModel): void {
    if (creditCard && Object.keys(creditCard).length) {
      const expirationDate = this.commonService.formateDateInput(creditCard.expirationDate, DateMode.MMAA);

      formGroup.get('cardNumberControl')?.setValue(creditCard.cardNumber);
      formGroup.get('expirationDateControl')?.setValue(expirationDate);
      formGroup.get('cvvControl')?.setValue(creditCard.cvv);
      formGroup.get('cardNameControl')?.setValue(creditCard.nameCard);
      formGroup.get('CPFControl')?.setValue(creditCard.cpf);
    }
  }

  save(): void {
    try {
      const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
      const newCard = this.getCardObj();
  
      if (this.mode === PageMode.ADD) {
        this.addNewCard(creditCardArr, newCard);
      } else {
        this.updateCard(creditCardArr, newCard);
      }
  
      this.showAlert(AlertType.SUCCESS, 'Cartão de crédito atualizado com sucesso!');
  
      // Delay the back navigation by 2 seconds
      setTimeout(() => {
        this.location.back();
      }, 2000);
      
    } catch (error) {
      this.showAlert(AlertType.DANGER, 'Ocorreu um erro ao adicionar o cartão de crédito. Por favor, tente novamente.');
    }
  }
  
  private addNewCard(creditCardArr: CreditCardInfoModel[], newCard: CreditCardInfoModel): void {
    if (!creditCardArr) {
      this.localStorageService.setItem('credit-card-info', [newCard]);
    } else {
      const newCardArr = [...creditCardArr, newCard];
      this.localStorageService.setItem('credit-card-info', newCardArr);
    }
  }
  
  private updateCard(creditCardArr: CreditCardInfoModel[], newCard: CreditCardInfoModel): void {
    if (creditCardArr) {
      const updatedCreditCardArr = this.removeCardByNumber(this.creditCard.cardNumber, creditCardArr);
      const newCardArr = [...updatedCreditCardArr, newCard];
      this.localStorageService.setItem('credit-card-info', newCardArr);
    }
  }
  
  removeCardByNumber(cardNumber: string, creditCardArr: CreditCardInfoModel[]): CreditCardInfoModel[] { // Update return type
    const index = creditCardArr.findIndex(item => item.cardNumber === cardNumber);
    if (index > -1) {
      creditCardArr.splice(index, 1); // Get removed item
      return creditCardArr; // Return updated array
    }
    return []; // Return null if no match found (optional)
  }

  showAlert(type: AlertType, message: string): void {
    this.alertService.showAlert(type, message);
  }

  getCardObj(): CreditCardInfoModel {
    const cardNumber = this.addCreditCardformGroup.controls.cardNumberControl.value as string;
    const cardOperator = this.commonService.getCreditCardOperator(cardNumber);
    return {
      cardNumber: cardNumber,
      expirationDate: this.addCreditCardformGroup.controls.expirationDateControl.value as string,
      cpf: this.addCreditCardformGroup.controls.CPFControl.value as string,
      cvv: this.addCreditCardformGroup.controls.cvvControl.value as string ,
      nameCard: this.addCreditCardformGroup.controls.cardNameControl.value as string,
      cardOperator: cardOperator,
      cardIcon: this.commonService.getCardIcon(cardOperator)
    }
  }

  cancel(): void {
    this.location.back();
  }

}

export enum PageMode {
  EDIT = 0,
  ADD = 1,
}
