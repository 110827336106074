import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutConfigService {
  private layoutVersion: number = 1; 

  getLayoutVersion(): number {
    return this.layoutVersion;
  }

  setLayoutVersion(version: number): void {
    this.layoutVersion = version;
  }
}
