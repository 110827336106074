import { Component, OnDestroy, OnInit, Input, input } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';

declare var bootstrap: any;

@Component({
  selector: 'app-membershipcard-modal',
  standalone: true,
  imports:[QRCodeModule],
  templateUrl: './membershipcard-modal.component.html',
  styleUrls: ['./membershipcard-modal.component.scss']
})
export class MembershipCardModalComponent implements OnInit, OnDestroy {

  @Input() backdropType!: string;
  @Input() paymentModalData!: any;

  memberShipCard = input<any>();

  private modalSubscription: Subscription | undefined;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      console.log(state);  // Verifique o que está sendo emitido aqui
      if (state.id === 'membershipCardModal') {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          const modal = new bootstrap.Modal(modalElement, { 
            backdrop: 'static',
            keyboard: false
          });
  
          if (state.action === 'open') {
            modal.show();
          } else if (state.action === 'close') {
            modal.hide();
            // Forçando a remoção do backdrop
            const backdropElement = document.querySelector('.modal-backdrop');
            if (backdropElement) {
              backdropElement.remove();
            }
          }
        } else {
          console.error('Elemento do modal não encontrado!');
        }
      }
    });
  }
  
  
  
  

  onCancel() {
    this.modalService.close('membershipCardModal');
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
