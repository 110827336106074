<div class="modal fade" id="{{modalId()}}" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" [attr.data-bs-backdrop]="backdropType()">
      <div class="modal-dialog {{size()}}" [class.modal-dialog-scrollable]="scrollable()">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">{{modalTitle()}}</h1>
          @if (showCloseBtn()) {
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCancel()"></button>
          }
        </div>
        <div class="modal-body" [innerHTML]="modalBodyMessage()" #modalBody>
          <!-- {{modalBodyMessage()}} -->
        </div>
        <div class="modal-footer">
            @if (showCloseBtn()) {
                <app-button class="mx-2 my-1"
                    id="cancelModalBtn"
                    [buttonOptions]="cancelBtn" data-bs-dismiss="modal" (buttonClick)="onCancel()"></app-button>
            }
            <app-button class="mx-2 my-1"
                id="confirmModalBtn"
                [buttonOptions]="confirmBtn"
                (buttonClick)="onConfirm()"></app-button>
        </div>
      </div>
    </div>
</div>
