import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { UploadService } from 'src/app/core/services/upload.service';
import { UserService } from 'src/app/core/services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { UserModel } from '../../models/userModel';
import { MemberService } from 'src/app/core/services/member.service';
import { MemberPlanDetailModel } from '../../models/planModel';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './app-file-upload.component.html',
  styleUrl: './app-file-upload.component.scss'
})
export class FileUploadComponent implements OnInit {
  @Output() fileSaved = new EventEmitter<boolean>();

  selectedFile: File | null = null;
  labelColor = 'white';
  uuid: string = '';
  base64Data: string = '';
  currentUser: UserModel;
  currentMemberPlanId: string | undefined;
  
  sendBtn = {
    buttonText: 'Enviar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  constructor(
    private location: Location,
    private http: HttpClient,
    private uploadService: UploadService,
    private userService: UserService,
    private memberService: MemberService
  ) {
    this.currentUser = this.userService.currentUserValue ?? {} as UserModel;
   }

   

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }      
  }

  onClick() {
    if (!this.selectedFile) {
      alert('Por favor, selecione um arquivo.');
      return;
    }

    this.generateUUID();
    this.convertToBase64(this.selectedFile);
    console.log(this.currentUser, 'current')
    if(this.currentUser?.memberPlans) {
      const memberId = this.currentUser.id;
      const planId = this.currentMemberPlanId
      if(memberId != null && planId != null) {
        const data = this.buildPayloadDocument(this.selectedFile, memberId, planId, this.base64Data, this.uuid);
        console.log('data', data)
        this.uploadService.upload(data).subscribe({
          next: (response) => {
            console.log('Arquivo enviado com sucesso!', response)
            const input = document.getElementById('fileInput') as HTMLInputElement;
            if (input) {
              input.value = '';
            }
            this.fileSaved.emit(true)
          },
          error: (error) => {
            console.error('Erro ao enviar arquivo', error);
            this.fileSaved.emit(false)
          }
        });
      }
      else {
        alert('Não foi possivel enviar o arquivo. Tente novamente.');
      }
    } 
    else {
      alert('Não foi possivel enviar o arquivo. Tente novamente.');
    }
  }

  private buildPayloadDocument(file: File, memberId: string, planId: string, base64Data: string, uuid: string): any {
    return {
      document: {
        contentType: file.type,
        fileBase64: base64Data,
        fileName: file.name,
        id: uuid
      },
      member: memberId,
      plan: planId
    };
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64Data = (reader.result as string).split(',')[1]; // Remove o prefixo data URL
    }
    reader.readAsDataURL(file); 
  }

  generateUUID() {
    this.uuid = uuidv4(); // Gera um UUID aleatório
  }

  back() {
    this.location.back();
  }

  ngOnInit(): void {
    if (this.currentUser?.id) {
      this.memberService.getCurrentPlan(this.currentUser.id).subscribe({
        next: (res: MemberPlanDetailModel) => {
          this.currentMemberPlanId = res.id;
        },
        error: (err) => {
          console.error('Erro ao buscar o plano atual:', err);
        }
      });
    }
  }
  
}
