<section class="conteudo-exclusivo" [ngStyle]="styles">
  <div class="container">
    <div *ngIf="styles" >
      <i class="bi bi-star-fill custom-icon icon-title"></i>
      <h1 class="title">{{styles["sectionTitle"]}}</h1>
      <p *ngIf="styles['showSubtitle']" class="mb-4 sub-title">{{styles["subtitleText"]}}</p>
      <div id="carouselExclusiveContent" class="container carousel slide">

        <ol class="carousel-indicators" *ngIf="showIndicators">
          <li *ngFor="let chunk of chunkedItems; let i = index" data-bs-target="#carouselExclusiveContent"
            [attr.data-bs-slide-to]="i" [class.active]="i === 0">
          </li>
        </ol>

        <div class="carousel-inner">
          <ng-container *ngFor="let chunk of chunkedItems; let i = index">
            <div class="carousel-item" [class.active]="i === 0">
              <div class="cards-wrapper d-flex flex-nowrap justify-content-space-btween">
                <div class="card col" *ngFor="let item of chunk; let j = index"
                  [ngClass]="{'active': isMobile && j === 0, 'col-4': chunkSize === 3 }">
                  @if (item.url) {
                  <iframe class="item-carousel" [src]="'https://www.youtube.com/embed/' + getVideoId(item.url) | safe"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                  } @else {
                  <img [src]="commonService.getImageStr(item.document.id)" class="item-carousel">
                  }
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <a class="carousel-control-prev" *ngIf="showIndicators" href="#carouselExclusiveContent" role="button"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </a>
        <a class="carousel-control-next" *ngIf="showIndicators" href="#carouselExclusiveContent" role="button"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </a>
      </div>
      <!-- <div class="d-flex align-items-center">
        <app-button [buttonOptions]="buttonPrev" [isIcon]="true" [left]="true" [iconName]="'bi bi-chevron-left'"
          [style]="'--bs-btn-padding-x: 10px;'" (click)="scrollCarousel(-1)" class="custom-button"></app-button>
        <div class="movie-carousel" #movieCarousel>
          <div class="movie-card" *ngFor="let movie of movies" #movieCard [title]="movie.title" (click)="goToPage(movie)">
            @if (movie.url) {
            <iframe class="item-carousel" [src]="'https://www.youtube.com/embed/' + getVideoId(movie.url) | safe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          } @else {
            <img [src]="commonService.getImage(movie.document.id)" class="item-carousel">
          }
          </div>
        </div>
        @if (buttonNext) {
        <app-button [buttonOptions]="buttonNext" [isIcon]="true" [left]="true" [iconName]="'bi bi-chevron-right'"
          [style]="'--bs-btn-padding-x: 10px;'" (click)="scrollCarousel(1)" class="custom-button"></app-button>
        }
      </div> -->
      <!-- <div *ngIf="versao === 2" class="d-flex flex-wrap align-items-center justify-content-center">
        <div class="movie-card-v2 mr-2 mb-2" *ngFor="let movie of movies" #movieCard>
          <img [src]="movie.image" class="img-fluid">
        </div>
      </div> -->
      <!-- @if (buttonNext) {

    } -->
      <div class="pb-5 pt-3 d-flex justify-content-center align-items-center" [class.mt-5]="showIndicators">
        <app-button [style]="'--bs-btn-padding-x: 1.4em;'" [buttonOptions]="deluxePartnerBtn"
          routerLink="/cadastro-socio"></app-button>
      </div>
    </div>
  </div>
</section>
