import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { AppSelectInputComponent, SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { CommonModule } from '@angular/common';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { PartnerData } from 'src/app/shared/models/partnerData';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberService } from 'src/app/core/services/member.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import { TermsService } from 'src/app/core/services/terms.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PrivacyPolicyService } from 'src/app/core/services/privacy-policy.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-cadastro-socio',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AppInputComponent, AppInputCustomComponent, AppSelectInputComponent, AppButtonComponent, AppCheckboxComponent, AppModalComponent],
  templateUrl: './cadastro-socio.component.html',
  styleUrl: './cadastro-socio.component.scss',
})
export class CadastroSocioComponent implements OnInit, OnDestroy {

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  // TODO: Rever estrutura de color
  labelColor = localStorage.getItem('primaryColor') || '#fff';

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  isLogged = localStorage.getItem('currentUser') ? true : false;

  private _destroy$ = new Subject<void>();

  memberRegistrationGroup = new FormGroup({
    fullnameControl: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ]{3,}( {1,2}[a-zA-ZÀ-ÿ]{2,}){1,}$')]),
    cpfControl: new FormControl<string>('', Validators.required),
    birthDate: new FormControl<string>('', [Validators.required, Validators.maxLength(10)]),
    genderControl: new FormControl<string>('', Validators.required),
    emailControl: new FormControl<string>('', [Validators.required, Validators.email]),
    phoneControl: new FormControl<string>('', [Validators.required, Validators.pattern('^[1-9]{2}[9][0-9]{4}[0-9]{4}$')]),
    passwordControl: new FormControl<string>('', Validators.required),
    confirmPasswordControl: new FormControl<string>('', [Validators.required, this.passwordMatchValidator.bind(this)]),
    termsConfirmationInputId: new FormControl<boolean>(false, Validators.requiredTrue)
  });

  checkboxLabel = ``;

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  buttonOptions = {
    buttonText: 'Cadastrar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  protected genderOptions = [
    { code: 'M', name: 'Masculino' },
    { code: 'F', name: 'Feminino' },
    { code: 'NI', name: 'Não Informar' }
  ] as SelectModel[];

  pageTitle = 'Informe os dados para iniciar seu cadastro';
  customErrorMessages: Record<string, string> = {};
  termsAndPoliciesModalId = 'termsAndPoliciesModal';
  termsAndPoliciesModalBodyMessage = '';
  termsAndPoliciesModalTitle = '';
  scrollable = true;

  memberId: string | null = null;
  social: string | null = null;
  indicationId: string | null = null;

  constructor(
    private router: Router,
    private memberService: MemberService,
    private authService: AuthService,
    private location: Location,
    private modalService: ModalService,
    private termsService: TermsService,
    private privacyPolicyService: PrivacyPolicyService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {
    this.authService.isLoggedIn.subscribe(status => {
     if(status){
      this.router.navigate(['/dashboard']);
     }
     window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    this.memberRegistrationGroup.get('passwordControl')?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.memberRegistrationGroup.get('confirmPasswordControl')?.updateValueAndValidity();
      });

      this.route.queryParams.subscribe(params => {
        this.memberId = params['memberId'];
        this.social = params['social'];
        this.indicationId = params['indicationId'];
      });

  }

  get controls(): any{
    return this.memberRegistrationGroup.controls;
  }

  private passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const parent = control.parent;
    if (!parent) return null;

    const password = parent?.get('passwordControl')?.value;
    const confirmPassword = parent?.get('confirmPasswordControl')?.value;

    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

  back() {
    this.location.back();
  }

  onSubmit(): void {
    if (this.memberRegistrationGroup.valid) {
      // Extrair o parâmetro indicatorUser da URL
      const indicatorUser = this.route.snapshot.queryParamMap.get('indicatorUser');
      // Handle form submission
      const partnerData = this.buildPayloadPartnerData(this.memberRegistrationGroup);
      if (indicatorUser) {
        partnerData.referrerMember = {
          id: indicatorUser
        };
      }
      this.postMember(partnerData);
    } else {
      // Handle form errors
      console.log('Form is invalid');
    }
  }

  validateCpfNumber(cpf: string) {
    // console.log(this.commonService.validateCpfNumber(cpf));
    // this.controls.cpfControl.setErrors({ invalidCpf: true });
    // this.customErrorMessages = { invalidCpf: 'CPF inválido' };
    // return this.commonService.validateCpfNumber(cpf);
  }

  validateDateBirth() {
  // criar validação de data
  //  console.log('validateDateBirth', this.commonService.validateDate(this.controls.dateBirthControl.value));

    // console.log(this.commonService.validateDateBirth(dateBirth));
    // this.controls.dateBirthControl.setErrors({ invalidDate: true });
    // this.customErrorMessages = { invalidDate: 'Data inválida' };
    // return this.commonService.validateDateBirth(dateBirth);
    }


  postMember(partnerData: any){
    this.memberService.postMember(partnerData).subscribe(
      (response) => {
        this.memberService.setMemberId(response.id);
        if (response.id) {

          if(this.memberId && this.social){
            let invitePayload: any = {
              member : this?.memberId,
              memberGuest: response.id,
              socialNetwork: this?.social,
              link: window.location.href.toString()
            }
            this.memberService.createMemberInviteLink(invitePayload).subscribe(res => {

            })
          }
          if(this.indicationId){
            let invitePayload: any = {
              id : this?.indicationId,
              memberGuestId: response.id,
              emailRegistered: partnerData.email
            }
            this.memberService.createMemberInviteEmail(invitePayload).subscribe(res => {

            })
          }
          this.router.navigate([`/completar-dados`]);
          this.alertService.showAlert(AlertType.SUCCESS, 'Atenção: Um e-mail de confirmação foi enviado. Por favor faça a validação do link na sua caixa de entrada para ter acesso aos seus benefícios. O e-mail pode estar no spam (lixeira).');
        }
      }
    );
  }

  private buildPayloadPartnerData(formGroup: FormGroup): PartnerData {
    return {
      birthDate: formGroup.get('birthDate')?.value.split('/').reverse().join('-'),
      cpf: formGroup.get('cpfControl')?.value,
      email: formGroup.get('emailControl')?.value.toLowerCase(), // Converte o email para minúsculas
      gender: formGroup.get('genderControl')?.value,
      memberSituation: {
        id: PartnerDataEnum.memberSituation
      },
      name: formGroup.get('fullnameControl')?.value,
      originMember: {
        id: PartnerDataEnum.originMember
      },
      phone: formGroup.get('phoneControl')?.value,
      registrationReason: {
        id: PartnerDataEnum.registrationReason
      },
      password: formGroup.get('passwordControl')?.value,
    };
  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }

  showTermsAndPolicies(event: Event, type: string) {
    event.preventDefault();
    this.scrollable = true;
    if(type === 'terms'){
      this.getTerms();
    }else{
      this.getPrivacyPolicy();
    }
    setTimeout(() => {
      this.modalService.open(this.termsAndPoliciesModalId);
    }, 100);

  }

  getTerms() {
    this.termsService.getTerms().subscribe((data) => {
      this.termsAndPoliciesModalTitle = 'Termos de Uso';
      this.termsAndPoliciesModalBodyMessage =  data[0].content;
    });
  }

  getPrivacyPolicy() {
    this.privacyPolicyService.getPrivacyPolicy().subscribe((response: any) => {
      this.termsAndPoliciesModalTitle = 'Política de Privacidade';
      this.termsAndPoliciesModalBodyMessage =  response.content[0].description;
    });
  }

}

enum PartnerDataEnum {
  memberSituation= '248d6551-0816-4c5f-8ec7-e4382546e832',
  originMember= '0aeb171a-1dae-4847-9dcf-23524c32ec49',
  registrationReason= 'fb10cca0-01e5-45f6-b48e-f60ebeb5ac79'
}
