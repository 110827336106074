import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { Plan } from '../../models/planModel';

@Component({
  selector: 'app-card-plan-items',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-plan-items.component.html',
  styleUrl: './card-plan-items.component.scss'
})
export class CardPlanItemsComponent {
  planItem = input.required<any>();
  
}
