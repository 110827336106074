<div class="card">
    <div class="m-1 mb-2 fw-medium fs-6">
        {{ planItem().planName }}
    </div>
    <ul class="card-list fs-small">
        <li class="py-1" *ngFor="let enableItem of planItem().enableItems">
            <i class="bi bi-check-circle-fill m-1"></i>{{ enableItem }}
        </li>
        <li class="disable py-1" *ngFor="let disableItem of planItem().disableItems">
            <i class="bi bi-x me-1"></i>{{ disableItem }}
        </li>
    </ul>
</div>
