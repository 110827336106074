import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { StyleContentExclusive } from 'src/app/shared/models/styleContent';
import { Page } from 'src/app/shared/models/Page';
import { ExclusiveContentModel } from 'src/app/shared/models/conteudoExclusivoModel';

@Injectable({
  providedIn: 'root'
})
export class ExclusiveContentService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

  public getExclusiveContent(): Observable<Page<ExclusiveContentModel>> {
    return this.get<Page<ExclusiveContentModel>>(`${this.env.publicApiSocioUrl}v1/socio/exclusive-contents`);
  }

  getExclusiveContentSection(): Observable<StyleContentExclusive> {
    return this.get<StyleContentExclusive>(`${this.env.publicApiSocioUrl}v1/socio/exclusive-content-sections`);
  }

  getExclusiveContentById(id:string): Observable<StyleContentExclusive> {
    return this.get<StyleContentExclusive>(`${this.env.socioApiUrl}/exclusive-contents/${id}`);
  }
 

}
