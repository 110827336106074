const urlS3 = '__S3__';
const authApiUrl = `/api/v1/auth`;
const socioApiUrl = `/api/v1/socio`;
const publicApiAuthUrl = `/public/api/`;
const publicApiSocioUrl = `/public/api/`;
const publicApiAdminUrl = `/public/api/`;



export const environment = {
    production: true,
    xTenantId: '__TENANT_ID__',
    authApiUrl: authApiUrl,
    socioApiUrl: socioApiUrl,
    publicApiSocioUrl: publicApiSocioUrl,
    publicApiAdminUrl: publicApiAdminUrl,
    publicApiAuthUrl: publicApiAuthUrl,
    urlS3: urlS3
};

