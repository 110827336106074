import { Component, input, OnDestroy, OnInit, output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Notification } from 'src/app/shared/models/notificationsModel';

declare var bootstrap: any;

@Component({
  selector: 'app-modal-notifications',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './modal-notifications.component.html',
  styleUrl: './modal-notifications.component.scss'
})
export class ModalNotificationsComponent implements OnInit, OnDestroy {

  modalId = input.required<string>();
  notificationModalData = input.required<Notification>();
  confirmAction = output();

  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Fechar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === this.modalId()) {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            const modal = new bootstrap.Modal(modalElement);
            modal.show();
          } else if (state.action === 'close') {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              modal.hide();
            }
          }
        }
      }
    });
  }

  onConfirm() {
    this.confirmAction.emit();
    this.modalService.close(this.modalId());
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

}
