<section class="section-faq " [ngStyle]="styles" *ngIf="styles">
    <div class="p-4 container faq-card">
        <div class="d-flex flex-column flex-md-row justify-content-between">
            <div class="col pb-4">
                <p class="h2 title">FAQ</p>
                <p class="h6 pb-1 subtitle">Perguntas frequentes</p>
                <p class="h4 description">Tire todas as suas dúvidas na nossa página do FAQ ou entre em contato conosco</p>
            </div>
            <div class="col col-md-3 justify-content-md-end justify-content-center d-flex align-items-center">
                <app-button [style]="'--bs-btn-padding-x: 3em;'" routerLink="/faq"  [buttonOptions]="buttonOptions"></app-button>
            </div>
        </div>
    </div>
</section>
