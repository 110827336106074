<div class="itemResgatado">
    <h1 class="mb-5">Você resgatou a experiência <span>{{rescueData.title}}</span></h1>
    <img class="my-5" [src]="rescueData.image" alt="">
    <p class="mt-5">Será enviado no seu email todos os detalhes sobre essa experiência</p>
    <app-button
        [isIcon]="true"
        [left]="true"
        [iconName]="'bi bi-arrow-left'"
        class="mx-2 my-1 pt-5"
        id="backBtn"
        [buttonOptions]="backBtn"
        (buttonClick)="goBack()"
        routerLink="previousUrl">
    </app-button>
</div>
