import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base/base.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { StyleContentFaq } from 'src/app/shared/models/styleContent';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getAllQuestions() {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/faq?pageSize=999`);
  }

  getFaqSection(): Observable<StyleContentFaq> {
    return this.get<StyleContentFaq>(`${this.env.publicApiSocioUrl}v1/socio/faq-config`);
  }

}
