import { Component, input } from '@angular/core';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-input-copy-to-clipboard',
  standalone: true,
  imports: [],
  templateUrl: './input-copy-to-clipboard.component.html',
  styleUrl: './input-copy-to-clipboard.component.scss'
})
export class InputCopyToClipboardComponent {

  id = input('input-id');
  label = input('Label');
  inputValue = input.required<string>();

  constructor(public alertService: AlertService) { }

  copyToClipboard() {
    const alertServ = this.alertService;
    navigator.clipboard.writeText(this.inputValue()).then(function() {
      alertServ.showAlert(AlertType.SUCCESS, 'Texto copiado!');
    }).catch(function(error) {
      console.error('Error copying text: ', error);
    });
  }
}
