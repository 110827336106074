import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { PlanPaymentUpgradeModel, PlanUpgradeModel } from 'src/app/shared/models/planPaymentsModel';

@Injectable({
  providedIn: 'root'
})
export class PlanPaymentsService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

  getPlanPaymentsUpgrades(memberPlanId: string ): Observable<PlanPaymentUpgradeModel[]> {
    return this.get<PlanPaymentUpgradeModel[]>(`${this.env.socioApiUrl}/plan-payments/upgrades/${memberPlanId}?isPortal=true`);
  }

  getPlanPaymentsRenewals(planId: string ): Observable<PlanPaymentUpgradeModel[]> {
    return this.get<PlanPaymentUpgradeModel[]>(`${this.env.socioApiUrl}/plan-payments/renewals/${planId}?isPortal=true`);
  }

  getListPlanUpgrades(planId: string): Observable<PlanUpgradeModel[]> {
    return this.get<PlanUpgradeModel[]>(`${this.env.socioApiUrl}/plan-upgrades/list-upgrades/${planId}`);
  }
}
