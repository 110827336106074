import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      setTimeout(() => {
        const enableLoggedArea = localStorage.getItem('enableLoggedArea');
        const enableNewMembership = localStorage.getItem('enableNewMembership');
        const enablePortal = localStorage.getItem('enablePortal');
        const messageLoggedArea = localStorage.getItem('messageLoggedArea');
        const messageNewMembership = localStorage.getItem('messageNewMembership');
        const messagePortal = localStorage.getItem('messagePortal');
      
        let customGuardResponse = true;

        if (enablePortal === 'false') {
          this.router.navigate([Routes.NotFoundPage, { message: messagePortal }]);
          resolve(false);
          return;
        }

        switch (route.routeConfig?.path) {
          case Routes.Login:
          case Routes.ChangePassword:
            customGuardResponse = enableLoggedArea === 'true';
            if (!customGuardResponse) this.router.navigate([Routes.NotFoundPage, { message: messageLoggedArea }]);
            break;
          case Routes.Register:
          case Routes.CompleteData:
          case Routes.FinishPayment:
          case Routes.Welcome:
            customGuardResponse = enableNewMembership === 'true';
            if (!customGuardResponse) this.router.navigate([Routes.NotFoundPage, { message: messageNewMembership }]);
            break;
          default:
            break;
        }

        resolve(customGuardResponse);
      }, 200)
    });
  }
}

enum Routes {
  Home = '',
  Login = 'login',
  Register = 'cadastro-socio',
  CompleteData = 'completar-dados',
  FinishPayment = 'finalizar-pagamento/:operation',
  Welcome = 'finalizar-pagamento/:operation/seja-bem-vindo',
  ChangePassword = 'alterar-senha',
  NotFoundPage = '/not-found-page',
}