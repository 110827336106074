import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Notification } from 'src/app/shared/models/notificationsModel';
import { SupportRouterService } from 'src/app/shared/services/support-router.service';


@Component({
  selector: 'notification-dropdown',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './notification-dropdown.component.html',
  styleUrl: './notification-dropdown.component.scss'
})
export class NotificationDropdownComponent {
  styles = input<any>();

  notifications = input.required<Notification[]>();
  markAllNotificationsAsRead = output();

  maxNotifications = 3;

  showAllNotifications = computed<boolean>(() => {
    if (this.notifications()?.length > this.maxNotifications) {
      return true;
    }
    return false;
  });

  notificationsBadge = computed<number>(() => {
    if (!this.notifications()?.length) {
      return 0;
    }

    return this.notifications().filter(notification => notification.isViewed === false).length;
  });

  constructor(private router: Router, private supportRouterService:SupportRouterService) { }

  markAllAsRead() {
    this.markAllNotificationsAsRead.emit();
  }

  viewNotification(notification: Notification) {
    if(!this.router.url.includes('/lista-notificacoes')) {
      this.supportRouterService.setRota(this.router.url)
    }
    this.router.navigate([`/lista-notificacoes/${notification.id}`]);
  }

  viewAllNotifications() {
    // Go to notifications page
    this.router.navigate(['/lista-notificacoes']);
  }

}
