<div class="container p-4 p-md-5 recover-pass-screen">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="recoverpass-main-title px-5 my-4 text-center">
        {{pageTitle}}
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <form [formGroup]="recoverPassFormGroup">
                <app-input class="col-md-6" [id]="'emailInputId'" [label]="'Email'" [type]="InputType.EMAIL"
                    [placeholder]="'Insira seu email'" formControlName="emailControl">
                </app-input>
            </form>
            <div class="text-center my-5" id="btn-recover-pass">
                <app-button class="mx-2 my-1" [disabled]="!recoverPassFormGroup.valid" [buttonOptions]="recoverPassBtn"
                    (buttonClick)="recoverPassword()"></app-button>
            </div>
        </div>
    </div>
</div>

<app-modal [modalTitle]="'Redefinir senha'" [modalId]="modalId" [modalBodyMessage]="modalBodyMessage"
    [showCloseBtn]="false" (confirmActionChange)="onModalConfirm()"></app-modal>