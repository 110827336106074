import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';

@Component({
  selector: 'app-save-new-password',
  standalone: true,
  imports: [CommonModule, AppInputComponent, ReactiveFormsModule, AppButtonComponent, RouterLink],
  templateUrl: './save-new-password.component.html',
  styleUrl: './save-new-password.component.scss'
})
export class SaveNewPasswordComponent implements OnInit {
  InputTypeEnum = InputType;
  private _destroy$ = new Subject<void>();

  formGroup = new FormGroup({
    newPass: new FormControl<string>('', Validators.required),
    repeatNewPass: new FormControl<string>('', [Validators.required, this.passwordMatchValidator.bind(this)]),
  });

  btnSaveOptions = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };
  token: string;
  showForm = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.activatedRoute.url.subscribe(res => {
      if (res[1].path == 'recover') {
        this.showForm = true;
      }
    });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.token = params['token'];
      if (this.token) {

        this.validateToken();
      }
    });
  }

  ngOnInit(): void {
    this.formGroup.get('newPass')?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.formGroup.get('repeatNewPass')?.updateValueAndValidity();
      });
  }

  get controls() {
    return this.formGroup.controls;
  }


  validateToken(): void {
    if(this.showForm) return;
    this.authService.validateToken(this.token).subscribe({
      next: response => {
        this.alertService.showAlert(AlertType.SUCCESS, 'Conta ativada com sucesso');
        this.router.navigate(['/login']);
      },
      error: error => {
        this.alertService.showAlert(AlertType.DANGER, 'Token inválido');
        this.router.navigate(['/login']);
      }
    }
    );
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const parent = control.parent;
    if (!parent) return null;

    const password = parent?.get('newPass')?.value;
    const confirmPassword = parent?.get('repeatNewPass')?.value;

    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }


  onSubmit(): void {
    if (this.formGroup.valid) {
      const body = {
        newPassword: this.formGroup.get('newPass')?.value,
        passwordConfirmation: this.formGroup.get('repeatNewPass')?.value,
      };
  
      this.authService.saveNewPassword(body, this.token).subscribe({
        next: () => {
          this.alertService.showAlert(AlertType.SUCCESS, 'Nova senha criada com sucesso!');
          this.router.navigate(['/login']);
        },
        error: (error) => {
          // Tratamento da mensagem de erro 
          let customMessage = 'Erro ao criar nova senha. Verifique os requisitos.';
          if (error && error.error && typeof error.error === 'string') {
            customMessage = 'Erro ao criar nova senha, favor verificar os requisitos.';
          }
          this.alertService.showAlert(AlertType.DANGER, customMessage);
        }
      });
    }
  }
  


}
