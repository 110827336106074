import { CommonModule } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import { FileService } from 'src/app/core/services/file.service';
import { RankingService } from 'src/app/core/services/ranking.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { RankingTableComponent } from 'src/app/shared/components/ranking-table/ranking-table.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

interface Row {
  ranking: number;
  photo: string;
  socio: string;
  cidade: string;
  pontos: number;
  trend?: 'up' | 'down';
}

@Component({
  selector: 'app-section-ranking',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RankingTableComponent
  ],
  templateUrl: './section-ranking.component.html',
  styleUrl: './section-ranking.component.scss'
})
export class SectionRankingComponent implements OnInit {

  public classes: any;
  public styles: { [key: string]: any };
  public buttonOptions: any;
  public ranking: any;
  public tableConfig: any;

  constructor(private layoutConfigService: LayoutConfigService,
    private rankingService: RankingService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.styles = {
      '--border-radius': layoutVersion === 1 ? '15px' : '0px'
    };
    this.classes = {
      'align-items-center': layoutVersion === 1,
      'align-items-start': layoutVersion === 2
    };

    this.buttonOptions = {
      primaryColor: '#5F83BF',
      secondaryColor: '#191844',
      buttonText: 'Ver Ranking Completo',
      buttonTextColor: '#FFFFFF',
      buttonBorderWidth: 'none',
      buttonBorderColor: 'none',
      buttonSize: 'btn btn-lg',
      borderRadius: layoutVersion === 1 ? '25px' : '0px'
    };

    this.getRanking();
  }


  getRanking(){
    this.rankingService.getRanking().subscribe((ranking: any) => {
      this.ranking = ranking;
      this.setTableConfig()
    });
  }

  setTableConfig() {
    if(!this.ranking) return;
    this.tableConfig = {
      caretUp: `color: ${this.ranking?.upTrendIconColor}`,
      caretDown: `color: ${this.ranking?.downTrendIconColor}`,
      thead: `background-color: ${this.ranking?.tableHeaderBackgroundColor}; color: ${this.ranking?.tableHeaderTextColor}`,
      titleStyle: `font-size: ${this.ranking?.titleFontSize}px; color: ${this.ranking?.titleColor}`,
      subtitleStyle: `font-size: ${this.ranking?.subtitleFontSize}px; color: ${this.ranking?.subtitleColor}`,
      primaryRowBackgroundColor: `background-color: ${this.ranking?.primaryRowBackgroundColor}; color: ${this.ranking?.tableTextColor}`,
      secondaryRowBackgroundColor: `background-color: ${this.ranking?.secondaryRowBackgroundColor}; color: ${this.ranking?.tableTextColor}`,
      background: this.ranking?.backgroundType == 'COLOR' ? `background-color: ${this.ranking?.backgroundColor}` : `background-image: url(${this.getImage(this.ranking?.document.id)})`,
    }
  }

  getImage(imgFile: any) {
    return this.fileService.getDocDownloadUrl(imgFile as string);
  }

}
