<div class="container p-4 p-md-5 recover-pass-screen">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="resend-email-main-title px-5 my-4 text-center">
        {{pageTitle}}
    </div>
    <div class="row justify-content-center">
        <div class="card">
            @if (!emailSended){
                <div class="card-body m-4 p-4 text-center">
                    <p><b>Atenção</b>: Por favor, faça a validação na sua caixa de entrada para ter acesso aos seus benefícios. O e-mail pode estar no spam(lixeira).</p>
                    <br>
                    <p class="mt-2">E-mail enviado para: <b>{{dataMember.mail}}</b></p>
                    <br>
                    <p class="mt-2">Caso não tenha recebido, clique no botão abaixo para reenviarmos o código.</p>
                    <br>
                    <app-button class="mx-2 mt-2 my-1" [buttonOptions]="buttonOptionsSendEmail"
                        (buttonClick)="resendEmail()"></app-button>
                </div>
            } @else {
                <div class="card-body m-4 p-4 text-center" >
                    <p>O e-mail com o link de validação foi enviado para o seu e-mail. Favor, verificar.</p>
                    <br>
                    <app-button class="mx-2 my-1" [buttonOptions]="buttonOptions"
                        (buttonClick)="back()"></app-button>
                </div>
            } 
        </div>
    </div>
</div>
