<div class="d-grid p-4 m-1 ticket-card">
    <div *ngIf="ticket()" class="body">
        <div class="ticket-info">{{ ticket().waveGame.gameDate | date:'dd/MM/yyyy' : '+0000' }} às {{ convertTimeToDate(ticket().waveGame.gameTime) | date:'HH:mm'}}</div>
        <div class="description">{{ ticket().waveGame.championship.title }} | {{ ticket().waveGame.location.name }}</div>
            <div class="match">
              @if(ticket().waveGame.mandatorSoccerTeam){
                <div class="team">
                  <img alt="Team match 1" [src]="teamLogo"/>
                  <div class="name">{{teamName}}</div>
                </div>
              } @else {
                <div class="team">
                  <img alt="Team match 1" [src]="adversaryLogo"/>
                  <div class="name">{{ ticket().waveGame.adversary.name }}</div>
                </div>
              }
                <div class="versus">X</div>
                @if(!ticket().waveGame.mandatorSoccerTeam){
                  <div class="team">
                    <img alt="Team match 1" [src]="teamLogo"/>
                    <div class="name">{{teamName}}</div>
                  </div>
                } @else {
                  <div class="team">
                    <img alt="Team match 1" [src]="adversaryLogo"/>
                    <div class="name">{{ ticket().waveGame.adversary.name }}</div>
                </div>
                }
            </div>
    </div>
    @if (hasCheckinBtn()) {
        <app-button id="checkInlBtn"
            class="mx-2 mt-4 btn-ckeckin"
            [buttonOptions]="checkInBtn"
            (buttonClick)="checkIn()">
        </app-button>
    }
    <!-- @if (hasTicketDetails() && ticket().bilhete) {
        <div class="d-grid gap-1 mt-4 mx-2 ticket-details">
            <span class="title">
                Bilhete
            </span>
            <span class="info">
                SÓCIO ({{ticket().bilhete.socio}})
            </span>
            <span class="info">
                ARQUIBANCADA ({{ticket().bilhete.arquibancada}})
            </span>
        </div>
    } -->
</div>
