import { Component, OnChanges, OnInit, SimpleChanges, input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ValidationErrorsComponent, MatIconModule],
  templateUrl: './app-toggle-button.component.html',
  styleUrl: './app-toggle-button.component.scss'
})
export class AppToggleButtonComponent implements OnChanges, OnInit {
  id = input('toggleBtnId');
  label = input('Label');
  color = input('#FFFFFF');
  formControlValue = input.required<FormControl>();
  toggleButtonOptions = input.required<BtnToggleOptions[]>();

  customErrorMessages: Record<string, string> = {};

  // TODO: Rever estrutura de color
  labelColor = 'white';
  public styles: { [key: string]: any };
  isRequired: boolean;

  ngOnInit() {
    this.isRequired = this.formControlValue()?.hasValidator(Validators.required) ?? false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let primaryButton = JSON.parse(localStorage.getItem('buttonPrimary') as string);
    
    let secondaryButton = JSON.parse(localStorage.getItem('buttonSecondary') as string);
    if (changes['color']) {
      this.styles = {
        '--bs-btn-active-bg': this.color(),
        '--bs-body-color': this.color(),
        '--primary-color-button': primaryButton?.color,
        '--primary-text-color-button': primaryButton?.textColor,
        '--primary-color-border-button': primaryButton?.hasBorder ?  primaryButton?.borderColor : 'transparent',
        '--secondary-color-button': secondaryButton?.color,
        '--secondary-text-color-button': secondaryButton?.textColor,
        '--secondary-color-border-button': secondaryButton?.hasBorder ? secondaryButton?.borderColor : 'transparent',
      }      
    }
  }

  selectItem(itemSelected: BtnToggleOptions) {
    this.formControlValue().setValue(itemSelected.value);
  }
  
}

export interface BtnToggleOptions {
  label: string;
  value: string;
  icon?: string;
  iconComponent?: 'mat-icon';
  additionalInfo?: string;
}
