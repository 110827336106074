import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LogoTextComponent } from '../logo-text/logo-text.component';
import { FooterService } from "../../services/footer.service";
import { CommonService } from "../../services/common.service";
import { UserModel } from "../../../shared/models/userModel";
import { UserService } from "../../services/user.service";
import { FileService } from '../../services/file.service';
import { CNPJPipe } from 'src/app/shared/pipe/cnpj.pipe';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, LogoTextComponent, MatIconModule, MatButtonModule, CNPJPipe, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  user: UserModel;

  showLogo: boolean;
  logoSide: string;
  logoPath: string;
  phoneNumbers: any[];
  emails: any[];
  showCNPJ: boolean;
  cnpj: string;
  address: string;
  showRegulation: boolean;
  privacyPolicy: boolean;
  showSocialNetwork: boolean;
  showWhatsappButton: boolean;
  backgroundType: string;
  backgroundImageUrl: string;
  backgroundColor: string;
  showSection: boolean;
  imageLogo: any;
  imageBackground: any;
  finalSentence: string;
  clubSocialNetwork: any[];
  clubData = JSON.parse(localStorage.getItem('socialNetworks') as string);

  styles: { [key: string]: string };

  constructor(
    private footerService: FooterService,
    private commonService: CommonService,
    private userService: UserService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.currentUserValue as UserModel;
    this.footerService.getFooter().subscribe({
      next: (res: any) => {
         
        let footer = res.content[0]
        this.showLogo = footer.showLogo;
        this.logoSide = footer.logoSide;
        this.logoPath = footer.logoPath;
        this.phoneNumbers = footer.phoneNumbers.map((phone: any) => this.commonService.getFormattedPhone(phone.phoneNumber));
        this.emails = footer.emails;
        this.showCNPJ = footer.showCNPJ;
        this.cnpj = footer.cnpj;
        this.address = footer.footerAddress;
        this.showRegulation = footer.showRegulation;
        this.privacyPolicy = footer.privacyPolicy;
        this.showSocialNetwork = footer.showSocialNetwork;
        this.showWhatsappButton = footer.showWhatsappButton;
        this.backgroundType = footer.backgroundType;
        this.backgroundImageUrl = footer.backgroundImageUrl;
        this.backgroundColor = footer.backgroundColor;
        this.showSection = footer.showSection;
        this.imageLogo = this.fileService.getDocDownloadUrl(footer.imageLogo.id as string) || '';
        this.finalSentence = footer.footerFinalSentence
        this.imageBackground = footer.imageBackground;
        this.styles = {
        '--background-color': footer.backgroundColor,
          '--background-image': footer.backgroundType == "Imagem" ? `url(${this.commonService.getImageStr(footer.imageBackground?.id)})` : 'none',
          '--icon-color': localStorage.getItem('secondaryColor') || '#000000',
          '--title-color': footer.titleColor,
          '--subtitle-Color': footer.subtitleColor,
        }
      }
    });

    this.clubSocialNetwork = this.clubData?.map((network: any) => ({
      class: `bi bi-${network.name.toLowerCase()}`,
      href: network.url
    }));
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
