<app-spinner *ngIf="isLoading$ | async"></app-spinner>

<app-header class="z-index11" [isLoggedIn]="isLoggedIn"></app-header>
<app-alert class="d-grid"></app-alert>
<main class="content" [ngStyle]="styles" *ngIf="styles">
  <div class="container main-container">
    <app-cookie-consent></app-cookie-consent>
    <router-outlet></router-outlet>
  </div>
</main>

<app-footer></app-footer>
