import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, finalize, throwError } from "rxjs";
import { AuthService } from "./services/auth.service";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "./services/local-storage.service";
import { SpinnerService } from "./services/spinner.service";
import { NO_AUTH } from "./no-auth.inteceptor";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private env = environment;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private spinnerService: SpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.get('showLoader') != 'false') {
      this.spinnerService.show();
    }


    if (request.context.get(NO_AUTH)) {
      this.spinnerService.hide();
      return next.handle(request);
    }

    const accessToken = this.localStorageService.getItem('token');
    let headers = new HttpHeaders();

   if(!request.url.includes('viacep')) {
      headers = headers.set('X-Tenant-ID', this.env.xTenantId);
    }
    if (accessToken) {
      if (this.authService.isTokenExpired(accessToken)) {
        this.authService.logout();
        return throwError(() => new Error('Token expired'));
      }
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    request = request.clone({ headers });

    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.authService.logout();
          location.reload();
        }
        this.spinnerService.hide();
        return throwError(() => err);
      }),
      finalize(() => {
        this.spinnerService.hide();
      })
    );
  }
}
