import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { FileUploadComponent } from 'src/app/shared/components/app-file-upload/app-file-upload.component';
import { Location } from '@angular/common';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';


@Component({
  selector: 'app-documentos',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, FileUploadComponent],
  templateUrl: './documentos.component.html',
  styleUrl: './documentos.component.scss'
})
export class DocumentosComponent{
  labelColor = 'white';
  currentUser: UserModel;
  accessToken: string;
  listDocuments: any = [];

  constructor(
    private location: Location,
    private memberService: MemberService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService
  ) { 
    this.currentUser = this.userService.currentUserValue ?? {} as UserModel;
    this.accessToken = this.localStorageService.getItem('token');
  }
  
  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  ngOnInit(): void {
    if(this.currentUser?.memberPlans) {
      const memberId = this.currentUser.id;
      this.memberService.getDocumentsMember(memberId, this.accessToken).subscribe((document) => {
        this.listDocuments = document;
      });
    }
  }

  showAlert(type: AlertType, message: string): void {
    this.alertService.showAlert(type, message);
  }

  getDocumentsMember(fileSaved: boolean) {
    if(fileSaved) {
      this.showAlert(AlertType.SUCCESS, 'Documento enviado com sucesso!');
      this.memberService.getDocumentsMember(this.currentUser.id, this.accessToken).subscribe((document) => {
        this.listDocuments = document;
      });
    }
  }

  canUpload():boolean{
    return this.listDocuments.length === 0 || !(this.haveApprovedOrAwaitingReviewStatus())
  }

  haveApprovedOrAwaitingReviewStatus():boolean{
    return this.listDocuments.some((item:any) => item.status === "APPROVED" || item.status === "AWAITING_REVIEW");
  }
  
  back() {
    this.location.back();
  }

}
