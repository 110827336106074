import { Component, OnDestroy, OnInit, input, model } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { PaymentType } from '../../models/paymentData';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CommonModule } from '@angular/common';
import { InputCopyToClipboardComponent } from '../input-copy-to-clipboard/input-copy-to-clipboard.component';
import { AppSelectInputComponent, SelectModel } from '../app-select-input/app-select-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppInputCustomComponent, InputMaskTypes } from '../app-input-custom/app-input-custom.component';
import { AppToggleButtonComponent } from '../app-button-group/app-toggle-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { DateMode } from 'src/app/core/services/common.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';

declare var bootstrap: any;

@Component({
  selector: 'app-payment-modal',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, InputCopyToClipboardComponent, AppSelectInputComponent, ReactiveFormsModule,
    AppInputCustomComponent, AppInputComponent, AppToggleButtonComponent],
  templateUrl: './payment-modal.component.html',
  styleUrl: './payment-modal.component.scss'
})
export class PaymentModalComponent implements OnInit, OnDestroy {

  modalId = input.required<string>();
  backdropType = input<string>();
  paymentType = input<PaymentType>(PaymentType.CREDITO);
  paymentModalData = input.required<PaymentModalData>();
  InputMaskTypesEnum = InputMaskTypes;
  InputTypeEnum = InputType;
  DateMode = DateMode;
  numberOfInstallmentsOptions: SelectModel[] = [];
  canChekPix = true;

  creditCardformGroup = new FormGroup({
    cardNumber: new FormControl('', Validators.required),
    numberOfInstallmentsControl: new FormControl('', Validators.required),
    cvvControl: new FormControl('', Validators.required),
    cardExpirationDate: new FormControl('', Validators.required),
    cardHolderName: new FormControl('', Validators.required),
    CPFControl: new FormControl('', Validators.required),
  });

  PaymentType = PaymentType;
  confirmAction = model(false);
  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    fontSize: 14,
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Confirmar Pagamento',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-primary',
    fontSize: 14,
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  constructor(
    private modalService: ModalService,
    private paymentDataService: PaymentDataService
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === this.modalId()) {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            const modal = new bootstrap.Modal(modalElement);

            this.generateIstallmentsOptions();
            modal.show();
          } else if (state.action === 'close') {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              modal.hide();
            }
          }
        }
      }
    });
  }

  generateIstallmentsOptions() {

    let maxInstallments = parseInt(localStorage.getItem('maximumNumberInstallment') as string);
    this.numberOfInstallmentsOptions = [];
    for (let i = 1; i <= maxInstallments; i++) {
      this.numberOfInstallmentsOptions.push({ code: i.toString(), name: i.toString() });
    }
  }

  onConfirm() {
    if (this.paymentType() === PaymentType.CREDITO) {
      this.paymentWithCreditCard();
      
    }else{
      this.disableCreditCardFields();
    }
    this.modalService.close(this.modalId());
  }

  disableCreditCardFields() {
    this.creditCardformGroup.controls['cardNumber'].disable();
    this.creditCardformGroup.controls['numberOfInstallmentsControl'].disable();
    this.creditCardformGroup.controls['cvvControl'].disable();
    this.creditCardformGroup.controls['cardExpirationDate'].disable();
    this.creditCardformGroup.controls['cardHolderName'].disable();
    this.creditCardformGroup.controls['CPFControl'].disable();
  }

  checkPixPayment(orderId: any): void {
    this.paymentDataService.getPaymentOrder(orderId).subscribe((data) => {
      if (data.status != 'paid') {
        if(this.canChekPix){
          setTimeout(() => {
            this.checkPixPayment(orderId);
          }, 3000);
        }
      }else{
        this.modalService.close(this.modalId());
      }
    });
  }


  paymentWithCreditCard() {
    // let body = {
    //   payment_method_id: 
    //   cardNumber: "",
    //   cardHolderName: "",
    //   cardCvv: "",
    //   cardExpirationDate: ""
    //   }
    console.log('paymentWithCreditCard',this.paymentModalData().invoiceNumber);
    
    console.log('paymentWithCreditCard',this.creditCardformGroup.value);
    this.confirmAction.set(true);
  }

  onCancel(){
    this.confirmAction.set(false);
    this.modalService.close(this.modalId());
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

}

export interface PaymentModalData {
  invoiceId?: string;
  invoiceNumber?: string;
  invoiceCode?: string;
  invoiceCodeImg?: string;
  creditCardOptions?: SelectModel[];
}
