import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InfoCardComponent } from 'src/app/shared/components/info-card/info-card.component';
import { RankingTableComponent } from 'src/app/shared/components/ranking-table/ranking-table.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';
import { Location } from '@angular/common';
import { MemberFinalScoreService } from 'src/app/core/services/member-final-score.service';
import { MemberFinalScoreModel } from 'src/app/shared/models/member-final-score';
import { ExperienceService } from 'src/app/core/services/experience.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { RankingService } from 'src/app/core/services/ranking.service';
import { FileService } from 'src/app/core/services/file.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

interface Row {
  ranking: number;
  photo: string;
  socio: string;
  cidade: string;
  pontos: number;
  trend?: 'up' | 'down';
}

@Component({
  selector: 'app-ranking-de-socios',
  standalone: true,
  imports: [
    CommonModule,
    InfoCardComponent,
    AppButtonComponent,
    RankingTableComponent,
    RouterLink
  ],
  templateUrl: './ranking-de-socios.component.html',
  styleUrl: './ranking-de-socios.component.scss'
})
export class RankingDeSociosComponent implements OnInit {
  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  score:any;
  ranking: any;
  tableConfig: any;

  infos = [
    { title: 'Pontos', value: '' },
    { title: 'Tendência', value: '', icon: '', extraText: '' },
    { title: 'Posição atual', value: '' },
    { title: 'Última posição', value: '' }
  ];
  public styles: { [key: string]: any };
  labelColor = 'white';
  memberFinalScore: MemberFinalScoreModel | undefined;
  historyPoints: any[] = [];
  user: UserModel;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private location: Location,
    private experienceService: ExperienceService,
    private router: Router,
    private memberFinalScoreService: MemberFinalScoreService,
    private userService: UserService,
    private rankingService: RankingService,
    private fileService: FileService,
    private alertService: AlertService
  ) {
    this.user = this.userService.currentUserValue as UserModel;
  }

  rows: Row[] = [
    { ranking: 1, photo: 'assets/imagens/socios/socio-1.png', socio: 'Diones Santos', cidade: 'Brasília - DF', pontos: 500, trend: 'up' },
    { ranking: 2, photo: 'assets/imagens/socios/socio.png', socio: 'Guilherme de Oliveira', cidade: 'Brasília - DF', pontos: 490, trend: 'up' },
    { ranking: 3, photo: 'assets/imagens/socios/socio-2.png', socio: 'Josias Silva', cidade: 'Goiânia - CO', pontos: 475, trend: 'down' },
    { ranking: 4, photo: 'assets/imagens/socios/socio.png', socio: 'Fellipe Amorim', cidade: 'Belo Horizonte - MG', pontos: 470, trend: 'up' },
  ];

  public buttonOptions: any;

  ngOnInit(): void {
    this.validateAccess();

    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.buttonOptions = {
      buttonText: 'Visualizar histórico completo',
      buttonSize: 'btn btn-sm btn-primary',
      borderRadius: layoutVersion === 1 ? '25px' : '0px'
    };

    this.styles = {
      '--border-radius': layoutVersion === 1 ? '15px' : '0px'
    };

    const currentUser = JSON.parse(localStorage.getItem('current-user') || '{}');
    const memberId = currentUser.id;

    this.experienceService.getMemberScore(memberId).subscribe(score=> {
      this.score = score;
    })
    this.fetchMemberFinalScore(memberId);

    this.memberFinalScoreService.getScoresEntryByMember(memberId).subscribe(res => {
         this.historyPoints = res;
    })

    this.getRanking();
  }

  fetchMemberFinalScore(memberId: string): void {
    this.memberFinalScoreService.getByMemberId(memberId).subscribe((data: MemberFinalScoreModel) => {
      this.memberFinalScore = data;
      this.updateInfos(data);
    });
  }

  goToHistoryPoints(){
    this.router.navigate(['/historico-pontos'], { state: { historyPoints: this.historyPoints , score: this.score} });
  }

  updateInfos(data: MemberFinalScoreModel): void {
    this.infos = [
      { title: 'Pontos', value: this.score },
      {
        title: 'Tendência',
        value: '',
        icon: data.trend === 'up' ? 'bi-caret-up-fill' : (data.trend === 'down' ? 'bi-caret-down-fill' : ''),
        extraText: data.trend === 'up' ? 'Subindo' : (data.trend === 'down' ? 'Descendo' : '')
      },
      { title: 'Posição atual', value: `${data.currentPosition}º` },
      { title: 'Última posição', value: `${data.lastPosition}º` }
    ];
  }

  back() {
    this.location.back();
  }

  getRanking() {
    this.rankingService.getRanking().subscribe((ranking: any) => {
      this.ranking = ranking;
      this.setTableConfig()
      console.log(this.ranking, 'ranking');
    });
  }

  setTableConfig() {
    if (!this.ranking) return;
    this.tableConfig = {
      caretUp: `color: ${this.ranking?.upTrendIconColor}`,
      caretDown: `color: ${this.ranking?.downTrendIconColor}`,
      thead: `background-color: ${this.ranking?.tableHeaderBackgroundColor}; color: ${this.ranking?.tableHeaderTextColor}`,
      titleStyle: `font-size: ${this.ranking?.titleFontSize}px; color: ${this.ranking?.titleColor}`,
      subtitleStyle: `font-size: ${this.ranking?.subtitleFontSize}px; color: ${this.ranking?.subtitleColor}`,
      primaryRowBackgroundColor: `background-color: ${this.ranking?.primaryRowBackgroundColor}; color: ${this.ranking?.tableTextColor}`,
      secondaryRowBackgroundColor: `background-color: ${this.ranking?.secondaryRowBackgroundColor}; color: ${this.ranking?.tableTextColor}`,
      background: this.ranking?.backgroundType == 'COLOR' ? `background-color: ${this.ranking?.backgroundColor}` : `background-image: url(${this.getImage(this.ranking?.document.id)})`,
    }
  }

  getImage(imgFile: any) {
    return this.fileService.getDocDownloadUrl(imgFile as string);
  }

  // Método de validação de acesso
  private validateAccess(): void {
    const activeSections = localStorage.getItem('activeSections');

    if (!activeSections) {
      this.handleAccessDenied();
      return;
    }

    const parsedSections = JSON.parse(activeSections);

    if (!parsedSections['ranking']) {
      this.handleAccessDenied();
    }
  }

  // Tratamento de acesso negado
  private handleAccessDenied(): void {
    this.alertService.showAlert(AlertType.WARNING, 'Você não possui acesso a essa página');
    this.router.navigate(['/dashboard']);
  }
}
