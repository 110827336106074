<div class="container welcome-page" id="welcome-page">
    <div class="logo-sou-mais-fc-page p-3 text-center" id="logo-page">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="welcoming-message text-center" id="welcoming-message" [innerHTML]="welcomeMessage"></div>
    <div class="welcoming-img p-3 text-center">
        <img [src]="imgWelcoming" alt="Welcoming" id="welcoming-img">
    </div>

    <div class="d-flex justify-content-center mt-4">
        <app-button class="my-2 mx-2"
            id="go-to-dashboard"
            [buttonOptions]="btnGoToLogin"
            (buttonClick)="goToLogin()">
        </app-button>
    </div>
</div>
