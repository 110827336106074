import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { CreditCardInfoModel } from 'src/app/shared/models/paymentData';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { lastValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-gerenciar-pagamento',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterLink, AppButtonComponent],
  templateUrl: './gerenciar-pagamento.component.html',
  styleUrl: './gerenciar-pagamento.component.scss'
})
export class GerenciarPagamentoComponent implements OnInit {

  // TODO: Rever estrutura de color
  labelColor = 'white';

  creditCardInfo: CreditCardInfoModel[];

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  buttonAddCardOptions = {
    buttonText: '+ Adicionar cartão',
    borderRadius: '30px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
  };

  constructor(private localStorageService: LocalStorageService,
    private paymentDataService: PaymentDataService,
    private commonService: CommonService,
    private location: Location) { }

  ngOnInit() {
    this.setCreditCardArr();
  }

  back() {
    this.location.back();
  }

  async setCreditCardArr() {
    try {
      const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
      const updatedArr = await this.handleCreditCardInfo(creditCardArr);
      this.creditCardInfo = updatedArr;
    } catch (error) {
      console.error('Error setting credit card info:', error);
      // Handle error appropriately, e.g., show an alert to the user
    }
  }

  private async handleCreditCardInfo(creditCardArr: CreditCardInfoModel[]): Promise<CreditCardInfoModel[]> {
    if (!creditCardArr.length) {
      try {
        const fetchedData = await lastValueFrom(this.paymentDataService.getCreditCardInfo());
        this.localStorageService.setItem('credit-card-info', fetchedData);
        return fetchedData; // Return the fetched data
      } catch (error) {
        console.error('Error fetching credit card info:', error);
        // Handle the error appropriately, e.g., show an alert to the user
      }
    }
    return creditCardArr; // Return existing data if no fetch needed
  }

}
