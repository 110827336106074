<div class="container p-4 p-md-5 recover-pass-screen">
  <div class="d-flex justify-content-end">
    <app-button
      id="back-desktop"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="buttonOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>
  <div class="logo-sou-mais-fc-page p-3 text-center">
    <img [src]="logoUrl" alt="Logo" />
  </div>
  <div class="antecipar-faturas-main-title px-5 my-4 text-center">
    {{ pageTitle }}
  </div>
  <div class="row justify-content-center">
    <div class="card">
      <form [formGroup]="antecipationForm">
        <div class="row m-4 justify-content-center">
          <div class="col-12">
            <ng-multiselect-dropdown
              [settings]="nextInvoicesSettings"
              id="next-invoices"
              placeholder="Selecionar as Faturas que deseja antecipar"
              [data]="nextInvoicesFormated"
              formControlName="nextInvoices"
              (ngModelChange)="changeInvoices($event)"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="row d-flex m-4">
          <!-- <div class="col-6">
            <label class="mb-1">Desconto</label>
            <input
              type="text"
              class="form-control"
              formControlName="discount"
              placeholder="Desconto"
              toOnlyNumberAndCaracter
              (blur)="changeDiscount()"
            />
          </div> -->
          <div class="col-12">
            <label class="mb-1">Valor</label>
            <input
              type="text"
              class="form-control"
              formControlName="amount"
              placeholder="Valor"
            />
          </div>
        </div>

        <div class="row d-block m-4">
          <label class="mb-3">Método de Pagamento</label>
          <div class="row d-flex">
            <div class="col-6">
              <app-button
                id="pix-option"
                [isIcon]="true"
                [left]="true"
                [iconType]="'mat-input'"
                [iconName]="'pix-darker'"
                [buttonOptions]="buttonPixOptions"
                (buttonClick)="setPaymentMethod(1)"
              ></app-button>
            </div>
            <div class="col-6">
              <app-button
                id="credit-card-option"
                [isIcon]="true"
                [left]="true"
                [iconName]="'bi bi-credit-card'"
                [buttonOptions]="buttonCreditCardOptions"
                (buttonClick)="setPaymentMethod(2)"
              ></app-button>
            </div>
          </div>
        </div>

        <div
          *ngIf="isCreditCard"
          class="payment-additional-info mt-4 fs-small"
        >
          <form [formGroup]="creditCardformGroup">
            <div class="row m-4">
              <app-input-custom
                class="col-12"
                [id]="'cardNumberInputId'"
                [placeholder]="'Insira o número do cartão'"
                [label]="'Número do cartão'"
                [mask]="InputMaskTypesEnum.CREDIT_CARD"
                formControlName="cardNumberControl"
              ></app-input-custom>

            </div>
            <div class="row m-4">
              <app-input
              class="col-md-6"
              [id]="'expirationDateInputId'"
              [label]="'Data de validade'"
              [type]="InputTypeEnum.MONTH"
              [placeholder]="DateMode.MMAA"
              formControlName="expirationDateControl"
            ></app-input>
              <app-input
                class="col-md-6"
                [id]="'cvvInputId'"
                [label]="'CVV'"
                [placeholder]="'CVV'"
                [type]="InputTypeEnum.NUMBER"
                [maxLength]="3"
                formControlName="cvvControl"
              ></app-input>
            </div>
            <div class="row m-4">
              <app-input
                class="col"
                [id]="'cardNameInputId'"
                [label]="'Nome do cartão'"
                [placeholder]="'Insira o nome do titular do cartão'"
                formControlName="cardNameControl"
              ></app-input>
            </div>
          </form>
        </div>
        <div class="row m-4">
          <app-button
            class="col w-100"
            [buttonOptions]="buttonCreateAntecipationOptions"
            (buttonClick)="createAntecipation()"
          ></app-button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-payment-modal
    [modalId]="modalId"
    [paymentType]="paymentType"
    [paymentModalData]="paymentModalData"
    (confirmActionChange)="onModalConfirm()"
></app-payment-modal>
