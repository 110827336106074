<div
  class="modal fade"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ modalTitle() }}
        </h1>

        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        @if ( !this.openCheckin) {
        Checkin não habilitado no momento
        }
        @if (showSelectInput() && this.openCheckin) {
        <app-select-input
          class="col-md-4"
          [id]="'selectInputId'"
          [label]="'Selecionar Usuário'"
          [options]="selectInputOptions"
          [formControl]="selectControl()"
          (ngModelChange)="alterarTickets()"
        ></app-select-input>
        } @if (showSelectInput()  && this.openCheckin) {
        <app-select-input
          class="col-md-4"
          [id]="'selectTicketInputId'"
          [label]="'Selecionar Ingresso'"
          [options]="ticketsOptions"
          [formControl]="ticketControl()"
        ></app-select-input>
        }

        <hr />

        @if (checkinsHistory.length > 0) {
        <div class="payment-history px-3">
          <div class="payment-history-title text-left py-3">
            Lista de Checkins Realizados
          </div>
          <div class="history pt-4">
            <div class="history-types">
              <div class="table">
                <table class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">Nome</th>
                      <th scope="col">Ingresso</th>
                      <th scope="col">Situação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  @for (i of checkinsHistory; track $index) {
                  <tbody>
                    <tr class="text-center">
                      <td>
                        {{
                          i?.memberDependentName
                            ? i.memberDependentName
                            : i.memberPlanName
                        }}
                      </td>
                      <td>{{ getTicketById(i) }}</td>
                      <td>{{ i?.checkinStatus }}</td>
                      <td>
                        <div class="d-block align-middle">
                          <app-button
                            id="view-checkin"
                            [isIcon]="true"
                            [left]="true"
                            [iconName]="'bi bi-box-arrow-up-right'"
                            [buttonOptions]="visualizarOptions"
                            (buttonClick)="visualizarCheckin(i.ticketLink)"
                          >
                          </app-button>
                          <app-button
                          id="cancel-checkin"
                          [isIcon]="true"
                          [left]="true"
                          [iconName]="'bi bi-ban'"
                          [disabled]="i?.checkinStatus == 'Cancelado' ? true : false || !this.isCanceled ? true : false || i?.ticketValue > 0 ? true : false"
                          [buttonOptions]="cancelarOptions"
                          (buttonClick)="cancelarCheckin(i)"
                          [style]="'margin-top: 8px'"
                        >
                        </app-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="modal-footer">
        @if (showCloseBtn()) {
        <app-button
          class="mx-2 my-1"
          id="cancelModalBtn"
          [buttonOptions]="cancelBtn"
          data-bs-dismiss="modal"
        ></app-button>
        }
        <app-button
          class="mx-2 my-1"
          id="confirmModalBtn"
          [disabled]="this.openCheckin ? false : true"
          [buttonOptions]="confirmBtn"
          (buttonClick)="onConfirm()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
