import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MemberFinalScoreModel } from 'src/app/shared/models/member-final-score';
import { Page } from 'src/app/shared/models/Page';

@Injectable({
  providedIn: 'root'
})
export class MemberFinalScoreService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
      super(http, errorHandlerService);
    }

  getAll(): Observable<Page<MemberFinalScoreModel>> {
    return this.get<Page<MemberFinalScoreModel>>(`${this.env.publicApiSocioUrl}`);
  }

  getByMemberId(memberId: string): Observable<MemberFinalScoreModel> {
    return this.get<MemberFinalScoreModel>(`${this.env.socioApiUrl}/member-final-score/member/${memberId}`);
  }

  getScoresEntryByMember(memberId: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-score-entries/get-scores-entry-by-member/${memberId}`);
  }
}
