<div class="pt-4 pt-md-5 px-md-3 px-lg-0">
    <div class="d-flex justify-content-end mb-3">
        <app-button id="back-desktop" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonBackOptions" (buttonClick)="back()">
        </app-button>
    </div>

    <div class="header-page">
        <h1 class="main-title" [style.color]="labelColor">
            Parcerias
        </h1>
        <form [formGroup]="filterGroup" class="col-12 col-md-6">
            <app-input [label]="''" [id]="'filterInputId'" [placeholder]="'Pesquisar por palavra chave'"
                formControlName="filterControl" (keyup)="filter()"></app-input>
        </form>
    </div>
    <app-cards-resgatar [data]="itemsFiltered"></app-cards-resgatar>
    @if (itemsInvalidFiltered.length > 0) {
    <app-cards-resgatar title="Cupons Utilizados" [data]="itemsInvalidFiltered"></app-cards-resgatar>
    }
</div>