import { Component } from '@angular/core';
import { AlertService, AlertInfo } from '../../services/alert.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TruncateTextDirective } from 'src/app/shared/directives/truncate-text.directive';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, TruncateTextDirective],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  alert: AlertInfo | null;
  private _destroy$ = new Subject<void>();

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.alertService.alertState
    .pipe(takeUntil(this._destroy$))
    .subscribe((alert: AlertInfo) => {
      this.alert = alert;
      if (alert) {
        setTimeout(() => this.clearAlert(), 5000); // Auto clear after 5 seconds
      }
    });
  }

  clearAlert() {
    this.alert = null;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
