import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit, output } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { TicketModelTO } from '../../models/ticketToModel';
import { FileService } from 'src/app/core/services/file.service';
import { GameService } from 'src/app/core/services/games.service';
import { CommonService } from 'src/app/core/services/common.service';


@Component({
  selector: 'app-ticket-card',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './app-ticket-card.component.html',
  styleUrl: './app-ticket-card.component.scss'
})
export class AppTicketCardComponent implements OnInit {
  ticket = input.required<any>();
  @Input() memberPlan:any;
  @Input() memberData:any;
  hasCheckinBtn = input<boolean>(true);
  hasTicketDetails = input<boolean>(false);
  btnCheckInAction = output();

  teamLogo: string;
  teamName = localStorage.getItem('clubName');
  adversaryLogo = '';

  checkInBtn = {
    buttonText: 'Realizar Check-In',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  constructor(private fileService: FileService,private gameService: GameService, private commonService: CommonService,) {}

  ngOnInit(): void {
    this.getLogo()
    if(this.ticket()){
      this.adversaryLogo= this.fileService.getDocDownloadUrl(this.ticket().waveGame.adversary.document.id);
    }
  }

  getLogo() {
    this.gameService.getNextMatches().subscribe({
      next: (response: any) => {
        let nextMatchesResponse = response;
        this.teamLogo = this.commonService.getImageStr(nextMatchesResponse.logo.id);
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    });
  }

  convertTimeToDate(time: string): Date {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  }

  checkIn(): void {
    this.btnCheckInAction.emit(this.ticket()?.id);
  }

}
