import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AlertService, AlertType } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private alertService: AlertService) { }

  public handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error && error.error.showError == 'false') {
      return throwError(error);
    } else {
      let errorMessage = '';
      if (error.error) {
        // Client-side errors
        errorMessage = `${error.error.message}`;
        this.alertService.showAlert(AlertType.DANGER, errorMessage); // Call alert service to show an alert
      } else {
        if (error.status) {
          // Server-side errors
          this.handleServiceError(error.status, error.message); // Handle specific HTTP status codes
        } else {
          return throwError(() => error);
        }
      }
      return throwError(() => error);
    }
  }



  private handleServiceError(status: number, errorMessage?: string): void {
    if (status) {
      switch (status) {
        case 400:
          // Bad Request        
          this.alertService.showAlert(AlertType.WARNING, errorMessage ?? 'Requisição inválida. Verifique os dados fornecidos.');
          break;
        case 401:
          // Unmemberized
          this.alertService.showAlert(AlertType.WARNING, errorMessage ?? 'Você não está autorizado a acessar este recurso.');
          break;
        case 403:
          // Forbidden
          this.alertService.showAlert(AlertType.WARNING, errorMessage ?? 'Você não tem permissão para acessar este recurso.');
          break;
        case 404:
          // Not Found
          this.alertService.showAlert(AlertType.WARNING, errorMessage ?? 'O recurso solicitado não foi encontrado.');
          break;
        case 500:
          // Internal Server Error
          this.alertService.showAlert(AlertType.DANGER, errorMessage ?? 'Ocorreu um erro interno no servidor. Tente novamente mais tarde.');
          break;
        default:
          this.alertService.showAlert(AlertType.DANGER, errorMessage ?? 'Ocorreu um erro inesperado.');
          break;
      }
    }
  }
}
