import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/core/services/member.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';

@Component({
  selector: 'app-send-confirmation-email',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './send-confirmation-email.component.html',
  styleUrl: './send-confirmation-email.component.scss'
})
export class SendConfirmationEmailComponent implements OnInit {

  pageTitle = 'Reenviar Confirmação de Email';
  emailSended = false;
  email = 'felipedias.developer@gmail.com';
  cpf = '';
  dataMember: any;

  logoUrl: string = localStorage.getItem('imageLogo') || '';

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  buttonOptionsSendEmail = {
    buttonText: 'Enviar Link',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  constructor(
    private location: Location, 
    private memberService: MemberService
  ) { }
  ngOnInit(): void {
    this.cpf = history.state.cpf;

    console.log(this.cpf)
    this.memberService.getDataUserByCpf(this.cpf).subscribe(res => {
      this.dataMember = res;
    })

  }

  back() {
    this.location.back();
  }

  resendEmail(){
    this.emailSended = true;
    this.memberService.resendEmail(this.dataMember).subscribe();
  }

}
