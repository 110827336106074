import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [],
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.scss'
})
export class NotFoundPageComponent {

  message: string = 'Página não encontrada';
  imageLogo = localStorage.getItem('imageLogo');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.message){
        console.log('message',param.message);
        
        this.message = param.message;
      }
    });
  }

  ngOnInit() {
    this.changeParentContainerClass();
  }

  ngOnDestroy() {
    this.restoreParentContainerClass();
  }


  private changeParentContainerClass() {
    const parent = this.el.nativeElement.parentElement;
    if (parent) {
      this.renderer.removeClass(parent, 'container');
    }
  }

  private restoreParentContainerClass() {
    const parent = this.el.nativeElement.parentElement;
    if (parent) {
      this.renderer.addClass(parent, 'container');
    }
  }

  

}
