import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { BaseService } from './base/base.service';
import { BalancesModel } from 'src/app/shared/models/balancesModel';

@Injectable({
  providedIn: 'root'
})
export class TransparencyService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getFilesData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/balances`);
  }

  getStatesData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-state`);
  }
  
  getCitiesData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-city`);
  }
  
  getNeighborhoodsData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-neighborhood`);
  }

  getPaymentMethodData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-payment-method`);
  }

  getGenderData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-gender`);
  }

  getAgeGroupsData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-age-group`);
  }
  
  getPlansData() {
    return this.get<{ content: BalancesModel[] }>(`${this.env.publicApiSocioUrl}v1/socio/members-transparency/members/count-by-plans`);
  }

}
