<div class="container py-5 px-5">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="headerPage">
        <h1 class="main-title" [style.color]="labelColor">
            Documentos
        </h1>
    </div>
    @if (canUpload()) {
        <app-file-upload  (fileSaved)="getDocumentsMember($event)"></app-file-upload> <!-- colocar o output aqui -->
    }
    <div class="documentosEnviados">
        @if(this.listDocuments?.length > 0) {
        <h2 class="main-title mb-3" [style.color]="labelColor">
            Documentos Enviados
        </h2>
        } @else {
            <h2 class="main-title mb-3" [style.color]="labelColor">
                Envie os seus Documentos
            </h2>
        }
        @if(this.listDocuments?.length < 0) {
            <p [style.color]="labelColor">Nenhum documento para exibir.</p>
        }
        @if(this.listDocuments?.length > 0) {
            <div class="card">
                <div class="table-responsive px-0">
                    <table class="table table-striped">
                        <thead>
                            <tr class="title text-center">
                                <th scope="col">Documento</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Justificativa</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of listDocuments; track $index) {
                                <tr class="text-center">
                                    <td>{{item.document.fileName}}</td>
                                    <td>
                                        {{ item.document.contentType == 'image/jpeg' ? 'Imagem' : (item.document.contentType == 'application/pdf' ? 'Documento' : 'Tipo desconhecido') }}
                                    </td>
                                    <td>{{item.rejectionReason}}</td>
                                      <td>
                                        {{ item.status == 'AWAITING_REVIEW' ? 'Aguardando análise' : (item.status == 'APPROVED' ? 'Aprovado' : 'Rejeitado') }}
                                      </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            
        }
        
    </div>
    
</div>
