<section class="section-planos" [ngStyle]="styles" *ngIf="items.length > 0">
    <div class="container wrapper-planos p-4" [ngClass]="classesAlign">
        <div class="wrapper-planos pb-3" [ngClass]="classesAlign">
            <h1 class="title-planos">{{title}}</h1>
            <p *ngIf="showSubtitle" class="subtitle-planos">{{subtitle}}</p>
        </div>
        <app-carousel-plans 
            [items]="items" 
            [styles]="styles"
            [defaultButtonOptions]="buttonOptionsCard"
            [highlightButtonOptions]="buttonOptionsCardSpecial"
            (carouselResponse)="carouselResponse($event)"></app-carousel-plans>
    </div>
</section>
