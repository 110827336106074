import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { Page } from 'src/app/shared/models/Page';
import { GeneralConfigModel } from 'src/app/shared/models/generalConfigModel';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends BaseService {

  constructor(http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  private env = environment;


  getExperiences(): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/experiences`);
  }

  getExperiencesByPlan(planId: any): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/experiences/get-experiences-by-plan/${planId}`);
  }

  getMemberScore(memberId: any) : Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-score-entries/get-score/${memberId}`);
  }

  rescueExperience(data: any) : Observable<any>{
    return this.post<any>(`${this.env.publicApiSocioUrl}v1/socio/member-score-entries/rescue-experience`, data);
  }


  getRescuesExperiences(memberId: any) : Observable<any>{
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/member-score-entries/get-rescues-experiences/${memberId}`);
  }

}
