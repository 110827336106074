import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { BenefitsService } from 'src/app/core/services/benefits.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { Page } from 'src/app/shared/models/Page';
import { Plan } from 'src/app/shared/models/planModel';
import { StyleContentBenefitTable } from 'src/app/shared/models/styleContent';

@Component({
  selector: 'app-section-tabela-beneficios',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-tabela-beneficios.component.html',
  styleUrl: './section-tabela-beneficios.component.scss'
})
export class SectionTabelaBeneficiosComponent {

  benefitsTableData: BenefitsTableDataModel[] = [];
  benefitsList: any[] = [];
  styles: { [key: string]: string };
  title = 'Tabela de Benefícios';
  isMobile: boolean = window.innerWidth < 768;
  styleBenefitTable: StyleContentBenefitTable;
  imageUrl: string;

  constructor(
    private benefitsService: BenefitsService,
    private planService: PlanService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getPublicPlans();
    this.fetchTableBenefitsStyles();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
    this.setMainStyle(this.styleBenefitTable);
  }

  private fetchTableBenefitsStyles(): void {
    this.benefitsService.getTableBenefitsStyles().subscribe((res: StyleContentBenefitTable) => {
      this.styleBenefitTable = res;
      this.title = this.styleBenefitTable?.title;
      this.imageUrl = this.commonService.getImageStr(this.styleBenefitTable.document?.id);

      this.setMainStyle(this.styleBenefitTable);
    });
  }

  setMainStyle(style: StyleContentBenefitTable): void {
    if (!style) return;

    this.styles = {
      '--display-section': style?.displaySection ? 'block' : 'none',
      '--background-image': style?.backgroundType === 'IMAGE' ? `url(${this.imageUrl})` : 'none',
      '--background-color': style?.backgroundColor,
      '--title-font-size': this.commonService.getRemFontSize(style?.titleFontSize, this.isMobile),
      '--title-color': style?.titleColor,
      '--header-background-color': style?.tableHeaderBackgroundColor,
      '--row-background-color': style?.tableRowBackgroundColor,
      '--table-text-color': style?.tableTextColor,
      '--table-header-text-color': style?.tableHeaderTextColor,
      '--table-border-color': style?.tableBorderColor
    }
  }

  getPublicPlans() {
    this.planService.getPublicPlans().subscribe((data: Page<Plan>) => {
      const filteredPlans = data.content.filter((plan: Plan) =>
        !!plan.saleLocations.find(location => location.name === 'Site')
      );
      this.buildBenefitList(filteredPlans);
      this.getAllBenefits();
    });
}


  getAllBenefits() {
    this.benefitsService.getAllBenefits().subscribe((data: any) => {
      this.benefitsList = data.content;
    });
  }


  buildBenefitList(plans: Plan[]): void {
    plans.forEach((item: any) => {
      this.benefitsTableData.push({
        id: item.id,
        title: item.planName,
        benefits: item.benefits
      });
    });
  }

  benefitsCheck(benefitPlan: any, benefitItemId: any) {
    let checkIcon = 'bi-dash-lg dash-icon';
    benefitPlan.benefits.forEach((benefit: any) => {
      if (benefit.id == benefitItemId) {
        checkIcon = 'bi-check-circle-fill check-icon';
      }
    });
    return checkIcon;
  }

  setLinestyle(idx: number){   
    let height = document.getElementById(`benefit-${idx}`)?.clientHeight;
    return `height: ${height ? height +2 : null }px;`;
  }

}

interface BenefitsTableDataModel {
  id: number;
  title: string;
  benefits: Benefit[];
}

interface Benefit {
  id: number;
  benefitName: string;
  isActive: boolean;
}
