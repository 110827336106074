import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/Page';

@Injectable({
  providedIn: 'root'
})
export class FooterService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getFooter(): Observable<Page<any>> {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/footer-config`);
  }

  getClubSocialNetworks() {
    return this.get(`${this.env.publicApiAdminUrl}v1/admin/clubs`);
  }

  getClubById(id: string) {
    return this.get(`${this.env.publicApiAdminUrl}v1/admin/clubs/${id}`);
  }
}
