<div class="container p-4 p-md-5 col-md-6">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="manage-payment-main-title my-4" [style.color]="labelColor">
        Gerenciar Pagamento
    </div>
    <div *ngIf="creditCardInfo?.length">
        @for (item of creditCardInfo; track $index) {
            <div class="card my-4 p-1 px-2">
                <div class="d-flex align-items-center">
                    <mat-icon class="p-1 me-2 mat-icon-credit" svgIcon="{{item.cardIcon}}"></mat-icon>
                    <!-- Div esconida apenas em xs  -->
                    <div class="d-sm-block d-none w-100 m-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-grid credit-card-info-md">
                                <span class="p-1 card-operator">
                                    {{item.cardOperator}}
                                </span>
                                <span class="p-1 card-number">
                                    ....{{item.cardNumber.slice(-4)}}
                                </span>
                            </div>
                            <div class="p-1 d-flex edit-card-md align-items-center cursor-pointer" [routerLink]="['/editar-cartao', item.cardNumber]">
                                Editar <i class="bi bi-pencil-square ms-1"></i>
                            </div>
                        </div>
                    </div>

                    <!-- Div visível apenas em xs -->
                    <div class="d-block d-sm-none w-100">
                        <div class="d-flex justify-content-between">
                            <div class="d-grid credit-card-info-sm">
                                <span class="p-1 card-operator">
                                    <!-- {{item.cardOperator}} -->
                                    {{ item.cardOperator.substring(0, 10) }}{{ item.cardOperator.length > 10 ? '...' : '' }}
                                </span>
                                <span class="p-1 card-number">
                                    ....{{item.cardNumber.slice(-4)}}
                                </span>
                            </div>
                            <div class="p-1 d-flex edit-card-sm align-items-center cursor-pointer" [routerLink]="['/editar-cartao', item.cardNumber]">
                                Editar <i class="bi bi-pencil-square ms-1"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    <div class="mt-5 mb-3 text-center">
        <app-button [buttonOptions]="buttonAddCardOptions" routerLink="/adicionar-cartao"></app-button>
    </div>
</div>
