<div class="container p-4 p-md-5" id="finalize-payment-page">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backOptions" (buttonClick)="back()">
        </app-button>
    </div>
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="finalize-payment-main-title my-4 text-center" [style.color]="labelColor">
        {{pageTitle}}
    </div>
    <div class="row">

        <form class="col-md-8" [formGroup]="finalizePaymentformGroup">
            <app-select-input class="col" [id]="'planInputId'" [label]="'Plano'" [options]="planOptions"
                formControlName="planControl"></app-select-input>

            <div class="py-2"></div>
            @if (planItem) {
            <div (click)="updateValueOfPayment()">
                <app-toggle-button class="col" [id]="'frequencyInputId'" [label]="'Periodicidade'"
                    [toggleButtonOptions]="frequencyOptions" [color]="'#E0C252'"
                    [formControlValue]="finalizePaymentformGroup.controls.frequencyControl"></app-toggle-button>
            </div>

            <div class="py-2"></div>
            <!-- <app-checkbox class="col" [id]="'invoiceRecurrenceId'" [label]="'Recorrência da fatura'"
                formControlName="invoiceRecurrenceControl"></app-checkbox> -->
            <div class="py-2"></div>
            @if(finalizePaymentformGroup.controls.frequencyControl.value) {
            <app-toggle-button class="col" [id]="'formOfPaymentInputId'" [label]="'Forma de Pagamento'"
                [toggleButtonOptions]="formOfPaymentOptions" [color]="'#FFFFFF'"
                [formControlValue]="finalizePaymentformGroup.controls.formOfPaymentControl"></app-toggle-button>
            }
            @if (dependentsOptions.length > 1 && planItem[0].amount != 0 ) {
            <div class="py-2"></div>
            <app-select-input class="col" [id]="'dependentInputId'" [label]="'Dependentes'"
                [options]="dependentsOptions" formControlName="dependentControl"
                (change)="updateValueWithDependents()"></app-select-input>
            }
            <!-- <div *ngIf="finalizePaymentformGroup.controls.formOfPaymentControl.value === PaymentType.PIX"
                class="payment-additional-info mt-5 fs-small">
                A chave PIX será gerada e enviada ao seu email após a confirmação.
            </div> -->
            <div *ngIf="showCreditCardOptions" class="payment-additional-info mt-4 fs-small">
                <form [formGroup]="creditCardformGroup">
                    <div class="row">
                        <app-input-custom class="col-12 col-md-6" [id]="'cardNumberInputId'"
                            [placeholder]="'Insira o número do cartão'" [label]="'Número do cartão'"
                            [mask]="InputMaskTypesEnum.CREDIT_CARD"
                            formControlName="cardNumberControl"></app-input-custom>
                        <app-input-custom class="col-md-6" [id]="'expirationDateInputId'" [placeholder]="'MM/AA'"
                            [label]="'Data de validade'" [mask]="InputMaskTypesEnum.MES_ANO"
                            [dropSpecialCharacters]="false" formControlName="expirationDateControl"></app-input-custom>
                    </div>
                    <div class="row">
                        <app-input class="col-md-6" [id]="'cvvInputId'" [label]="'CVV'" [placeholder]="'CVV'"
                            [type]="InputTypeEnum.NUMBER" [maxLength]="3" formControlName="cvvControl"></app-input>
                        @if (numberOfInstallmentsOptions.length > 0) {
                        <app-select-input class="col-md-6" [id]="'numberOfInstallmentsInputId'"
                            [label]="'Nº de parcelas'" [options]="numberOfInstallmentsOptions"
                            formControlName="numberOfInstallmentsControl">
                        </app-select-input>
                        }
                    </div>
                    <div class="row">
                        <app-input class="col" [id]="'cardNameInputId'" [label]="'Nome do cartão'"
                            [placeholder]="'Insira o nome do titular do cartão'"
                            formControlName="cardNameControl"></app-input>
                    </div>
                    <div class="row">
                        <app-input-custom class="col" [id]="'CPFInputId'" [label]="'CPF'"
                            [mask]="InputMaskTypesEnum.CPF" [placeholder]="'Insira o CPF do titular'"
                            formControlName="CPFControl"></app-input-custom>
                    </div>
                </form>
                <!-- <div class="row">
                    <app-select-input class="col-md-6" [id]="'creditCardInputId'" [label]="'Selecione o cartão'"
                        [options]="creditCardOptions()" formControlName="creditCardControl"></app-select-input>
                    
                </div>
                <div class="mt-5 mb-3 text-center">
                    <app-button [buttonOptions]="buttonAddCardOptions" routerLink="/adicionar-cartao"></app-button>
                </div> -->
            </div>
            }
        </form>
        @if (paymentSummary?.planName) {
        <div class="col-md-4 summary-payment">
            <div class="my-4">Resumo</div>
            <div class="row my-3">
                <div class="col-7 fs-small">
                    {{paymentSummary.planName}} -
                    {{getFrequencyFormLabel(finalizePaymentformGroup.controls.frequencyControl.value || '')}}
                </div>
                <div class="col-5">
                    <div class="fs-small fw-medium d-flex justify-content-end">
                        {{paymentSummary.amount > 0 ? (paymentSummary.amount | customCurrency) : 'Gratuito'}}
                    </div>

                    <!-- <div class="fs-xsmall d-flex justify-content-end">
                        {{paymentItem.planItemDetail}}
                    </div> -->
                </div>
            </div>
            <hr class="my-3">
            <div class="row my-3">
                <div class="col-7 fs-small">
                    <ng-container *ngIf="operation === 1; else otherOperations">
                        TOTAL UPGRADE
                    </ng-container>
                    <ng-template #otherOperations>
                        <ng-container *ngIf="operation === 6; else defaultOperation">
                            TOTAL MENSAL UPGRADE
                        </ng-container>
                        <ng-template #defaultOperation>
                            TOTAL
                        </ng-template>
                    </ng-template>
                </div>

                <div class="col-5">
                    <div class="fs-small fw-medium d-flex justify-content-end">
                        <ng-container *ngIf="operation === 1">
                            {{paymentSummary.upgradeAmount | customCurrency}}
                        </ng-container>
                        <ng-container *ngIf="operation === 6">
                            {{paymentSummary.amount | customCurrency}}
                        </ng-container>
                        <ng-container *ngIf="operation !== 1 && operation !== 6">
                            {{paymentSummary.amount | customCurrency}}
                        </ng-container>
                    </div>
                </div>
            </div>
            @if (paymentSummary.enableItems?.length > 0){
            <app-card-plan-items [planItem]="paymentSummary">
            </app-card-plan-items>
            }

            <div class="mt-4 row">
                <app-button class="col w-100"
                    [disabled]="finalizePaymentformGroup.invalid || creditCardformGroup.invalid"
                    [buttonOptions]="buttonOptions" (buttonClick)="save()"></app-button>
            </div>
        </div>
        }
    </div>

</div>
<app-payment-modal [modalId]="modalId" [paymentType]="paymentType" [paymentModalData]="paymentModalData"
    (confirmActionChange)="onModalConfirm()"></app-payment-modal>

<!-- <app-adicionar-cartao></app-adicionar-cartao> -->