import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { PaymentMethodsCheckboxModel } from 'src/app/shared/models/paymentMethodsModel';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

    getPaymentMethods() {
      return this.get(`${this.env.socioApiUrl}/payment-methods`);
    }

    getPaymentMethodsCheckBox(): Observable<PaymentMethodsCheckboxModel[]> {
      return this.get<PaymentMethodsCheckboxModel[]>(`${this.env.socioApiUrl}/payment-method-type/select-check-box`);
    }
}
