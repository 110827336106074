import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-newspaper',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule, CarouselModule, RouterLink, FormsModule ],
  templateUrl: './newspaper.component.html',
  styleUrl: './newspaper.component.scss'
})
export class NewspaperComponent implements OnInit{

  public dataNewspaper: any;

  ngOnInit(): void {
    this.dataNewspaper = {
      title: 'Ayrton Senna: saiba como foi o velório, enterro e homenagens ao piloto',
      subtitle: 'Há exatos 30 anos, em 5 de maio de 1994, tricampeão foi enterrado em cemitério de São Paulo; cortejo levou milhares às ruas da capital paulista em despedida digna de chefe de estado',
      banner: {
        url: '/assets/imagens/newspaper.png',
        title: '',
        alt: '',
      },
      autor: 'Diones Santos',
      text: [
        {
          title: '',
          subtitle: 'Quatro dias após a batida fatal que sofreu no GP de San Marino da F1, em um 5 de maio de 1994, um dos maiores ídolos do esporte brasileiro foi levado ao seu descanso final. Sepultado no Cemitério do Morumbi na Zona Sul de São Paulo, Ayrton Senna foi velado por quase dois dias com honrarias de chefe de estado e, em sua última volta, acompanhado por milhares de fãs pelas ruas da capital paulista. O ge relembra detalhes da despedida do tricampeão, há exatos 30 anos. Veja',
        },
        {
          title: 'Ainda na Itália',
          subtitle: 'As homenagens ao piloto começaram ainda nos dias 2 e 3 de maio, quando flores e bilhetes foram depositados aos montes na entrada do Instituto Médico Legal (IML) de Bolonha, na Itália. O primeiro cortejo a Senna, até o aeroporto local, recebeu aplausos de admiradores; de lá, o caixão do tricampeão foi levado para o Aeroporto de Paris rumo ao Brasil - em um voo comercial.',
        },
      ]
    }  
  }
 
}
