import { CommonModule } from '@angular/common';
import { Component, Input, OnInit  } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

@Component({
  selector: 'app-historico-de-pontos',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RouterLink
  ],
  templateUrl: './historico-de-pontos.component.html',
  styleUrl: './historico-de-pontos.component.scss'
})
export class HistoricoDePontosComponent implements OnInit {
  totalPontos: any;
  public buttonOptions: any;

  historicoPontos: any[] = [];

  constructor(private layoutConfigService: LayoutConfigService) { }

  ngOnInit(): void {

    this.historicoPontos = history.state.historyPoints;
    this.totalPontos = history.state.score;
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.buttonOptions = {
      buttonText: 'Voltar',
      buttonSize: 'btn btn-md btn-secondary',
      borderRadius: layoutVersion === 1 ? '25px' : '0px',
      fontSize: 14
    };
  }
}
