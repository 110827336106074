<div class="container p-4 p-md-5">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="notification-title mt-2 mb-5" [style.color]="'white'">
        Notificações
    </div>

    <div class="card" *ngIf="notificationsList.length > 0">
        <div class="card-body m-0 p-0">
            <div class="table-responsive notifications-table px-0 custom-scrollbar">
                <table class="table table-striped table mb-0">
                    <thead>
                        <tr class="text-center">
                            @if (!isMobile) {
                                <th scope="col">
                                    Data
                                </th>
                            }
                            <th scope="col">
                                Título
                            </th>
                            <th scope="col">
                                Mensagem
                            </th>
                            <th scope="col">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @if (notificationsList.length !== 0) {
                            @for (notification of notificationsList; track $index) {
                                <tr [ngClass]="notification.isViewed ? 'notification-read': 'notification-unread'">
                                    @if (!isMobile) {
                                        <td class="text-center" [appTruncateText]="80">
                                            {{ notification.createdAt }}
                                        </td>
                                    }
                                    <td class="text-center" [appTruncateText]="20">
                                        {{ notification.title }}
                                    </td>
                                    <td class="text-left" [appTruncateText]="80" [innerHTML]="notification.subject">
                                        
                                    </td>
                                    <td class="align-content-center">
                                        <div class="d-flex justify-content-around actions">
                                            <i class="bi bi-search px-2" role="button" title="Visualizar"
                                                (click)="viewNotification(notification)"></i>
                                            <i class="bi bi-trash px-2" role="button" title="Excluir"
                                                (click)="deleteNotification(notification)"></i>
                                        </div>
                                    </td>
                                </tr>
                            }
                        } @else {
                            <tr class="notification-read">
                                <td class="text-center" colspan="3">Nenhuma notificação encontrada</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <h4 *ngIf="notificationsList.length == 0">Não existem notificações cadastradas</h4>

</div>


<app-modal-notifications [modalId]="notificationModalId" [notificationModalData]="notificationModalData"
    (confirmAction)="viewNotificationModalConfirm()">
</app-modal-notifications>

<app-modal
    [modalTitle]="'Excluir notificação'"
    [modalId]="modalId"
    [modalBodyMessage]="modalBodyMessage"
    [showCloseBtn]="true"
    (confirmActionChange)="onModalConfirmDelete()"
></app-modal>
