import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient) { }

  downloadPDF(urlPdf: string): Observable<Blob> {
    const filePath = urlPdf;
    return this.getPdfBlob(filePath);
  }

  private getPdfBlob(filePath: string): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', filePath, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          observer.next(blob);
          observer.complete();
        } else {
          observer.error('Erro ao baixar o arquivo');
        }
      };

      xhr.onerror = () => {
        observer.error('Erro de rede');
      };

      xhr.send();
    });
  }
}