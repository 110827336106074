import { inject, Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends BaseService {
  private env = environment.publicApiSocioUrl;
  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  upload(formData: any): Observable<any> {
    const url = this.env + 'v1/socio/member-documents';   
    return this.http.post(url, formData);
  }
}