import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/area-publica/home/home.component';
import { DadosDeSocioComponent } from './features/dados-de-socio/dados-de-socio.component';
import { CadastroSocioComponent } from './features/cadastro-socio/cadastro-socio.component';
import { FinalizarPagamentoComponent } from './features/finalizar-pagamento/finalizar-pagamento.component';
import { AdicionarCartaoComponent } from './features/adicionar-cartao/adicionar-cartao.component';
import { GerenciarPagamentoComponent } from './features/gerenciar-pagamento/gerenciar-pagamento.component';
import { FaqComponent } from './features/faq/faq.component';
import { ParceriasComponent } from './features/parcerias/parcerias.component';
import { ConteudoExclusivoComponent } from './features/conteudo-exclusivo/conteudo-exclusivo.component';
import { NewspaperComponent } from './features/conteudo-exclusivo/newspaper/newspaper.component';
import { VideoComponent } from './features/conteudo-exclusivo/video/video.component';
import { LoginComponent } from './features/login/login.component';
import { authGuard } from './core/guards/auth.guard';
import { UpdatePasswordComponent } from './features/update-password/update-password.component';
import { SejaBemVindoComponent } from './features/finalizar-pagamento/seja-bem-vindo/seja-bem-vindo.component';
import { RecoverPasswordComponent } from './features/login/recover-password/recover-password.component';
import { CompletarDadosComponent } from './features/completar-dados/completar-dados.component';
import { RankingDeSociosComponent } from './features/ranking-de-socios/ranking-de-socios.component';
import { HistoricoDePontosComponent } from './features/historico-de-pontos/historico-de-pontos.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { TransparenciaComponent } from './features/transparencia/transparencia.component';
import { MeuPlanoComponent } from './features/meu-plano/meu-plano.component';
import { ConvidarAmigoComponent } from './features/convidar-amigo/convidar-amigo.component';
import { CheckInComponent } from './features/check-in/check-in.component';
import { UpgradePlanoComponent } from './features/upgrade-plano/upgrade-plano.component';
import { ViewInvoiceComponent } from './shared/components/view-invoice/view-invoice.component';
import { ListNotificationsComponent } from './features/notifications/list-notifications/list-notifications.component';
import { EditPlanComponent } from './features/edit-plan/edit-plan.component';
import { ExperienciasComponent } from './features/experiencias/experiencias.component';
import { ResgatesComponent } from './features/resgates/resgates.component';
import { DocumentosComponent } from './features/documentos/documentos.component';
import { SaveNewPasswordComponent } from './features/login/save-new-password/save-new-password.component';
import { PrivacyPolicyComponent } from './features/privacy-policy/privacy-policy.component';
import { TermosUsoComponent } from './features/termos-uso/termos-uso.component';
import { SendConfirmationEmailComponent } from './features/login/send-confirmation-email/send-confirmation-email.component';
import { AntecipationInvoicesComponent } from './features/antecipation-invoices/antecipation-invoices.component';
import { DetailsContentComponent } from './features/details-content/details-content.component';
import { CustomGuard } from './core/guards/custom.guard';
import { OutrosPlanosComponent } from './features/outros-planos/outros-planos.component';

const enableLoggedArea = localStorage.getItem('enableLoggedArea');

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [CustomGuard] },
  { path: 'login', component: LoginComponent, canActivate: [CustomGuard] },
  { path: 'login/recuperar-senha', component: RecoverPasswordComponent, canActivate: [CustomGuard] },
  { path: 'login/reenviar-email-confirmacao', component: SendConfirmationEmailComponent, canActivate: [CustomGuard] },
  { path: 'auth/confirm', component: SaveNewPasswordComponent },
  { path: 'auth/recover', component: SaveNewPasswordComponent },
  { path: 'cadastro-socio', component: CadastroSocioComponent, canActivate: [CustomGuard] },
  { path: 'completar-dados', component: CompletarDadosComponent, canActivate: [CustomGuard] },
  { path: 'finalizar-pagamento', component: FinalizarPagamentoComponent, canActivate: [CustomGuard] },
  { path: 'finalizar-pagamento/seja-bem-vindo', component: SejaBemVindoComponent, canActivate: [CustomGuard] },
  { path: 'alterar-senha', component: UpdatePasswordComponent, canActivate: [authGuard] },
  { path: 'dados-de-socio', component: DadosDeSocioComponent, canActivate: [authGuard] },
  { path: 'adicionar-cartao', component: AdicionarCartaoComponent, canActivate: [authGuard] },
  { path: 'editar-cartao/:cardNumber', component: AdicionarCartaoComponent, canActivate: [authGuard] },
  { path: 'gerenciar-pagamento', component: GerenciarPagamentoComponent, canActivate: [authGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard] },
  { path: 'lista-notificacoes', component: ListNotificationsComponent, canActivate: [authGuard] },
  { path: 'lista-notificacoes/:notificationId', component: ListNotificationsComponent, canActivate: [authGuard] },
  { path: 'meu-plano', component: MeuPlanoComponent, canActivate: [authGuard] },
  { path: 'outros-planos', component: OutrosPlanosComponent, canActivate: [authGuard] },
  { path: 'ver-fatura', component: ViewInvoiceComponent, canActivate: [authGuard] },
  { path: 'view-invoice', component: ViewInvoiceComponent, canActivate: [authGuard] },
  { path: 'antecipar-fatura', component: AntecipationInvoicesComponent, canActivate: [authGuard]},
  { path: 'editar-plano', component: EditPlanComponent, canActivate: [authGuard] },
  { path: 'upgrade-plano', component: UpgradePlanoComponent, canActivate: [authGuard] },
  { path: 'renovar-plano', component: UpgradePlanoComponent, canActivate: [authGuard] },
  { path: 'selecionar-plano', component: UpgradePlanoComponent},
  { path: 'ranking-de-socios', component: RankingDeSociosComponent, canActivate: [authGuard] },
  { path: 'historico-pontos', component: HistoricoDePontosComponent, canActivate: [authGuard] },
  { path: 'faq', component: FaqComponent },
  { path: 'politica-de-privacidade', component: PrivacyPolicyComponent },
  { path: 'regulamento', component: TermosUsoComponent },
  { path: 'parcerias', component: ParceriasComponent, canActivate: [authGuard] },
  { path: 'conteudo-exclusivo', component: ConteudoExclusivoComponent, canActivate: [authGuard] },
  { path: 'conteudo-exclusivo/newspaper', component: NewspaperComponent, canActivate: [authGuard] },
  { path: 'conteudo-exclusivo/video', component: VideoComponent, canActivate: [authGuard] },
  { path: 'conteudo-exclusivo/detalhe/:id', component: DetailsContentComponent, canActivate: [authGuard] },
  { path: 'transparencia', component: TransparenciaComponent, canActivate: [authGuard] },
  { path: 'convidar-amigo', component: ConvidarAmigoComponent, canActivate: [authGuard] },
  { path: 'check-in', component: CheckInComponent, canActivate: [authGuard] },
  { path: 'experiencias', component: ExperienciasComponent, canActivate: [authGuard] },
  { path: 'resgates', component: ResgatesComponent, canActivate: [authGuard] },
  { path: 'documentos', component: DocumentosComponent, canActivate: [authGuard] },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
