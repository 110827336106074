import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { CustomButtonDto } from 'src/app/shared/components/app-button/app-button-dto';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { CarouselPlansComponent } from 'src/app/shared/components/carousel-plans/carousel-plans.component';
import { PeriodicitiModel, Plan } from 'src/app/shared/models/planModel';
import { StyleContentPlan } from 'src/app/shared/models/styleContent';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

interface CarouselItem {
  title: string;
  enableItems: string[];
  disableItems: string[];
  highlight: boolean;
  periodo: string;
  valor: string;
  src: string;
  buttonOptions: CustomButtonDto
}

@Component({
  selector: 'app-section-planos',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink, CarouselPlansComponent],
  templateUrl: './section-planos.component.html',
  styleUrls: ['./section-planos.component.scss']
})



export class sectionPlanosComponente implements OnInit {

  buttonOptionsCard = {
    primaryColor: '#191844',
    secondaryColor: '#191844',
    buttonText: 'ME TORNAR SÓCIO',
    buttonTextColor: '#ffffff',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    buttonSize: 'btn btn-md',
    fontSize: 12
  };

  buttonOptionsCardSpecial = {
    primaryColor: '#191844',
    secondaryColor: '#191844',
    buttonText: 'ME TORNAR SÓCIO',
    buttonTextColor: '#ffffff',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    buttonSize: 'btn btn-md',
    fontSize: 12
  };

  title = '';
  subtitle = '';
  showSubtitle = false;

  isMobile = window.innerWidth < 768;
  items: CarouselItem[] = [];
  classes: any;
  classesAlign: any;
  currencyPipe = new CustomCurrencyPipe();

  public styles: { [key: string]: any };

  constructor(
    private layoutConfigService: LayoutConfigService,
    private planService: PlanService,
    private commonService: CommonService,
    private router: Router
  ) { }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
  }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.classes = {
      'layout-v2': layoutVersion === 2,
    };
    this.classesAlign = {
      'align-items-center': layoutVersion === 1,
      'align-items-start': layoutVersion === 2
    };
    
    this.getPlanConfig();

  }


  getAllPlans() {
    this.planService.getPublicPlans().subscribe((plans: any) => {
      this.items = plans.content
        .filter((plan: Plan) => !!plan.saleLocations.find(location => location.name === 'Site'))
        .map((plan: Plan) => ({
          title: plan.planName,
          enableItems: plan.benefits?.filter(benefit => benefit.isActive).map(benefit => benefit.benefitName) as string[],
          disableItems: plan.benefits?.filter(benefit => !benefit.isActive).map(benefit => benefit.benefitName) as string[],
          periodo: plan.periodicities.map((period: PeriodicitiModel) => period.description).join(', '),
          valor: `${this.currencyPipe.transform(plan.amount)}/mês`,
          src: this.commonService.getImageStr(plan.imgPortalPlan?.id as string),
          highlight: plan.highlightOnPortal,
          buttonOptions: plan.highlightOnPortal ? this.buttonOptionsCard : this.buttonOptionsCardSpecial,
          position: plan.position
        }));
        this.items.sort((a: any, b: any) => a.position - b.position);  
    });
}




  getPlanConfig() {
    this.planService.getPlanConfig().subscribe((config: StyleContentPlan) => {
      const styleContent = config;

      this.setStyles(styleContent);
      this.setBtnStyle(styleContent);
      this.getAllPlans();
    });
  }

  setStyles(styles: StyleContentPlan) {
    this.title = styles.title;
    this.subtitle = styles.subtitle;
    this.showSubtitle = styles.showSubtitle;
    this.styles = {
      carouselType: styles.carouselType,
      carouselInterval: styles.carouselInterval,
      '--show-section': styles.showSection ? 'flex' : 'none',
      '--show-subtitle': styles.showSubtitle ? 'block' : 'none',
      '--title-size': this.commonService.getRemFontSize(styles.titleSize, this.isMobile),
      '--title-color': styles.titleColor,
      '--subtitle-size': this.commonService.getRemFontSize(styles.subtitleSize, this.isMobile),
      '--subtitle-color': styles.subtitleColor,
      '--background-general-color': styles.backgroundGeneralColor,
      '--background-general-image': styles.backgroundGeneralType == 'IMAGE' ?  `url(${this.commonService.getImageStr(styles.backgrounGeneralImage?.id)})` : 'none',

      '--card-default-title-size': this.commonService.getRemFontSize(styles.cardDefaultTitleSize, this.isMobile),
      '--card-default-title-color': styles.cardDefaultTitleColor,
      '--card-default-description-size': this.commonService.getRemFontSize(styles.cardDefaultDescriptionSize, this.isMobile),
      '--card-default-description-color': styles.cardDefaultDescriptionColor,
      '--card-default-price-size': this.commonService.getRemFontSize(styles.cardDefaultPriceSize, this.isMobile),
      '--card-default-price-color': styles.cardDefaultPriceColor,
      '--card-default-background-color': styles.cardDefaultBackgroundColor,
      '--card-default-background-image': styles.cardDefaultBackgroundType == 'IMAGE' ?  `url(${this.commonService.getImageStr(styles.cardDefaultBackgroundImage?.id)})` : 'none',

      '--card-special-title-size': this.commonService.getRemFontSize(styles.cardSpecialTitleSize, this.isMobile),
      '--card-special-title-color': styles.cardSpecialTitleColor,
      '--card-special-description-size': this.commonService.getRemFontSize(styles.cardSpecialDescriptionSize, this.isMobile),
      '--card-special-description-color': styles.cardSpecialDescriptionColor,
      '--card-special-price-size': this.commonService.getRemFontSize(styles.cardSpecialPriceSize, this.isMobile),
      '--card-special-price-color': styles.cardSpecialPriceColor,
      '--card-special-background-color': styles.cardSpecialBackgroundColor,
      '--card-special-background-image': styles.cardSpecialBackgroundType == 'IMAGE' ?  `url(${this.commonService.getImageStr(styles.cardSpecialBackgroundImage?.id)})` : 'none',

      '--side-buttons-color': styles.sideButtonsColor,
      '--side-buttons-border-color': styles.sideButtonsBorderColor,
      '--side-buttons-border': styles.sideButtonsBorder ? '1px solid' : 'none',
      '--side-buttons-icon-color': styles.sideButtonsColor
    }
  }

  

  private setBtnStyle(styles: StyleContentPlan) {
    this.buttonOptionsCard = {
      primaryColor : styles.cardDefaultButtonColor,
      secondaryColor : styles.cardDefaultButtonColor,
      buttonText : styles.cardDefaultButtonText,
      buttonTextColor : styles.cardDefaultButtonTextColor,
      borderRadius : '25px',
      buttonBorderWidth : styles.cardDefaultButtonHasBorder ? '1px' : 'none',
      buttonBorderColor : styles.cardDefaultButtonBorderColor,
      buttonSize : 'btn btn-md',
      fontSize : 12,
    }

    this.buttonOptionsCardSpecial = {
      primaryColor : styles.cardSpecialButtonColor,
      secondaryColor : styles.cardSpecialButtonColor,
      buttonText : styles.cardSpecialButtonText,
      buttonTextColor : styles.cardSpecialButtonTextColor,
      borderRadius : '25px',
      buttonBorderWidth : styles.cardSpecialButtonHasBorder ? '1px' : 'none',
      buttonBorderColor : styles.cardSpecialButtonBorderColor,
      buttonSize : 'btn btn-md',
      fontSize : 12,
    }
  }

  carouselResponse(event: any) {
    this.router.navigateByUrl('/cadastro-socio');
  }


}
