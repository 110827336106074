<div class="modal fade" id="modal-guess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content modal-guess">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Dê o palpite sobre o jogo</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <div class="match p-3" *ngIf="matchModalData()?.teams?.length">
                <form class="d-flex" [formGroup]="matchGuessGroup">
                    <div class="team p-2">
                        <img class="m-2" [src]="matchModalData().teams[0].logo"/>
                        <div class="text-center">{{ matchModalData().teams[0].nome }}</div>
                        <app-input 
                            [id]="'team1'"
                            [placeholder]="'Insira o valor'"
                            [type]="InputTypeEnum.NUMBER"
                            formControlName="guessTeam1Control"
                        ></app-input>
                    </div>
                    <p class="against align-content-center px-3">X</p>
                    <div class="team p-2">
                        <img class="m-2" [src]="matchModalData().teams[1].logo"/>
                        <div class="text-center">{{ matchModalData().teams[1].nome }}</div>
                        <app-input
                            [id]="'team1'"
                            [placeholder]="'Insira o valor'"
                            [type]="InputTypeEnum.NUMBER"
                            formControlName="guessTeam2Control"
                        ></app-input>
                    </div>
                </form>
            </div>

        </div>
        <div class="modal-footer">
            <app-button class="mx-2 my-1"
                id="confirmModalBtn"
                [buttonOptions]="confirmBtn"
                [disabled]="!matchGuessGroup.valid"
                (buttonClick)="onConfirm()"></app-button>
        </div>
      </div>
    </div>
</div>
