<app-section-main id="main"></app-section-main>
<app-section-planos id="planos"></app-section-planos>
<app-section-tabela-beneficios id=""></app-section-tabela-beneficios>
<app-section-proximo-confronto id="ingressos"></app-section-proximo-confronto>
<app-section-card-beneficios id=""></app-section-card-beneficios>
<app-section-conteudo-exclusivo id="exclusivo"></app-section-conteudo-exclusivo>
<!-- <app-section-simple-card id="marketing"></app-section-simple-card> -->
<app-section-video id="video"></app-section-video>
<app-section-carousel-beneficios id="beneficios"></app-section-carousel-beneficios>
<app-section-ranking id="ranking"></app-section-ranking>
<app-section-faq id="faq"></app-section-faq>

@if (enableWhatsappButton) {
  <a [href]="whatsappLink" class="whatsapp-float" target="_blank">
    <i class="fab fa-whatsapp fa-2x"></i>
  </a>
  }