<div class="container px-4 py-5">
    <div class="newspaper">
        <div class="banner">
            <img [src]="dataNewspaper.banner.url" alt="dataNewspaper.banner.alt" alt="dataNewspaper.banner.title">
        </div>
        <h1 class="main-title">
            {{ dataNewspaper.title }}
        </h1>
        <p>{{ dataNewspaper.subtitle }}</p>
        <h2>Autor: {{ dataNewspaper.autor }}</h2>
        <hr/>
        <div class="content" *ngFor="let item of dataNewspaper.text">
            <h3 *ngIf="item.title !== ''">{{ item.title }}</h3>
            <p *ngIf="item.subtitle !== ''">{{ item.subtitle }}</p>
        </div>
    </div>
</div>