<div class="d-flex justify-content-sm-between px-0 py-5 pb-0">
  <div class="main-title mb-4" [style.color]="labelColor">
    Regulamento
  </div>
  <app-button id="back-desktop" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
    [buttonOptions]="backOptions" (buttonClick)="back()">
  </app-button>
</div>

<div [innerHTML]="content" class="py-5"></div>
