<section class="main-section d-flex align-items-center" [ngStyle]="styles"
  [ngClass]="{
    'position-top': sectionMain.elementPosition === 'TOPO',
    'position-center': sectionMain.elementPosition === 'CENTRO',
    'position-base': sectionMain.elementPosition === 'BASE'
  }">
  <div class="container" [ngClass]="{
    'h-100': sectionMain.elementPosition === 'TOPO' || sectionMain.elementPosition === 'BASE',
    'd-flex': sectionMain.elementPosition === 'BASE',
    'justify-content-center': sectionMain.elementPosition === 'CENTRO',
    'align-items-center': sectionMain.elementPosition !== 'BASE',
    'align-items-end': sectionMain.elementPosition === 'BASE',
    'text-center': true
  }">
    <div class="p-3 row">
      <div class="col-md-10 offset-md-1">
        <h1 class="pb-3">
          <span class="prefixo">{{componentData.prefixo}}</span>
          <span *ngIf="sectionMain.hasCounter" class="specialCharacter">{{componentData.specialCharacter}}</span>
          <span *ngIf="sectionMain.hasCounter" class="highlight">{{componentData.highlight}}</span>
          <span class="sufixo">{{componentData.sufixo}}</span>
        </h1>
        <p *ngIf="sectionMain.hasSubtitle" class="pb-5 px-4 subtitle">{{componentData.subtitle}}</p>
        <app-button *ngIf="sectionMain.hasJoinPlanButton" 
          [buttonOptions]="buttonOptions" 
          routerLink="/cadastro-socio">
        </app-button>
      </div>
    </div>
  </div>
</section>
