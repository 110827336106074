<section class="section-ranking" [ngStyle]="styles" [style]="tableConfig?.background" *ngIf="ranking?.displaySection">
    <div class="container p-4">
        <div class="container-title pb-4" [ngClass]="classes">
            <strong [style]="tableConfig?.titleStyle">{{ranking?.title}}</strong>
            <p *ngIf="tableConfig?.displaySubtitle" [style]="tableConfig?.subtitleStyle">{{ranking?.subtitle}}</p>
        </div>
        <app-ranking-table [tableConfig]="tableConfig" *ngIf="tableConfig"></app-ranking-table>
        <!-- <div class="d-flex justify-content-center">
            <app-button  [buttonOptions]="buttonOptions"></app-button>
        </div> -->
    </div>
</section>
