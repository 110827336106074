import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { FaqService } from 'src/app/features/faq/faq.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { StyleContentFaq } from 'src/app/shared/models/styleContent';

@Component({
  selector: 'app-section-faq',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RouterLink
  ],
  templateUrl: './section-faq.component.html',
  styleUrl: './section-faq.component.scss'
})
export class SectionFaqComponent {

  public styles: { [key: string]: any };
  public buttonOptions: any;
  isMobile = window.innerWidth < 768;

  constructor(
    private faqService: FaqService,
    private commonService: CommonService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
  }

  ngOnInit(): void {
    this.getFaqSectionStyles();
  }

  getFaqSectionStyles() {
    this.faqService.getFaqSection().subscribe((response: StyleContentFaq) => {
      let faqSecResp = response;
           
      this.buttonOptions = {
        buttonText: 'Tirar Dúvidas',
        buttonSize: 'btn btn-md',
        borderRadius: '25px',
        primaryColor: faqSecResp.buttonColor,
        buttonTextColor: faqSecResp.buttonColorText,
        buttonBorderColor: faqSecResp.buttonBorderColor  || 'none',
        buttonBorderWidth: faqSecResp.isButtonBorder ? '1px' : 'none',
        btnBorderHover: faqSecResp.buttonBorderWhenHovering,
        btnBorderHoverColor: faqSecResp.buttonBorderColorWhenHovering,
        btnTextHoverColor: faqSecResp.buttonTextColorWhenHovering,
        btnHoverColor: faqSecResp.buttonColorWhenHovering,
      };      
      this.setStyles(faqSecResp);
    });
  }

  setStyles(styles: StyleContentFaq) {
    this.styles = {
      '--show-section' : styles.showSection ? 'block' : 'none',
      '--color-title' : styles.colorTitle,
      '--size-fonte-title' : this.commonService.getRemFontSize(styles.sizeFontTitle, this.isMobile),
      '--color-subtitle' : styles.colorSubtitle,
      '--description-text-size' : this.commonService.getRemFontSize(styles.descriptionTextSize, this.isMobile),
      '--description-text-color' : styles.textColor,
      '--size-fonte-subtitle' : styles.sizeFontSubtitle,
      '--background-image': styles.backgroundType == 'IMAGE' ?  `url(${this.commonService.getImageStr(styles.backgroundImage?.id)})` : 'none',
      '--background-color' : styles.backgroundColor,
      '--background-card-image': styles.backgroundCardType == 'IMAGE' ?  `url(${this.getImageCardUrl(styles)})` : 'none',
      '--background-card-color' : styles.backgroundCardColor,
    };    
  }

  getImageCardUrl(faq: StyleContentFaq) {
    if (faq?.defaultStyle) {
      return '/assets/imagens/bg-faq.png';
    }
    return this.commonService.getImageStr(faq.backgroundCardImage?.id);
  }

}
