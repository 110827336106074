import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss'
})
export class CookieConsentComponent implements OnInit{
  showCookieBanner: boolean = false;
  public styles: { [key: string]: string };

  buttonOptions = {
    buttonText: 'Eu Concordo',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  ngOnInit(): void {
    const acceptCookies = localStorage.getItem('accept-cookies');
    if (!acceptCookies) {
      this.showCookieBanner = true;
    }

    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
      '--secondaryColor': localStorage.getItem('secondaryColor') as string,
    };
  }

  acceptCookies(): void {
    localStorage.setItem('accept-cookies', 'true');
    this.showCookieBanner = false;
  }

  declineCookies(): void {
    this.showCookieBanner = false;
  }
}
