export interface InvoiceModel {
    amount: number;
    createdAt?: string;
    description?: string;
    discount?: number;
    dueDate?: string;
    externalId?: string;
    fee?: number;
    id?: string;
    invoiceOriginId?: string;
    invoiceType?: string;
    isActive?: boolean;
    isDeleted?: boolean;
    memberPlanId?: string;
    paymentDate?: string;
    paymentMethod?: string;
    status?: StatusInvoice;
    updatedAt?: string;
    memberId?: string;
    refMonth?: string;
    paymentMethodId?: string,
    periodicityId?: string
    invoicePaymentStatus?:string
}

export interface InvoiceModelPublic {
    amount: number;
    id?: string;
    refMonth?: string;
    refYear?: string;
    dueDate?: string;
    invoiceType?: InvoiceType;
    invoicePaymentStatus?: InvoicePaymentStatusModel;
    paymentDate?: string;
}

export interface InvoicePaymentStatusModel {
    statusTypeName: string;
    id?: string;
    createdAt?: string;
    updatedAt?: string;
}


export class InvoiceType {
    public id?: string;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public isDeleted?: boolean;
    public createdAt?: string;
    public updatedAt?: string;
  }

export enum StatusInvoice {
    PENDING = 'Pendente',
    CANCELED = 'Cancelado',
    PAID = 'Pago'
}
