export interface CustomButtonDto {
    primaryColor?: string;
    secondaryColor?: string;
    buttonText?: string;
    buttonTextColor?: string;
    borderRadius?: string;
    buttonBorderWidth?: string;
    buttonBorderColor?: string;
    buttonSize?: string;
    btnDisabledBackground?: string;
    btnDisabledTextColor?: string;
    btnBorderHover?: boolean;
    btnBorderHoverColor?: string;
    btnTextHoverColor?: string;
    btnHoverColor?: string;
    fontSize?: number;
}

export const defaultCustomButtonDto: CustomButtonDto = {
    primaryColor: localStorage.getItem('primaryColor') as string,
    secondaryColor: localStorage.getItem('secondaryColor') as string,
    buttonText: 'Botão',
    buttonTextColor: '#ffffff',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000',
};