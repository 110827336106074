import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { AppSelectInputComponent, SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { PartnerData } from 'src/app/shared/models/partnerData';
import { OperationType } from '../finalizar-pagamento/finalizar-pagamento.component';
import { MemberService } from 'src/app/core/services/member.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CepService } from 'src/app/core/services/cep.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-completar-dados',
  standalone: true,
  imports: [ReactiveFormsModule, AppInputCustomComponent, AppInputComponent, AppSelectInputComponent, AppButtonComponent],
  templateUrl: './completar-dados.component.html',
  styleUrl: './completar-dados.component.scss'
})
export class CompletarDadosComponent implements OnInit {
  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Complemente seus Dados';
  memberId: string = '';

  protected stateOptions = [] as SelectModel[];

  protected cityOptions = [] as SelectModel[];

  submitBtn = {
    buttonText: 'Prosseguir',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-primary',
  };

  partnerDataformGroup = new FormGroup({
    CEPControl: new FormControl('', [Validators.required]),
    addressControl: new FormControl('', [Validators.required]),
    numberControl: new FormControl('', [Validators.required]),
    complementControl: new FormControl(''), 
    neighborhoodControl: new FormControl('', [Validators.required]),
    stateControl: new FormControl('', [Validators.required]),
    cityControl: new FormControl('', [Validators.required])
  });

  constructor(private router: Router,
    private memberService: MemberService,
    private commonService: CommonService,
    private cepService: CepService,
    private paymentDataService: PaymentDataService,
    private alertService: AlertService
  ) {
    this.memberId = this.memberService.getMemberId();
    if (!this.memberId) {
      this.router.navigate(['/cadastro-socio']);
    }
    this.getState();
  }

  ngOnInit(): void {
  }

  get controls(): any {
    return this.partnerDataformGroup.controls;
  }

  onSubmit(): void {
    if (this.partnerDataformGroup.valid) {
      // Handle form submission
      const partnerData = this.buildPayloadPartnerData(this.partnerDataformGroup);
      this.postMember(partnerData);
    } else {
      // Handle form errors
      console.log('Form is invalid');
    }
  }

  private buildPayloadPartnerData(formGroup: FormGroup): PartnerData {
    return {
      area: formGroup.get('addressControl')?.value,
      cityCode: parseInt(formGroup.get('cityControl')?.value),
      memberId: this.memberId,
      neighborhood: formGroup.get('neighborhoodControl')?.value,
      complement: formGroup.get('complementControl')?.value, // Campo opcional
      number: parseInt(formGroup.get('numberControl')?.value),
      stateCode: parseInt(formGroup.get('stateControl')?.value),
      zipCode: parseInt(formGroup.get('CEPControl')?.value.replace('-', ''))
    };
  }

  getAddressByCEP(): void {
    const cep = this.partnerDataformGroup.get('CEPControl')?.value as string;
    if (cep.length === 8) {
      this.cepService.searchCep(cep).subscribe(
        (response) => {
          this.partnerDataformGroup.reset();
          if(response.erro == 'true'){
            this.alertService.showAlert(AlertType.DANGER, 'CEP não encontrado');
            return;
          }
          
          this.partnerDataformGroup.get('CEPControl')?.setValue(response.cep);
          this.partnerDataformGroup.get('addressControl')?.setValue(response.logradouro);
          this.partnerDataformGroup.get('neighborhoodControl')?.setValue(response.bairro);
          this.partnerDataformGroup.get('stateControl')?.setValue(this.stateOptions.find((state) => state.name === response.estado)?.code as string);
          // Chama getCity e define a cidade correta
          this.getCity(response.localidade);
        }
      );
    }
  }

  getState(): void {
    this.commonService.getStatePublic().subscribe(
      (response) => {
        this.stateOptions = response.map((state: any) => {
          return {
            code: state.id,
            name: state.name
          } as SelectModel;
        });
      });
  }

  getCity(cityName?: string): void {
    this.cityOptions = [];
    this.commonService.getCity(this.partnerDataformGroup.get('stateControl')?.value).subscribe(
      (response) => {
        this.partnerDataformGroup.get('cityControl')?.setValue(response.find((city: any) => city.name.toLowerCase() === cityName?.toLowerCase())?.id);
        this.cityOptions = response.map((city: any) => {
          return {
            code: city.id,
            name: city.name
          } as SelectModel;
        });
        this.cityOptions.unshift({ code: '0', name: 'Selecione uma cidade' });        
      });
  }


  postMember(partnerData: any) {
    this.memberService.postMemberAddress(partnerData).subscribe(
      (response) => {
        this.paymentDataService.setOperationType(OperationType.NEW);
        this.router.navigate([`/selecionar-plano`]);
      }
    );
  }
}