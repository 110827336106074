import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import {  Router, RouterLink } from '@angular/router';
import { AppCarouselSimpleComponent } from 'src/app/shared/components/app-carousel-simple/app-carousel-simple.component';
import { ConteudoExclusivoModel } from 'src/app/shared/models/conteudoExclusivoModel';
import { ExclusiveContentService } from 'src/app/core/services/exclusive-content.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-conteudo-exclusivo',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule, CarouselModule, RouterLink, FormsModule, AppCarouselSimpleComponent],
  templateUrl: './conteudo-exclusivo.component.html',
  styleUrl: './conteudo-exclusivo.component.scss'
})
export class ConteudoExclusivoComponent implements OnInit {
  labelColor = localStorage.getItem('primaryColor');
  displayCarousel = 'flex';
  recommendedCarousel: ConteudoExclusivoModel[];
  itemsRecommended: any;
  videoList: any;
  articleList: any;
  options: any;
  typeActive: string;
  selectedOption: string;
  hasContent: boolean = true; // Flag para indicar se há conteúdos disponíveis
  filterGroup = new FormGroup({
    filterControl: new FormControl<string>('')
  })
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 27,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      600: {
        items: 2.5
      },
      700: {
        items: 3
      },
      940: {
        items: 3
      },
      1240: {
        items: 3.5
      },
      1540: {
        items: 3.5
      },
      1840: {
        items: 6
      },
      2140: {
        items: 6
      },
      2440: {
        items: 6
      }
    },
  }

  constructor(
    private exclusiveContentService: ExclusiveContentService,
    private commonService: CommonService,
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.validateAccess();
    this.getExclusiveContent();
    this.recommendedCarousel = [];

    this.options = [
      {
        name: 'Mais recentes',
        code: 'mais-recentes',
      },
      {
        name: 'Mais antigos',
        code: 'mais-antigos',
      },
    ]

    this.selectedOption = 'mais-recentes';
  }

  getExclusiveContent() {
    this.recommendedCarousel = [];
    this.exclusiveContentService.getExclusiveContent().subscribe((response: any) => {
      this.recommendedCarousel = response.content.map((item: any) => {
        return {
          id: item.id,
          url: item.url,
          title: item.title,
          description: item.description,
          subtitle: item.subtitle || '',
          date: item.createdAt,
          read: 0,
          image: {
            title: item.title,
            url: this.commonService.getImageStr(item.document?.id) || item.url ? `https://img.youtube.com/vi/${item.url.split('v=')[1]}/maxresdefault.jpg` : '',
          },
          type: item.contentType
        }

        //pegar imagem da tumb do video

      });

      this.itemsRecommended = this.orderListByDate(this.recommendedCarousel)

      this.videoList = this.orderListByDate(this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() == 'video'));

      this.articleList = this.orderListByDate(this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() == 'article'));

      console.log('videoList', this.videoList);
      console.log('itemsRecommended', this.itemsRecommended);
      console.log('articleList', this.articleList);

      // Define a flag hasContent como false se não houver conteúdos
      this.hasContent = this.recommendedCarousel.length > 0;
    });
  }

  filter() {
    const textFilter = this.filterGroup.get('filterControl')?.value;

    if (textFilter && textFilter.length >= 2) {
      const filterText = textFilter.toLowerCase();
      const applyFilter = (item: any) =>
        (item.title?.toLowerCase().includes(filterText) || item.description?.toLowerCase().includes(filterText));

      this.itemsRecommended = this.recommendedCarousel.filter(applyFilter);
      this.videoList = this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() === 'video' && applyFilter(a));
      this.articleList = this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() === 'article' && applyFilter(a));
    } else {
      this.itemsRecommended = this.recommendedCarousel;
      this.videoList = this.orderListByDate(this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() === 'video'));
      this.articleList = this.orderListByDate(this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() === 'article'));
    }
  }

  filterType(type: string) {
    if (this.typeActive === type) {
      this.typeActive = '';
      this.itemsRecommended = this.recommendedCarousel;
    } else {
      this.typeActive = type;
      this.itemsRecommended = this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() == type.toLocaleLowerCase());
    }
    console.log('itemsRecommended', this.itemsRecommended);
  }

  filterSelect() {
    switch (this.selectedOption) {
      case 'mais-recentes':
        this.itemsRecommended = this.recommendedCarousel.sort(function (a: any, b: any) {
          if (a.date < b.date) {
            return 1;
          }
          if (a.date > b.date) {
            return -1;
          }
          return 0;
        });
        break;
      case 'mais-antigos':
        this.itemsRecommended = this.recommendedCarousel.sort(function (a: any, b: any) {
          if (a.date > b.date) {
            return 1;
          }
          if (a.date < b.date) {
            return -1;
          }
          return 0;
        });
        break;
      case 'mais-lidos':
        this.itemsRecommended = this.recommendedCarousel.sort(function (a: any, b: any) {
          if (a.read < b.read) {
            return 1;
          }
          if (a.read > b.read) {
            return -1;
          }
          return 0;
        });
        break;
    }
  }

  checkShowFilter(filter: string) {
    //verificar se existe item com esse filtro no campo type
    let check = this.recommendedCarousel.filter((a: any) => a.type.toLowerCase() == filter.toLowerCase());
    return check.length > 0;
  }

  orderListByDate(list: any) {
    return list.sort(function (a: any, b: any) {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });
  }


  private validateAccess(): void {
    const activeSections = localStorage.getItem('activeSections');

    if (!activeSections) {
      this.handleAccessDenied();
      return;
    }

    const parsedSections = JSON.parse(activeSections);

    if (!parsedSections['exclusivo']) {
      this.handleAccessDenied();
    }
  }

  private handleAccessDenied(): void {
    this.alertService.showAlert(AlertType.WARNING, 'Você não possui acesso a essa página');
    this.router.navigate(['/dashboard']);
  }

}